<div class="page-wrapper d-flex flex-column">
  <!-- <app-header></app-header> -->
    <section class="main-content container-responsive">
      <div class="col-lg-6 offset-lg-3">
          <div id="wrapper">
            <form #addBusinessForm="ngForm" (submit)="onSubmit()" *ngIf="showForm">
                <div class="form-group">
                  <label for="businessType">Type of Business</label>
                  <select 
                    [(ngModel)]="addBusinessModel.businessType"
                    class="form-control"
                    id="businessType"
                    name="businessType"
                  >
                    <option>Dispensary</option>
                    <option>Grower</option>
                    <option>Manufacturer</option>
                    <option>Distributor</option>
                    <option>Lab Testing Facility</option>
                  </select>
                </div>
                <div class="form-group" [class.is-invalid]="businessName.touched && !businessName.valid">
                  <label for="businessName">Business name <span class="require-symbol">*</span></label>
                  <input
                    #businessName="ngModel"
                    [(ngModel)]="addBusinessModel.businessName"
                    type="text"
                    id="businessName"
                    name="businessName"
                    class="form-control"
                    required
                  />
                  <span class="help-block" *ngIf="businessName.errors && businessName.errors.required && businessName.touched">
                    Business name is required.
                  </span>
                </div>
                <div class="form-group">
                  <label for="address">Address</label>
                  <input
                    [(ngModel)]="addBusinessModel.address"
                    type="text"
                    id="address"
                    name="address"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="city">City</label>
                  <input
                    [(ngModel)]="addBusinessModel.city"
                    type="text"
                    id="city"
                    name="city"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="state">State</label>
                  <select 
                    [(ngModel)]="addBusinessModel.state"
                    id="state"
                    name="state"
                    class="form-control"
                  >
                    <option *ngFor="let code of stateCodeList" [ngValue]="code">
                      {{ code }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="zip">ZIP Code</label>
                  <input
                    [(ngModel)]="addBusinessModel.zip"
                    type="text"
                    id="zip"
                    name="zip"
                    class="form-control"
                  />
                </div>
                <div class="form-group" [class.is-invalid]="phoneNumber.touched && !phoneNumber.valid">
                  <label for="phoneNumber">Phone number <span class="require-symbol">*</span></label>
                  <input
                    #phoneNumber="ngModel"
                    [(ngModel)]="addBusinessModel.phoneNumber"
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    class="form-control"
                    required
                  />
                  <span class="help-block" *ngIf="phoneNumber.errors && phoneNumber.errors.required && phoneNumber.touched">
                    Phone number is required.
                  </span>
                </div>
                <div class="form-group" [class.is-invalid]="email.touched && !email.valid">
                  <label class="form-label" for="email">Email <span class="require-symbol">*</span></label>
                  <input
                    #email="ngModel"
                    [(ngModel)]="addBusinessModel.email"
                    type="text"
                    id="email"
                    name="email"
                    class="form-control"
                    required
                    email
                  />
                  <span class="help-block" *ngIf="email.errors && email.errors.required && email.touched">
                    Email is required.
                  </span>
                  <span class="help-block" *ngIf="email.errors && email.errors.email && email.touched">
                    Email is invalid.
                  </span>
                </div>
                <div class="form-group" [class.is-invalid]="website.touched && !website.valid">
                  <label for="website">Website <span class="require-symbol">*</span></label>
                  <input
                    #website="ngModel"
                    [(ngModel)]="addBusinessModel.website"
                    type="text"
                    id="website"
                    name="website"
                    class="form-control"
                    required
                  />
                  <span class="help-block" *ngIf="website.errors && website.errors.required && website.touched">
                    Website is required.
                  </span>
                </div>
                <div class="form-group">
                  <label for="contactName">Contact name</label>
                  <input
                    [(ngModel)]="addBusinessModel.contactName"
                    type="text"
                    id="contactName"
                    name="contactName"
                    class="form-control"
                  />
                </div>
                <re-captcha
                  [(ngModel)]="addBusinessModel.captcha"
                  name="captcha"
                  required
                  siteKey="{{ recaptchaKey }}"
                  #captchaControl="ngModel"
                ></re-captcha>
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  id="submit"
                  [disabled]="!addBusinessForm.form.valid">Submit</button>
            </form>
            <div id="success-message" *ngIf="!showForm">
                Your business has been submitted. Once verified, this business
                will be listed on Mama's Ganja. Thank you for supporting the
                legal marijuana industry.
            </div>
          </div>

      </div>
    </section>
  <!-- <app-footer></app-footer> -->
</div>
    