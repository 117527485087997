var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { LocationService } from '../../../services/location.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
var AdminDispensariesSearchComponent = /** @class */ (function (_super) {
    __extends(AdminDispensariesSearchComponent, _super);
    function AdminDispensariesSearchComponent(seo, locationService, router) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.locationService = locationService;
        _this.router = router;
        _this.searchType = 'name';
        _this.index = false;
        _this.follow = false;
        return _this;
    }
    AdminDispensariesSearchComponent.prototype.ngOnInit = function () {
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
    };
    AdminDispensariesSearchComponent.prototype.searchDispensaries = function () {
        var _this = this;
        this.locationService.searchDispensaries(this.q, this.searchType, true).subscribe(function (matches) {
            _this.matches = matches;
        });
    };
    AdminDispensariesSearchComponent.prototype.edit = function (id) {
        this.router.navigate(["admin/dispensaries/edit/" + id]);
    };
    AdminDispensariesSearchComponent.prototype.delete = function (id) {
        this.router.navigate(["admin/dispensaries/delete/" + id]);
    };
    return AdminDispensariesSearchComponent;
}(BaseComponent));
export { AdminDispensariesSearchComponent };
