import { Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocationService } from '../services/location.service';

@Injectable()
export class StateListResolver implements Resolve<any> {

    constructor(
        private locationService: LocationService,
        private router: Router
    ) {}

    resolve() {
        return this.locationService.getStateList();
    }
}
