<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add New Social Media Site</h4>
    <button type="button" class="close" aria-label="Close" (click)="addSocialMediaSiteModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="socialMediaSite">Social Media Site</label>
            <ng-multiselect-dropdown
                name="socialMediaSite"
                [(ngModel)]="socialMediaSite"
                [placeholder]="'Select Social Media Site'"
                [data]="socialMediaSites"
                [settings]="socialMediaSiteSettings"
                (onSelect)="onSocialMediaSiteSelect($event)"
                (onDeSelect)="onSocialMediaSiteDeSelect($event)"
            >
            </ng-multiselect-dropdown>
        </div>

        <div class="form-group">
            <label for="userName">User Name</label>
            <input
                [(ngModel)]="socialMedia.socialMedia.userName"
                type="text"
                id="userName"
                name="userName"
                class="form-control"
            />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="add(socialMedia)">Save</button>
</div>
