import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StateCodeListResolver } from './resolvers/state-code-list.resolver';
import { AdminComponent } from './components/admin/admin.component';
import { AdminIndexComponent } from './components/admin/admin-index/admin-index.component';
import { AdminDispensariesIndexComponent } from './components/admin/admin-dispensaries-index/admin-dispensaries-index.component';
import { AdminDispensariesSearchComponent } from './components/admin/admin-dispensaries-search/admin-dispensaries-search.component';
import { AdminDispensariesEditComponent } from './components/admin/admin-dispensaries-edit/admin-dispensaries-edit.component';
import { AdminDispensariesCopyeditComponent } from './components/admin/admin-dispensaries-copyedit/admin-dispensaries-copyedit.component';
import { AdminDispensariesDeleteComponent } from './components/admin/admin-dispensaries-delete/admin-dispensaries-delete.component';

import { DispensaryResolver } from './resolvers/dispensary.resolver';

import { CallbackComponent } from './components/callback.component';
import { AuthenticateComponent } from './components/authenticate.component';
import { LogoutComponent } from './components/logout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AddBusinessComponent } from './components/add-business/add-business.component';
import { AdminLocationEditComponent } from './components/admin/admin-location-edit/admin-location-edit.component';
import { AdminFeaturedComponent } from './components/admin/admin-featured/admin-featured.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'authenticate',
    component: AuthenticateComponent
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: []
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AdminIndexComponent,
        pathMatch: 'full'
      },
      {
        path: 'add-business',
        component: AddBusinessComponent,
        pathMatch: 'full',
        resolve: {
          stateCodeList: StateCodeListResolver
        }
      },
      {
        path: 'dispensaries',
        component: AdminDispensariesIndexComponent,
        pathMatch: 'full'
      },
      {
        path: 'dispensaries/search',
        component: AdminDispensariesSearchComponent,
        pathMatch: 'full'
      },
      {
        path: 'dispensaries/edit/:id',
        component: AdminDispensariesEditComponent,
        pathMatch: 'full',
        resolve: {
          dispensary: DispensaryResolver,
          stateCodeList: StateCodeListResolver
        }
      },
      {
        path: 'dispensaries/copyedit/:id',
        component: AdminDispensariesCopyeditComponent,
        pathMatch: 'full',
        resolve: {
          dispensary: DispensaryResolver,
          stateCodeList: StateCodeListResolver
        }
      },
      {
        path: 'featured',
        component: AdminFeaturedComponent,
        pathMatch: 'full',
        resolve: {
          stateCodeList: StateCodeListResolver
        }
      },
      {
        path: 'location/edit',
        component: AdminLocationEditComponent,
        pathMatch: 'full',
        resolve: {
          stateCodeList: StateCodeListResolver
        }
      },
      {
        path: 'dispensaries/delete/:id',
        component: AdminDispensariesDeleteComponent,
        pathMatch: 'full',
        resolve: {
          dispensary: DispensaryResolver
        }
      }
    ]
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { initialNavigation: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
