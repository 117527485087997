export const environment = {
  name: 'production',
  production: true,
  defaultIndexFollow: false,
  webAppUrl: 'https://www.mamasganja.com',
  //webAppUrl: 'https://dev-mamasganja.herokuapp.com',
  cdnRoot: 'https://d3jmre862o9y6b.cloudfront.net',
  apiRoot: 'https://admin.mamasganja.com',
  recaptchaKey: '6Le6LHYUAAAAAKDYQDKohqYAXgsQAC1VLDIXAZU5',
  auth: {
    clientID: 'S6XUt9sNeE5bZJUsgeCWgUHweQeK3c3W',
    domain: 'dev-694s3be1.auth0.com',
    audience: 'https://admin.mamasganja.com',
    redirect: 'https://admin.mamasganja.com/callback',
    scope: 'openid profile email'
  } 
};
