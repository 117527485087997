import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Photo } from '../../../model/photo';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-dispensaries-edit-photo-edit-modal',
  templateUrl: './admin-dispensaries-edit-photo-edit-modal.component.html'
})
export class AdminDispensariesEditPhotoEditModalComponent {
  @Input() photo: Photo;
  @Output() editPhotoEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public editPhotoModal: NgbActiveModal) {
  }

  update() {
    this.editPhotoEmitter.emit(this.photo);
    this.editPhotoModal.close();
  }

}
