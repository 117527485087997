/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./admin-dispensaries-edit-photo-add-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../services/location.service";
var styles_AdminDispensariesEditPhotoAddModalComponent = [];
var RenderType_AdminDispensariesEditPhotoAddModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminDispensariesEditPhotoAddModalComponent, data: {} });
export { RenderType_AdminDispensariesEditPhotoAddModalComponent as RenderType_AdminDispensariesEditPhotoAddModalComponent };
function View_AdminDispensariesEditPhotoAddModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_AdminDispensariesEditPhotoAddModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add New Photo"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addPhotoModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 21, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 20, "form", [["novalidate", ""], ["type", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(9, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(11, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "label", [["for", "photo"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Upload Photo:"])), (_l()(), i0.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "input", [["name", "profile"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminDispensariesEditPhotoAddModalComponent_1)), i0.ɵdid(18, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "label", [["for", "altText"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Alt Text"])), (_l()(), i0.ɵeld(22, 0, null, null, 5, "input", [["class", "form-control"], ["id", "altText"], ["name", "altText"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 23).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 23)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 23)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.altText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(25, 671744, null, 0, i1.NgModel, [[2, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(27, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(30, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.editMode; _ck(_v, 18, 0, currVal_7); var currVal_15 = "altText"; var currVal_16 = _co.altText; _ck(_v, 25, 0, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 11).ngClassValid; var currVal_5 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵnov(_v, 27).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 27).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 27).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 27).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 27).ngClassValid; var currVal_13 = i0.ɵnov(_v, 27).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 27).ngClassPending; _ck(_v, 22, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_17 = _co.formDisabled; _ck(_v, 29, 0, currVal_17); var currVal_18 = (_co.editMode ? "Save" : "Upload Image"); _ck(_v, 30, 0, currVal_18); }); }
export function View_AdminDispensariesEditPhotoAddModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-dispensaries-edit-photo-add-modal", [], null, null, null, View_AdminDispensariesEditPhotoAddModalComponent_0, RenderType_AdminDispensariesEditPhotoAddModalComponent)), i0.ɵdid(1, 114688, null, 0, i3.AdminDispensariesEditPhotoAddModalComponent, [i4.NgbActiveModal, i5.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminDispensariesEditPhotoAddModalComponentNgFactory = i0.ɵccf("app-admin-dispensaries-edit-photo-add-modal", i3.AdminDispensariesEditPhotoAddModalComponent, View_AdminDispensariesEditPhotoAddModalComponent_Host_0, { businessId: "businessId", business: "business", editMode: "editMode", order: "order", photoType: "photoType" }, { addPhotoEmitter: "addPhotoEmitter" }, []);
export { AdminDispensariesEditPhotoAddModalComponentNgFactory as AdminDispensariesEditPhotoAddModalComponentNgFactory };
