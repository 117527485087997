import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSite } from '../../../model/review-site';

@Component({
    selector: 'app-admin-dispensaries-edit-review-site-delete-modal',
    templateUrl: './admin-dispensaries-edit-review-site-delete-modal.component.html'
})
export class AdminDispensariesEditReviewSiteDeleteModalComponent {
    @Input() reviewSite: ReviewSite;
    @Output() deleteReviewSiteEmitter: EventEmitter<any> = new EventEmitter();

    constructor(public deleteReviewSiteModal: NgbActiveModal) {}

    delete(reviewSite) {
        this.deleteReviewSiteEmitter.emit(reviewSite);
        this.deleteReviewSiteModal.close();
    }
}
