<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Add New Photo</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="addPhotoModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form type="">
    <div class="form-group">
      <label for="photo">Upload Photo:</label>
      <br />
      <input type="file" name="profile" (change)="onFileSelect($event)" />
    </div>

    <ng-container *ngIf="editMode">
        <!-- <div class="form-group">
        <label for="photoId">Photo ID</label>
        <input
            [(ngModel)]="photo.photoId"
            type="text"
            id="photoId"
            name="photoId"
            class="form-control"
        />
        </div>

        <div class="form-group">
        <label for="photoExt">Photo Extension</label>
        <input
            [(ngModel)]="photo.photoExt"
            type="text"
            id="photoExt"
            name="photoExt"
            class="form-control"
        />
        </div> -->
    </ng-container>
    <div class="form-group">
        <label for="altText">Alt Text</label>
        <input
            [(ngModel)]="altText"
            type="text"
            id="altText"
            name="altText"
            class="form-control"
        />
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="add()" [disabled]="formDisabled">
    {{ editMode ? 'Save' : 'Upload Image' }}
  </button>
</div>
