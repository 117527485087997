import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Country } from '../model/country';
import { State } from '../model/state';
import { County } from '../model/county';
import { City } from '../model/city';
import { Business } from '../model/business';
import { CitySelectItem } from '../model/city-select-item';
import { LicenseTypeSelectItem } from '../model/license-type-select-item';
import { PaymentMethodSelectItem } from '../model/payment-method-select-item';
import { SocialMediaSiteSelectItem } from '../model/social-media-site-select-item';
import { ReviewSiteSelectItem } from '../model/review-site-select-item';
import { AuthService } from './auth.service';
import { BusinessTypeSelectItem } from '../model/business-type-select-item';
import { LicDesignationTypeSelectItem } from '../model/lic-designation-type-select-item';
import { Photo } from '../model/photo';
import { FeaturedDispensary, FeaturedDispensarySiteLocation } from '../model/featured-dispensary';
import { StateButton } from '../model/state-button';

export enum CacheLocations {
  homepage = 'homepage',
  usa = 'usa',
  dispensary = 'dispensary',
  state = 'state'
}

@Injectable()
export class LocationService {
  private invalidateToken: string = '?invalidate=1052Ab01';
  private apiRoot: string = environment.apiRoot;

  private nocacheHeaders: HttpHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  getInvalidateLink(url: string): string {
    return `${url}${this.invalidateToken}`;
  }

  removeFeaturedDispensary(id: number,
                           forceInvalidation: boolean = false) {
    return this.http.delete(
      `${this.apiRoot}/api/admin/featured/${id}`,
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );
  }

  setFeaturedDispensary(
    businessId: number,
    siteLocation: FeaturedDispensarySiteLocation,
    rank: 1 | 2 | 3,
    forceInvalidation: boolean = false) {
    return this.http.post(
      `${this.apiRoot}/api/admin/featured`,
      {businessId, siteLocation, rank},
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );

  }

  updateAdToAllLocationsInState(state: State, forceInvalidation: boolean = false) {
    return this.http.post(
      `${this.apiRoot}/api/admin/state/update-all`,
      state,
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );
  }

  updateState(state: State, forceInvalidation: boolean = false) {
    return this.http.post(
      `${this.apiRoot}/api/admin/state`,
      state,
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );
  }

  updateCity(city: City, forceInvalidation: boolean = false) {
    return this.http.post(
      `${this.apiRoot}/api/admin/city`,
      city,
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );
  }

  updateCounty(county: County, forceInvalidation: boolean = false) {
    return this.http.post(
      `${this.apiRoot}/api/admin/county`,
      county,
      {
        params: {
          forceInvalidation: forceInvalidation ? '1' : '0',
        },
      },
    );
  }

  updateDispensary(dispensary: Business, forceInvalidation: boolean = false): Promise<boolean> {
    console.log('forceInvalidation service', forceInvalidation);
    return this.authService.getAccessToken().then(() => {
      return this.http
        .put<boolean>(
          `${this.apiRoot}/api/admin/dispensary/update`,
          dispensary,
          {
            params: {
              forceInvalidation: forceInvalidation ? '1' : '0',
            },
          },
        )
        .toPromise();
    });
  }

  getFeaturedDispensaries(location: FeaturedDispensarySiteLocation) {
    return this.http.get<FeaturedDispensary[]>(`${this.apiRoot}/api/featured/${location}`);
  }

  getImageById(id) {
    return this.http.get<Photo>(`${this.apiRoot}/api/photos/${id}`);
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.apiRoot}/api/countries`);
  }

  getCountry(countryId: number): Observable<Country> {
    return this.http.get<Country>(`${this.apiRoot}/api/countries/${countryId}`);
  }

  getStateList(): Observable<State[]> {
    return this.http.get<State[]>(`${this.apiRoot}/api/countries/1/states`);
  }

  getState(stateCode: string): Observable<State> {
    return this.http.get<State>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}`,
    );
  }

  getStateCodeList(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiRoot}/api/countries/1/statecodes`,
    );
  }

  getCountyCity(stateCode: string, countyCitySlug: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}/${countyCitySlug}`,
    );
  }

  getAllCounties(stateCode: string): Observable<County[]> {
    return this.http.get<County[]>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/counties`,
    );
  }

  getCounty(stateCode: string, countySlug: string): Observable<County> {
    return this.http.get<County>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/counties/${countySlug}`,
    );
  }

  getAllCities(
    stateCode: string,
  ): Observable<City[]> {
    return this.http.get<City[]>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}/cities?q=all`,
    );
  }

  getCity(
    stateCode: string,
    countySlug: string,
    citySlug: string,
  ): Observable<City> {
    return this.http.get<City>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/counties/${countySlug}/cities/${citySlug}`,
    );
  }

  checkState(stateCode: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}/exists`,
    );
  }

  getStateIdFromStateCode(stateCode: string): Observable<number> {
    return this.http.get<number>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}/id`,
    );
  }

  checkCountyCity(
    stateCode: string,
    countyCitySlug: string,
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/${countyCitySlug}/exists`,
    );
  }

  checkCounty(stateCode: string, countySlug: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/counties/${countySlug}/exists`,
    );
  }

  checkCity(
    stateCode: string,
    countySlug: string,
    citySlug: string,
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${
        this.apiRoot
      }/api/countries/1/states/${stateCode}/counties/${countySlug}/cities/${citySlug}/exists`,
    );
  }

  getStateCityList(stateCode: string): Observable<CitySelectItem[]> {
    return this.http.get<CitySelectItem[]>(
      `${this.apiRoot}/api/countries/1/states/${stateCode}/cities`,
    );
  }

  getPaymentMethods(): Observable<PaymentMethodSelectItem[]> {
    return this.http.get<PaymentMethodSelectItem[]>(
      `${this.apiRoot}/api/payment-methods`,
    );
  }

  getSocialMediaSites(): Observable<SocialMediaSiteSelectItem[]> {
    return this.http.get<SocialMediaSiteSelectItem[]>(
      `${this.apiRoot}/api/social-media-sites`,
    );
  }

  getReviewSites(): Observable<ReviewSiteSelectItem[]> {
    return this.http.get<ReviewSiteSelectItem[]>(
      `${this.apiRoot}/api/review-sites`,
    );
  }

  getAllDispensaries(): Observable<Business[]> {
    return this.http.get<Business[]>(
      `${this.apiRoot}/api/dispensaries`,
    );
  }

  getPendStates(): Observable<StateButton[]> {
    return this.http.get<StateButton[]>(`${this.apiRoot}/api/dispensaries/pendstates`,
      {
        headers: this.nocacheHeaders,
      },
    );
  }

  getPendList(stateId: number): Observable<Business[]> {
    return this.http.get<Business[]>(`${this.apiRoot}/api/dispensaries/pendlist/${stateId}`,
      {
        headers: this.nocacheHeaders,
      },
    );
  }

  searchDispensaries(query: string, type: string = 'name', edit: boolean = false): Observable<Business[]> {
    return this.http.get<Business[]>(
      `${this.apiRoot}/api/dispensaries/search?q=${query}&t=${type}&edit=${edit}`,
      {
        headers: this.nocacheHeaders,
      },
    );
  }

  getDispensary(id: number): Observable<Business> {
    return this.http.get<Business>(`${this.apiRoot}/api/dispensaries/${id}`);
  }

  getLicenseTypesByState(
    stateCode: string,
  ): Observable<LicenseTypeSelectItem[]> {
    return this.http.get<LicenseTypeSelectItem[]>(
      `${this.apiRoot}/api/licensetypes/${stateCode}`,
    );
  }

  getBusinessTypes(): Observable<BusinessTypeSelectItem[]> {
    return this.http.get<BusinessTypeSelectItem[]>(
      `${this.apiRoot}/api/businesstypes`,
    );
  }

  getLicDesignationTypes(): Observable<LicDesignationTypeSelectItem[]> {
    return this.http.get<LicDesignationTypeSelectItem[]>(`${this.apiRoot}/api/licensedesignationtypes`);
  }

  getGalleryImages(businessId) {
    return this.http.get(`${this.apiRoot}/api/photos/gallery/${businessId}`);
  }

  uploadAdImage(image: File, altText: string, adUrl: string, adCode: string) {

    let input = new FormData();
    input.append('image', image);
    input.append('altText', altText);
    input.append('adUrl', adUrl);
    input.append('adCode', adCode);

    console.log({input});

    return this.http.post(
      `${this.apiRoot}/api/admin/photos/ads`,
      input,
    );
  }

  uploadImage(image: File, fileName: string, photoType: 'profile' | 'logo' | 'gallery', order?: number) {

    console.log({image}, {fileName}, {photoType}, {order});

    let input = new FormData();
    input.append('image', image);
    input.append('fileName', fileName + '-' + image.name.replace(/\s/g, ''));
    input.append('photoType', photoType);
    if (order) {
      input.append('order', order.toString());
    }

    console.log({input});

    return this.http.post(
      `${this.apiRoot}/api/admin/dispensary/photos`,
      input,
    );
  }
}
