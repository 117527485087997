/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./admin-dispensaries-delete.component";
import * as i2 from "../../../services/seo.service";
var styles_AdminDispensariesDeleteComponent = [];
var RenderType_AdminDispensariesDeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminDispensariesDeleteComponent, data: {} });
export { RenderType_AdminDispensariesDeleteComponent as RenderType_AdminDispensariesDeleteComponent };
export function View_AdminDispensariesDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["deleting..."]))], null, null); }
export function View_AdminDispensariesDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-dispensaries-delete", [], null, null, null, View_AdminDispensariesDeleteComponent_0, RenderType_AdminDispensariesDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i1.AdminDispensariesDeleteComponent, [i2.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminDispensariesDeleteComponentNgFactory = i0.ɵccf("app-admin-dispensaries-delete", i1.AdminDispensariesDeleteComponent, View_AdminDispensariesDeleteComponent_Host_0, {}, {}, []);
export { AdminDispensariesDeleteComponentNgFactory as AdminDispensariesDeleteComponentNgFactory };
