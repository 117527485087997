import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SeoService } from './services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Mama\'s Ganja';
  cdnRoot = environment.cdnRoot;

  constructor(private seo: SeoService) {}

  ngOnInit() {
    this.seo.addTags([
      { tagName: 'link', rel: 'dns-prefetch', href: `${this.cdnRoot}/` },
      { tagName: 'link', rel: 'preconnect', href: `${this.cdnRoot}/` },
      { tagName: 'meta', name: 'robots', content: `noindex, nofollow` },
    ]);
  }
}
