import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationService } from './services/location.service';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable()
export class CountyCityGuard implements CanActivate {

    constructor(
        private router: Router,
        private locationService: LocationService,
        private location: Location
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean>|Promise<boolean>|boolean {
        let stateCode = route.params.state;
        let countyCitySlug = route.params.countyOrCity;

        return this.locationService.checkCountyCity(stateCode, countyCitySlug)
            .pipe(map(isValid => {
                if (!isValid) {
                    this.router.navigate(['404']);
                    let url = state.url;
                    if (!url.endsWith('/')) {
                        url = `${url}/`
                    }
                    setTimeout(() => this.location.replaceState(url));
                }
                return isValid;
            }));
    }
}
