<div class="col-lg-8 offset-lg-2">
  <h1>Featured Dispensaries</h1>
  <div id="wrapper">
    <br><br>

    <div class="form-group">
      <label for="siteLocation">Select SITE LOCATION to Edit:</label>
      <select
        [(ngModel)]="selectedSiteLocation"
        id="siteLocation"
        name="siteLocation"
        class="form-control"
        (change)="changeLocation(selectedSiteLocation)"
      >
        <option value="">-- Select a Website location --</option>
        <option *ngFor="let location of siteLocationList" [ngValue]="location">
          {{ location }}
        </option>
      </select>
      <br>
    </div>

    <div *ngIf="selectedSiteLocation" class="topper-wrapper">
      <h2>Edit Featured Dispensaries of Site section: <strong>{{ selectedSiteLocation }}</strong></h2>

      <div style="margin: 25px 0">
        <label>
          <input type="checkbox" [(ngModel)]="forceInvalidation" [ngModelOptions]="{standalone: true}"/>
          Force an invalidation of the cache
        </label>
        <br/>
        If you want to force the invalidation of the cache, please tick the checkbox this first before you make any other change on
        this page.
      </div>

      <div class="form-group">
        <label for="featuredOne">#1 Featured</label>
        <ng-multiselect-dropdown
          name="featuredOne"
          [(ngModel)]="selectedDispensaryOne"
          [placeholder]="'Select Dispensary for Featured #1'"
          [data]="dispensaries"
          [settings]="businessSettings"
          (onSelect)="onDispensarySelect($event, 1)"
          (onDeSelect)="onDispensaryDeSelect($event, 1)"
        >
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="featuredTwo">#2 Featured</label>
        <ng-multiselect-dropdown
          name="featuredTwo"
          [(ngModel)]="selectedDispensaryTwo"
          [placeholder]="'Select Dispensary for Featured #2'"
          [data]="dispensaries"
          [settings]="businessSettings"
          (onSelect)="onDispensarySelect($event, 2)"
          (onDeSelect)="onDispensaryDeSelect($event, 2)"
        >
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="featuredThree">#3 Featured</label>
        <ng-multiselect-dropdown
          name="featuredThree"
          [(ngModel)]="selectedDispensaryThree"
          [placeholder]="'Select Dispensary for Featured #3'"
          [data]="dispensaries"
          [settings]="businessSettings"
          (onSelect)="onDispensarySelect($event, 3)"
          (onDeSelect)="onDispensaryDeSelect($event, 3)"
        >
        </ng-multiselect-dropdown>
      </div>

      <!-- Alerts on save -->
      <div *ngIf="toast.show" class="alert alert-success" role="alert">
        {{ toast.message }}
      </div>

    </div>

  </div>
</div>
