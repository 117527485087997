/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "container text-center"], ["id", "error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, ":svg:svg", [["height", "100"], ["width", "100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:polygon", [["points", "50,25 17,80 82,80"], ["stroke-linejoin", "round"], ["style", "fill:none;stroke:#ff8a00;stroke-width:8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:text", [["fill", "#ff8a00"], ["font-family", "sans-serif"], ["font-size", "42px"], ["font-weight", "900"], ["x", "42"], ["y", "74"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["!"])), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "main-icon text-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "uxicon uxicon-alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["File not found (404 error)"])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "col-md-6 col-md-push-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["If you think what you're looking for should be here, please contact the site owner."]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i1.NotFoundComponent, [i0.PLATFORM_ID, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
