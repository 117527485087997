import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { isPlatformBrowser } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderTitleService } from './services/header-title.service';
import { SeoService } from './services/seo.service';
import { LocationService } from './services/location.service';

import { StateResolver } from './resolvers/state.resolver';
import { StateGuard } from './state.guard';

import { CountryResolver } from './resolvers/country.resolver';
import { StateListResolver } from './resolvers/state-list.resolver';
import { StateCodeListResolver } from './resolvers/state-code-list.resolver';
import { CountyCityGuard } from './county-city.guard';
import { CountyCityResolver } from './resolvers/county-city.resolver';
import { AdminIndexComponent } from './components/admin/admin-index/admin-index.component';
import { AdminDispensariesIndexComponent } from './components/admin/admin-dispensaries-index/admin-dispensaries-index.component';
import { AdminDispensariesSearchComponent } from './components/admin/admin-dispensaries-search/admin-dispensaries-search.component';
import { AdminDispensariesEditComponent } from './components/admin/admin-dispensaries-edit/admin-dispensaries-edit.component';
import { AdminDispensariesCopyeditComponent } from './components/admin/admin-dispensaries-copyedit/admin-dispensaries-copyedit.component';
import { AdminDispensariesDeleteComponent } from './components/admin/admin-dispensaries-delete/admin-dispensaries-delete.component';
import { DispensaryResolver } from './resolvers/dispensary.resolver';
import { AdminDispensariesEditPhotoAddModalComponent } from './components/admin/admin-dispensaries-edit-photo-add-modal/admin-dispensaries-edit-photo-add-modal.component';
import { AdminDispensariesEditPhotoEditModalComponent } from './components/admin/admin-dispensaries-edit-photo-edit-modal/admin-dispensaries-edit-photo-edit-modal.component';
import { AdminDispensariesEditPhotoDeleteModalComponent } from './components/admin/admin-dispensaries-edit-photo-delete-modal/admin-dispensaries-edit-photo-delete-modal.component';
import { AdminDispensariesEditSocialMediaSiteAddModalComponent } from './components/admin/admin-dispensaries-edit-social-media-site-add-modal/admin-dispensaries-edit-social-media-site-add-modal.component';
import { AdminDispensariesEditSocialMediaSiteEditModalComponent } from './components/admin/admin-dispensaries-edit-social-media-site-edit-modal/admin-dispensaries-edit-social-media-site-edit-modal.component';
import { AdminDispensariesEditSocialMediaSiteDeleteModalComponent } from './components/admin/admin-dispensaries-edit-social-media-site-delete-modal/admin-dispensaries-edit-social-media-site-delete-modal.component';
import { AdminDispensariesEditReviewSiteAddModalComponent } from './components/admin/admin-dispensaries-edit-review-site-add-modal/admin-dispensaries-edit-review-site-add-modal.component';
import { AdminDispensariesEditReviewSiteEditModalComponent } from './components/admin/admin-dispensaries-edit-review-site-edit-modal/admin-dispensaries-edit-review-site-edit-modal.component';
import { AdminDispensariesEditReviewSiteDeleteModalComponent } from './components/admin/admin-dispensaries-edit-review-site-delete-modal/admin-dispensaries-edit-review-site-delete-modal.component';
import { AdminDispensariesEditOpenTimeAddModalComponent } from './components/admin/admin-dispensaries-edit-open-time-add-modal/admin-dispensaries-edit-open-time-add-modal.component';
import { AdminDispensariesEditOpenTimeEditModalComponent } from './components/admin/admin-dispensaries-edit-open-time-edit-modal/admin-dispensaries-edit-open-time-edit-modal.component';
import { AdminDispensariesEditOpenTimeDeleteModalComponent } from './components/admin/admin-dispensaries-edit-open-time-delete-modal/admin-dispensaries-edit-open-time-delete-modal.component';
import { CallbackComponent } from './components/callback.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth.guard';
import { AuthenticateComponent } from './components/authenticate.component';
import { LogoutComponent } from './components/logout.component';
import { AdminComponent } from './components/admin/admin.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AddBusinessComponent } from './components/add-business/add-business.component';
import { AdminLocationEditComponent } from './components/admin/admin-location-edit/admin-location-edit.component';
import { AdminAdsPhotoAddModalComponent } from './components/admin/admin-ads-photo-add-modal/admin-ads-photo-add-modal.component';
import { AdminFeaturedComponent } from './components/admin/admin-featured/admin-featured.component';

// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService }  from './in-memory-data.service';

export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    super();
  }

  serialize(tree: UrlTree): any {
    let path = super.serialize(tree);
    if (!isPlatformBrowser(this.platformId)) {
      return path;
    }
    let newPath = "";

    let [pathQueryString, anchor] = path.split("#");
    let [base, queryString] = pathQueryString.split("?");

    newPath = base;
    if (!base.endsWith("/")) {
      newPath += "/";
    }
    if (queryString) {
      newPath += "?" + queryString;
    }
    if (anchor) {
      newPath += "#" + anchor;
    }
    return newPath;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    AuthenticateComponent,
    LogoutComponent,
    AddBusinessComponent,
    AdminComponent,
    NotFoundComponent,
    AdminIndexComponent,
    AdminDispensariesIndexComponent,
    AdminDispensariesSearchComponent,
    AdminDispensariesEditComponent,
    AdminDispensariesCopyeditComponent,
    AdminDispensariesEditPhotoAddModalComponent,
    AdminDispensariesEditPhotoEditModalComponent,
    AdminDispensariesEditPhotoDeleteModalComponent,
    AdminDispensariesEditOpenTimeAddModalComponent,
    AdminDispensariesEditOpenTimeEditModalComponent,
    AdminDispensariesEditOpenTimeDeleteModalComponent,
    AdminDispensariesEditSocialMediaSiteAddModalComponent,
    AdminDispensariesEditSocialMediaSiteEditModalComponent,
    AdminDispensariesEditSocialMediaSiteDeleteModalComponent,
    AdminDispensariesEditReviewSiteAddModalComponent,
    AdminDispensariesEditReviewSiteDeleteModalComponent,
    AdminDispensariesEditReviewSiteEditModalComponent,
    AdminDispensariesDeleteComponent,
    AdminLocationEditComponent,
    AdminFeaturedComponent,

    AdminAdsPhotoAddModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "universal-app" }),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    FormsModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDlZUw8abnXeCgfID3zQswOFbUSJ-BhxhI' }),
    AgmSnazzyInfoWindowModule
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // )
  ],
  providers: [
    HeaderTitleService,
    LocationService,
    AuthService,
    StateResolver,
    CountryResolver,
    CountyCityResolver,
    StateListResolver,
    StateCodeListResolver,
    DispensaryResolver,
    AuthGuard,
    StateGuard,
    CountyCityGuard,
    SeoService,
    GoogleMapsAPIWrapper,
    { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer }
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [
    AdminDispensariesEditPhotoAddModalComponent,
    AdminDispensariesEditPhotoEditModalComponent,
    AdminDispensariesEditPhotoDeleteModalComponent,
    AdminDispensariesEditOpenTimeAddModalComponent,
    AdminDispensariesEditOpenTimeEditModalComponent,
    AdminDispensariesEditOpenTimeDeleteModalComponent,
    AdminDispensariesEditSocialMediaSiteAddModalComponent,
    AdminDispensariesEditSocialMediaSiteEditModalComponent,
    AdminDispensariesEditSocialMediaSiteDeleteModalComponent,
    AdminDispensariesEditReviewSiteAddModalComponent,
    AdminDispensariesEditReviewSiteDeleteModalComponent,
    AdminDispensariesEditReviewSiteEditModalComponent,

    AdminAdsPhotoAddModalComponent
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? "in the browser"
      : "on the server";
    // console.log(`Running ${platform} with appId=${appId}`);
  }
}
