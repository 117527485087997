<div class="col-lg-8 offset-lg-2">
  <h1>Toppers & Ads</h1>
  <div id="wrapper">



    <br><br>

    <div class="form-group">
      <label for="businessCityName">Select STATE to Edit:</label>
      <select
        [(ngModel)]="selectedState"
        id="stateCode"
        name="stateCode"
        class="form-control"
        (change)="getStateInfo(selectedState)"
      >
        <option value="-1">-- Select a State --</option>
        <option *ngFor="let state of statesList" [ngValue]="state">
          {{ state.stateCode }}
        </option>
      </select>
      <br><br>
    </div>

    <ng-container *ngIf="selectedState?.stateName">

      <div class="buttons-wrapper">
        <button type="button" class="btn btn-outline-dark" (click)="changeEditMode('State')" [class.selected]="topperEditMode === TopperMode.State">
          Edit STATE Items
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="changeEditMode('City')" [class.selected]="topperEditMode === TopperMode.City">
          Edit CITY Items
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="changeEditMode('County')" [class.selected]="topperEditMode === TopperMode.County">
          Edit COUNTY Items
        </button>
      </div>

      <ng-container *ngIf="topperEditMode === TopperMode.City">
        <div class="form-group">
          <label for="businessCityName"><strong>Select a City to Edit:</strong></label>
          <ng-multiselect-dropdown
            name="selectedCity"
            [(ngModel)]="selectedCity"
            [placeholder]="'Select City'"
            [data]="citySelectItems"
            [settings]="businessCitySettings"
            (onSelect)="onBusinessCitySelect($event)"
            (onDeSelect)="onBusinessCityDeSelect()"
          >
          </ng-multiselect-dropdown>
        </div>
      </ng-container>

      <ng-container *ngIf="topperEditMode === TopperMode.County">

        <div class="form-group">
          <label for="businessCityName"><strong>Select a County to Edit:</strong></label>
          <ng-multiselect-dropdown
            name="selectedCity"
            [(ngModel)]="selectedCounty"
            [placeholder]="'Select County'"
            [data]="counties"
            [settings]="businessCountySettings"
            (onSelect)="onCountySelect($event)"
            (onDeSelect)="onCountyDeSelect()"
          >
          </ng-multiselect-dropdown>
        </div>

      </ng-container>

      <ng-container *ngIf="topperEditMode === TopperMode.State">
        <!-- <h3>State Topper edit mode:</h3> -->
      </ng-container>

    </ng-container>

    <br>
    <br>
    <br>
    <br>
    <br>

    <div *ngIf="canEditToppers" class="topper-wrapper">
      <h2>Edit the <u>{{ topperEditMode }}</u> of <strong>{{ topperLocationName }}</strong></h2>

      <div class="form-group">
        <label for="topperOne">#1 Topper</label>
          <ng-multiselect-dropdown
            name="topperOne"
            [(ngModel)]="selectedDispensaryOne"
            [placeholder]="'Select Dispensary  for Topper #1'"
            [data]="dispensaries"
            [settings]="businessSettings"
            (onSelect)="onDispensarySelect($event, 1)"
            (onDeSelect)="onDispensaryDeSelect($event, 1)"
          >
          </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="topperTwo">#2 Topper</label>
          <ng-multiselect-dropdown
            name="topperTwo"
            [(ngModel)]="selectedDispensaryTwo"
            [placeholder]="'Select Dispensary for Topper #2'"
            [data]="dispensaries"
            [settings]="businessSettings"
            (onSelect)="onDispensarySelect($event, 2)"
            (onDeSelect)="onDispensaryDeSelect($event, 2)"
          >
          </ng-multiselect-dropdown>
      </div>
      <div class="form-group">
        <label for="topperThree">#3 Topper</label>
          <ng-multiselect-dropdown
            name="topperThree"
            [(ngModel)]="selectedDispensaryThree"
            [placeholder]="'Select Dispensary for Topper #3'"
            [data]="dispensaries"
            [settings]="businessSettings"
            (onSelect)="onDispensarySelect($event, 3)"
            (onDeSelect)="onDispensaryDeSelect($event, 3)"
          >
          </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="ad">Advertisement</label>
        <br>
        <button (click)="imageModal('ad')">Upload New Ad</button>
        <br>
        <br>
        <div *ngIf="adImage && adImageSrc" class="clearfix">
          <h3>Current Ad setup:</h3>

          <div class="float-left ml-5" *ngIf="!adImage.adCode">
            <img [src]="adImageSrc" class="float-left">
            <br>
            <span><strong>(for reference) Image Direct Link:</strong> <a [href]="adImageSrc" target="_blank">{{ adImageSrc }}</a></span>
            <br>
            <span><strong>Alt:</strong> {{ adImage.altText }} </span>
            <br>
            <span><strong>URL:</strong> <a [href]="adImage.adUrl" target="_blank">{{ adImage.adUrl }}</a></span>
          </div>
          <div class="float-left ml-5" *ngIf="adImage.adCode">
            <span><strong>Code:</strong> {{ adImage.adCode }}</span>
          </div>
          <br>
          <br>
        </div>

        <div *ngIf="adImage && adImageSrc && topperEditMode === TopperMode.State">
          <br><br>
          <div>
            <label>
              <input type="checkbox" [(ngModel)]="forceInvalidationState" [ngModelOptions]="{standalone: true}" />
              Force an invalidation of the cache
            </label>
          </div>
          <button (click)="updateAllStateLocations()" class="btn btn-outline-dark p-3">SAVE for ALL Counties & Cities for the State: <strong>{{ topperLocationName }}</strong></button>
        </div>

      </div>

      <br>
      <br>

      <div>
        <label>
          <input type="checkbox" [(ngModel)]="forceInvalidation" [ngModelOptions]="{standalone: true}" />
          Force an invalidation of the cache
        </label>
      </div>

      <button (click)="onSubmit()" class="btn btn-primary p-3">SAVE for {{ topperEditMode }}: <strong>{{ topperLocationName }}</strong></button>

    </div>

  </div>
</div>
