import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialMediaSite } from '../../../model/social-media-site';

@Component({
    selector: 'app-admin-dispensaries-edit-social-media-site-edit-modal',
    templateUrl: './admin-dispensaries-edit-social-media-site-edit-modal.component.html'
})
export class AdminDispensariesEditSocialMediaSiteEditModalComponent implements OnInit {
    @Input() public businessId: number;
    @Input() public socialMedia: SocialMediaSite;
    @Input() public socialMediaSites: any[];
    @Output() editSocialMediaSiteEmitter: EventEmitter<any> = new EventEmitter();

    socialMediaSite: any[] = [];
    socialMediaSiteSettings: any = {};

    constructor(public editSocialMediaSiteModal: NgbActiveModal) {
        this.socialMedia  = {
          id: -1,
          name: '',
          icon: '',
          baseUrl: '',
          socialMedia: {
            businessId: -1,
            socialMediaSiteId: -1,
            userName: ''
          }
        };
    }

    ngOnInit() {
        this.socialMedia.socialMedia.businessId = this.businessId;
        this.socialMediaSiteSettings = {
            singleSelection: true,
            idField: 'socialMediaSiteId',
            textField: 'socialMediaSiteName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };

        this.socialMediaSite = [
          {
            socialMediaSiteId: this.socialMedia.id,
            socialMediaSiteName: this.socialMedia.name
          }
        ];
    }

    onSocialMediaSiteSelect(socialMediaSite) {
      this.socialMedia.id = socialMediaSite.socialMediaSiteId;
      this.socialMedia.name = socialMediaSite.socialMediaSiteName;
      this.socialMedia.socialMedia.socialMediaSiteId = socialMediaSite.socialMediaSiteId;
    }

    onSocialMediaSiteDeSelect(socialMediaSite) {
      this.socialMedia.id = -1;
      this.socialMedia.name = '';
      this.socialMedia.socialMedia.socialMediaSiteId = -1;
    }

    edit(socialMediaSite) {
      this.editSocialMediaSiteEmitter.emit(socialMediaSite);
      this.editSocialMediaSiteModal.close();
    }
}
