import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocationService } from '../services/location.service';

@Injectable()
export class DispensaryResolver implements Resolve<any> {

    constructor(
        private locationService: LocationService,
        private router: Router
    ) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.locationService
            .getDispensary(route.params['id']);
    }
}
