import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BaseComponent } from './base.component';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-authenticate',
  template: `
    <button (click)="login()">Login</button>
  `,
  styles: []
})
export class AuthenticateComponent extends BaseComponent implements OnInit {

  constructor(
    private seo: SeoService,
    private authService: AuthService
  ) {
    super();
   }

  ngOnInit() {
    this.seo.addTags([
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
    ]);      
  }

  login() {
    this.authService.login();
  }

}
