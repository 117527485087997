<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Edit Photo</h4>
  <button type="button" class="close" aria-label="Close" (click)="editPhotoModal.dismiss()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
      <div class="form-group">
          <label for="photoId">Photo ID</label>
          <input disabled
              [(ngModel)]="photo.photoId"
              type="text"
              id="photoId"
              name="photoId"
              class="form-control"
          />
      </div>
      <div class="form-group">
          <label for="photoExt">Photo Extension</label>
          <input disabled
              [(ngModel)]="photo.photoExt"
              type="text"
              id="photoExt"
              name="photoExt"
              class="form-control"
          />
      </div>
      <div class="form-group">
        <label for="altText">Alt Text</label>
        <input
            [(ngModel)]="photo.altText"
            type="text"
            id="altText"
            name="altText"
            class="form-control"
        />
    </div>
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="update()">Save</button>
</div>