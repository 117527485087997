<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Review Site</h4>
    <button type="button" class="close" aria-label="Close" (click)="editReviewSiteModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="reviewSite">Review Site</label>
            <ng-multiselect-dropdown
                name="reviewSite"
                [(ngModel)]="reviewSite"
                [placeholder]="'Select Review Site'"
                [data]="reviewSites"
                [settings]="reviewSiteSettings"
                (onSelect)="onReviewSiteSelect($event)"
                (onDeSelect)="onReviewSiteDeSelect($event)"
            >
            </ng-multiselect-dropdown>
        </div>
      
        <div class="form-group">
            <label for="slug">Slug</label>
            <input
                [(ngModel)]="review.review.slug"
                type="text"
                id="slug"
                name="slug"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label for="reviewCount">Review Count</label>
            <input
                [(ngModel)]="review.review.reviewCount"
                type="text"
                id="reviewCount"
                name="reviewCount"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label for="rating">Rating</label>
            <input
                [(ngModel)]="review.review.rating"
                type="text"
                id="rating"
                name="rating"
                class="form-control"
            />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="edit(review)">Save</button>
</div>
