/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./admin-dispensaries-index.component";
import * as i2 from "../../../services/seo.service";
var styles_AdminDispensariesIndexComponent = [];
var RenderType_AdminDispensariesIndexComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminDispensariesIndexComponent, data: {} });
export { RenderType_AdminDispensariesIndexComponent as RenderType_AdminDispensariesIndexComponent };
export function View_AdminDispensariesIndexComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-lg-6 offset-lg-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["id", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "btn btn-block btn-lg btn-primary"], ["href", "admin/dispensaries/search/"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Search Dispensaries "]))], null, null); }
export function View_AdminDispensariesIndexComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-dispensaries-index", [], null, null, null, View_AdminDispensariesIndexComponent_0, RenderType_AdminDispensariesIndexComponent)), i0.ɵdid(1, 114688, null, 0, i1.AdminDispensariesIndexComponent, [i2.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminDispensariesIndexComponentNgFactory = i0.ɵccf("app-admin-dispensaries-index", i1.AdminDispensariesIndexComponent, View_AdminDispensariesIndexComponent_Host_0, {}, {}, []);
export { AdminDispensariesIndexComponentNgFactory as AdminDispensariesIndexComponentNgFactory };
