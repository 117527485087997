import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { LocationService } from '../services/location.service';

@Injectable()
export class StateResolver implements Resolve<any> {

    constructor(
        private locationService: LocationService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.locationService
            .getState(route.params['state']);

    }
}
