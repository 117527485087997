import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable()
export class HeaderTitleService {
  title = new BehaviorSubject('');

  constructor(private pageTitle: Title) {}

  setTitle(title: string) {
    this.title.next(title);
    this.pageTitle.setTitle(`${title} | MAMA'S GANJA`);
  }
}
