var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location.service';
import { AdminDispensariesEditPhotoAddModalComponent } from '../admin-dispensaries-edit-photo-add-modal/admin-dispensaries-edit-photo-add-modal.component';
import { AdminDispensariesEditPhotoEditModalComponent } from '../admin-dispensaries-edit-photo-edit-modal/admin-dispensaries-edit-photo-edit-modal.component';
import { AdminDispensariesEditPhotoDeleteModalComponent } from '../admin-dispensaries-edit-photo-delete-modal/admin-dispensaries-edit-photo-delete-modal.component';
import { AdminDispensariesEditSocialMediaSiteAddModalComponent } from '../admin-dispensaries-edit-social-media-site-add-modal/admin-dispensaries-edit-social-media-site-add-modal.component';
import { AdminDispensariesEditSocialMediaSiteEditModalComponent } from '../admin-dispensaries-edit-social-media-site-edit-modal/admin-dispensaries-edit-social-media-site-edit-modal.component';
import { AdminDispensariesEditSocialMediaSiteDeleteModalComponent } from '../admin-dispensaries-edit-social-media-site-delete-modal/admin-dispensaries-edit-social-media-site-delete-modal.component';
import { AdminDispensariesEditReviewSiteAddModalComponent } from '../admin-dispensaries-edit-review-site-add-modal/admin-dispensaries-edit-review-site-add-modal.component';
import { AdminDispensariesEditReviewSiteEditModalComponent } from '../admin-dispensaries-edit-review-site-edit-modal/admin-dispensaries-edit-review-site-edit-modal.component';
import { AdminDispensariesEditReviewSiteDeleteModalComponent } from '../admin-dispensaries-edit-review-site-delete-modal/admin-dispensaries-edit-review-site-delete-modal.component';
import { AdminDispensariesEditOpenTimeAddModalComponent } from '../admin-dispensaries-edit-open-time-add-modal/admin-dispensaries-edit-open-time-add-modal.component';
import { AdminDispensariesEditOpenTimeEditModalComponent } from '../admin-dispensaries-edit-open-time-edit-modal/admin-dispensaries-edit-open-time-edit-modal.component';
import { AdminDispensariesEditOpenTimeDeleteModalComponent } from '../admin-dispensaries-edit-open-time-delete-modal/admin-dispensaries-edit-open-time-delete-modal.component';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { environment } from '../../../../environments/environment';
var AdminDispensariesEditComponent = /** @class */ (function (_super) {
    __extends(AdminDispensariesEditComponent, _super);
    function AdminDispensariesEditComponent(seo, actr, locationService, mapsApiLoader, zone, wrapper, modalService) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.actr = actr;
        _this.locationService = locationService;
        _this.mapsApiLoader = mapsApiLoader;
        _this.zone = zone;
        _this.wrapper = wrapper;
        _this.modalService = modalService;
        _this.cdnRoot = environment.cdnRoot;
        _this.daysOfWeek = [
            { dayOfWeekId: 0, dayOfWeekName: "Sunday" },
            { dayOfWeekId: 1, dayOfWeekName: "Monday" },
            { dayOfWeekId: 2, dayOfWeekName: "Tuesday" },
            { dayOfWeekId: 3, dayOfWeekName: "Wednesday" },
            { dayOfWeekId: 4, dayOfWeekName: "Thursday" },
            { dayOfWeekId: 5, dayOfWeekName: "Friday" },
            { dayOfWeekId: 6, dayOfWeekName: "Saturday" }
        ];
        _this.showForm = true;
        _this.businessCity = [];
        _this.businessCitySettings = {};
        _this.businessType = [];
        _this.businessTypeSettings = {};
        _this.licenseType = [];
        _this.licenseTypeSettings = {};
        _this.licDesignations = [];
        _this.licDesignationsSettings = {};
        _this.deliveryCities = [];
        _this.deliveryCitiesSettings = {};
        _this.payments = [];
        _this.paymentMethodSettings = {};
        _this.forceInvalidation = false;
        _this.index = false;
        _this.follow = false;
        _this.mapsApiLoader = mapsApiLoader;
        _this.zone = zone;
        _this.wrapper = wrapper;
        var today = new Date();
        _this.licenseExpiryDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
        _this.sub = _this.actr.data.subscribe(function (data) {
            _this.dispensary = data.dispensary;
            _this.locationService.getGalleryImages(_this.dispensary.id)
                .subscribe(function (images) {
                console.log(images);
                _this.dispensary.photos = images;
            });
            _this.stateId = _this.dispensary.licenseType.stateId;
            _this.dispensary.licenseExpiryDate = new Date(_this.dispensary.licenseExpiryDate);
            _this.licenseExpiryDate.day =
                _this.dispensary.licenseExpiryDate.getDate();
            _this.licenseExpiryDate.month =
                _this.dispensary.licenseExpiryDate.getMonth() + 1;
            _this.licenseExpiryDate.year =
                _this.dispensary.licenseExpiryDate.getFullYear();
            _this.businessType = [
                {
                    businessTypeId: _this.dispensary.businessType.id,
                    businessTypeName: _this.dispensary.businessType.name
                }
            ];
            _this.licenseType = [
                {
                    licenseTypeId: _this.dispensary.licenseType.id,
                    licenseTypeName: _this.dispensary.licenseType.name
                }
            ];
            _this.businessCity = [
                {
                    cityId: _this.dispensary.cityId,
                    cityName: _this.dispensary.businessCityName
                }
            ];
            _this.dispensary.licDesignationTypes.forEach(function (designation) {
                _this.licDesignations.push({
                    licDesignationTypeId: designation.id,
                    licDesignationTypeName: designation.name
                });
            });
            _this.dispensary.deliveryCities.forEach(function (city) {
                _this.deliveryCities.push({
                    cityId: city.cityId,
                    cityName: city.cityName
                });
            });
            _this.dispensary.paymentTypes.forEach(function (payment) {
                _this.payments.push({
                    paymentMethodId: payment.id,
                    paymentMethodName: payment.name
                });
            });
            _this.stateCodeList = data.stateCodeList;
        });
        return _this;
    }
    AdminDispensariesEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
        this.businessCitySettings = {
            singleSelection: true,
            idField: "cityId",
            textField: "cityName",
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
        this.businessTypeSettings = {
            singleSelection: true,
            idField: 'businessTypeId',
            textField: 'businessTypeName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
        this.licenseTypeSettings = {
            singleSelection: true,
            idField: 'licenseTypeId',
            textField: 'licenseTypeName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
        this.licDesignationsSettings = {
            singleSelection: false,
            idField: "licDesignationTypeId",
            textField: "licDesignationTypeName",
            enableCheckAll: false,
            allowSearchFilter: true,
            closeDropDownOnSelection: false
        };
        this.deliveryCitiesSettings = {
            singleSelection: false,
            idField: "cityId",
            textField: "cityName",
            enableCheckAll: false,
            allowSearchFilter: true,
            closeDropDownOnSelection: false
        };
        this.paymentMethodSettings = {
            singleSelection: false,
            idField: "paymentMethodId",
            textField: "paymentMethodName",
            allowSearchFilter: true,
            closeDropDownOnSelection: false
        };
        this.locationService
            .getStateCityList(this.dispensary.businessStateCode)
            .subscribe(function (cities) {
            _this.citySelectItems = cities;
        });
        this.locationService
            .getBusinessTypes()
            .subscribe(function (types) {
            _this.businessTypeSelectItems = types;
        });
        this.locationService
            .getLicDesignationTypes()
            .subscribe(function (types) {
            _this.licDesignationTypeSelectItems = types;
        });
        this.locationService
            .getLicenseTypesByState(this.dispensary.businessStateCode)
            .subscribe(function (types) {
            _this.licenseTypeSelectItems = types;
        });
        this.locationService.getSocialMediaSites().subscribe(function (socialMediaSites) {
            _this.socialMediaSites = socialMediaSites;
        });
        this.locationService.getReviewSites().subscribe(function (reviewSites) {
            _this.reviewSites = reviewSites;
        });
        this.locationService.getPaymentMethods().subscribe(function (paymentMethods) {
            _this.paymentMethodItems = paymentMethods;
        });
    };
    AdminDispensariesEditComponent.prototype.updateCities = function () {
        var _this = this;
        this.dispensary.cityId = -1;
        this.dispensary.businessCityName = "";
        this.dispensary.city = null;
        this.locationService
            .getStateCityList(this.dispensary.businessStateCode)
            .subscribe(function (cities) {
            _this.citySelectItems = cities;
            _this.businessCity = [];
            _this.deliveryCities = [];
        });
        this.locationService
            .getLicenseTypesByState(this.dispensary.businessStateCode)
            .subscribe(function (types) {
            _this.licenseTypeSelectItems = types;
        });
        this.locationService
            .getStateIdFromStateCode(this.dispensary.businessStateCode)
            .subscribe(function (id) {
            _this.stateId = id;
        });
        this.onLocationChange();
    };
    AdminDispensariesEditComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    AdminDispensariesEditComponent.prototype.hasDeliveryLicense = function () {
        if (!this.dispensary.businessType) {
            return false;
        }
        return this.dispensary
            .businessType.name
            .toLowerCase()
            .includes("delivery");
    };
    AdminDispensariesEditComponent.prototype.onBusinessCitySelect = function (city) {
        this.businessCity = [city];
        this.dispensary.cityId = city.cityId;
        this.dispensary.businessCityName = city.cityName;
        this.onLocationChange();
    };
    AdminDispensariesEditComponent.prototype.onBusinessCityDeSelect = function (city) {
        this.businessCity = [];
        this.dispensary.cityId = -1;
        this.dispensary.businessCityName = "";
        this.onLocationChange();
    };
    AdminDispensariesEditComponent.prototype.onBusinessTypeSelect = function (type) {
        this.businessType = [type];
        this.dispensary.businessTypeId = type.businessTypeId;
        this.dispensary.businessType = {
            id: type.businessTypeId,
            name: type.businessTypeName
        };
    };
    AdminDispensariesEditComponent.prototype.onBusinessTypeDeSelect = function (type) {
        this.businessType = [];
        this.dispensary.businessTypeId = -1;
        this.dispensary.businessType = null;
    };
    AdminDispensariesEditComponent.prototype.onLicenseTypeSelect = function (type) {
        this.licenseType = [type];
        this.dispensary.licenseTypeId = type.licenseTypeId;
        this.dispensary.licenseType = {
            id: type.licenseTypeId,
            name: type.licenseTypeName,
            stateId: this.stateId
        };
    };
    AdminDispensariesEditComponent.prototype.onLicenseTypeDeSelect = function (type) {
        this.licenseType = [];
        this.dispensary.licenseTypeId = -1;
        this.dispensary.licenseType = null;
    };
    AdminDispensariesEditComponent.prototype.dateChanged = function (dateObj) {
        if (typeof dateObj === 'string') {
            return;
        }
        if (!dateObj) {
            return;
        }
        this.dispensary.licenseExpiryDate =
            new Date(dateObj.year, dateObj.month - 1, dateObj.day);
    };
    AdminDispensariesEditComponent.prototype.onLicDesignationSelect = function (designation) {
        this.licDesignations.push(designation);
        this.dispensary.licDesignationTypes.push({
            id: designation.licDesignationTypeId,
            name: designation.licDesignationTypeName,
            licDesignation: {
                businessId: this.dispensary.id,
                licDesignationTypeId: designation.licDesignationTypeId
            }
        });
    };
    AdminDispensariesEditComponent.prototype.onLicDesignationDeSelect = function (designation) {
        this.licDesignations = this.licDesignations.filter(function (x) { return x.licDesignationTypeId !== designation.licDesignationTypeId; });
        this.dispensary.licDesignationTypes = this.dispensary.licDesignationTypes.filter(function (x) { return x.id !== designation.licDesignationTypeId; });
    };
    AdminDispensariesEditComponent.prototype.onDeliveryCitySelect = function (city) {
        this.deliveryCities.push(city);
        this.dispensary.deliveryCities.push({
            cityId: city.cityId,
            cityName: city.cityName,
            counties: [],
        });
    };
    AdminDispensariesEditComponent.prototype.onDeliveryCityDeSelect = function (city) {
        this.deliveryCities = this.deliveryCities.filter(function (x) { return x.cityId !== city.cityId; });
        this.dispensary.deliveryCities = this.dispensary.deliveryCities.filter(function (x) { return x.cityId !== city.cityId; });
    };
    AdminDispensariesEditComponent.prototype.onPaymentMethodSelect = function (paymentMethod) {
        this.payments.push(paymentMethod);
        this.dispensary.paymentTypes.push({
            id: paymentMethod.paymentMethodId,
            name: paymentMethod.paymentMethodName
        });
    };
    AdminDispensariesEditComponent.prototype.onPaymentMethodDeSelect = function (paymentMethod) {
        this.payments = this.payments.filter(function (x) { return x.paymentMethodId !== paymentMethod.paymentMethodId; });
        this.dispensary.paymentTypes = this.dispensary.paymentTypes.filter(function (x) { return x.id !== paymentMethod.paymentMethodId; });
    };
    AdminDispensariesEditComponent.prototype.onPaymentMethodSelectAll = function (paymentMethods) {
        this.payments = paymentMethods;
        this.dispensary.paymentTypes = this.payments.map(function (x) {
            return {
                id: x.paymentMethodId,
                name: x.paymentMethodName
            };
        });
    };
    AdminDispensariesEditComponent.prototype.onPaymentMethodDeSelectAll = function () {
        this.payments = [];
        this.dispensary.paymentTypes = [];
    };
    AdminDispensariesEditComponent.prototype.addSocialMediaSite = function () {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditSocialMediaSiteAddModalComponent);
        modalRef.componentInstance.businessId = this.dispensary.id;
        modalRef.componentInstance.socialMediaSites = this.socialMediaSites;
        modalRef.componentInstance.addSocialMediaSiteEmitter.subscribe(function (socialMediaSite) {
            _this.dispensary.socialMediaSites.push(socialMediaSite);
        });
    };
    AdminDispensariesEditComponent.prototype.editSocialMediaSite = function (socialMedia) {
        var modalRef = this.modalService.open(AdminDispensariesEditSocialMediaSiteEditModalComponent);
        modalRef.componentInstance.businessId = this.dispensary.id;
        modalRef.componentInstance.socialMedia = socialMedia;
        modalRef.componentInstance.socialMediaSites = this.socialMediaSites;
        modalRef.componentInstance.editSocialMediaSiteEmitter.subscribe(function (socialMediaSite) { });
    };
    AdminDispensariesEditComponent.prototype.deleteSocialMediaSite = function (socialMediaSite) {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditSocialMediaSiteDeleteModalComponent);
        modalRef.componentInstance.socialMediaSite = socialMediaSite;
        modalRef.componentInstance.deleteSocialMediaSiteEmitter.subscribe(function (socialMediaSite) {
            _this.dispensary.socialMediaSites = _this.dispensary.socialMediaSites.filter(function (s) { return s.id !== socialMediaSite.id; });
        });
    };
    AdminDispensariesEditComponent.prototype.addReviewSite = function () {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditReviewSiteAddModalComponent);
        modalRef.componentInstance.businessId = this.dispensary.id;
        modalRef.componentInstance.reviewSites = this.reviewSites;
        modalRef.componentInstance.addReviewSiteEmitter.subscribe(function (reviewSite) {
            _this.dispensary.reviewSites.push(reviewSite);
        });
    };
    AdminDispensariesEditComponent.prototype.editReviewSite = function (review) {
        var modalRef = this.modalService.open(AdminDispensariesEditReviewSiteEditModalComponent);
        modalRef.componentInstance.businessId = this.dispensary.id;
        modalRef.componentInstance.review = review;
        modalRef.componentInstance.reviewSites = this.reviewSites;
        modalRef.componentInstance.editReviewSiteEmitter.subscribe(function (reviewSite) { });
    };
    AdminDispensariesEditComponent.prototype.deleteReviewSite = function (reviewSite) {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditReviewSiteDeleteModalComponent);
        modalRef.componentInstance.reviewSite = reviewSite;
        modalRef.componentInstance.deleteReviewSiteEmitter.subscribe(function (reviewSite) {
            _this.dispensary.reviewSites = _this.dispensary.reviewSites.filter(function (s) { return s.id !== reviewSite.id; });
        });
    };
    AdminDispensariesEditComponent.prototype.addPhoto = function (type) {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditPhotoAddModalComponent);
        modalRef.componentInstance.editMode = false;
        modalRef.componentInstance.photoType = type;
        modalRef.componentInstance.business = this.dispensary;
        modalRef.componentInstance.businessId = this.dispensary.id;
        var currentOrder = this.dispensary.photos ? this.dispensary.photos.length + 1 : 1;
        if (currentOrder > 3) {
            modalRef.componentInstance.order = 1;
        }
        else {
            modalRef.componentInstance.order = currentOrder;
        }
        modalRef.componentInstance.addPhotoEmitter.subscribe(function (photo) {
            console.log('UPLOADED PHOTO?');
            console.log(photo);
            switch (type) {
                case 'profile':
                    _this.dispensary.profileImageId = photo.photoId;
                    _this.dispensary.profileImageExt = photo.photoExt;
                    _this.dispensary.profileImageAlt = photo.altText;
                    return;
                case 'gallery':
                    // If already 3 items exist, remove last item & add new item as #1
                    if (_this.dispensary.photos.length >= 3) {
                        _this.dispensary.photos.pop();
                        _this.dispensary.photos.unshift(photo);
                    }
                    else {
                        // Add to end if one of first 3 items
                        _this.dispensary.photos.push(photo);
                    }
                    return;
                case 'logo':
                    console.log(photo);
                    _this.dispensary.logoImageId = photo.photoId;
                    _this.dispensary.logoImageExt = photo.photoExt;
                    _this.dispensary.logoImageAltText = photo.altText;
                    return;
                default:
                    console.log('Did you forget to pass a photo type? [ profile | gallery | logo.');
                    return;
            }
        });
    };
    AdminDispensariesEditComponent.prototype.editPhoto = function (photo) {
        // Why are we even editing them, they should just have to delete and re-upload an image
        var modalRef = this.modalService.open(AdminDispensariesEditPhotoEditModalComponent);
        modalRef.componentInstance.editMode = false;
        modalRef.componentInstance.photo = photo;
        modalRef.componentInstance.editPhotoEmitter.subscribe(function (photo) {
            //
        });
    };
    AdminDispensariesEditComponent.prototype.deletePhoto = function (photo) {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditPhotoDeleteModalComponent);
        modalRef.componentInstance.photo = photo;
        modalRef.componentInstance.deletePhotoEmitter.subscribe(function (photo) {
            _this.dispensary.photos = _this.dispensary.photos.filter(function (f) { return f.photoId !== photo.photoId; });
        });
    };
    AdminDispensariesEditComponent.prototype.showDayName = function (dayId) {
        return this.daysOfWeek.find(function (x) { return x.dayOfWeekId === dayId; }).dayOfWeekName;
    };
    AdminDispensariesEditComponent.prototype.sortedOpenTimes = function () {
        return this.dispensary.openTimes.sort(function (a, b) {
            if (a.dayOfWeek === b.dayOfWeek) {
                return a.order - b.order;
            }
            else {
                return a.dayOfWeek - b.dayOfWeek;
            }
        });
    };
    AdminDispensariesEditComponent.prototype.addTime = function () {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditOpenTimeAddModalComponent);
        modalRef.componentInstance.businessId = this.dispensary.id;
        modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
        modalRef.componentInstance.addOpenTimeEmitter.subscribe(function (openTime) {
            _this.dispensary.openTimes.push(openTime);
        });
    };
    AdminDispensariesEditComponent.prototype.editTime = function (openTime) {
        var modalRef = this.modalService.open(AdminDispensariesEditOpenTimeEditModalComponent);
        modalRef.componentInstance.openTime = openTime;
        modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
        modalRef.componentInstance.editOpenTimeEmitter.subscribe(function (openTime) {
            // no action?
        });
    };
    AdminDispensariesEditComponent.prototype.deleteTime = function (openTime) {
        var _this = this;
        var modalRef = this.modalService.open(AdminDispensariesEditOpenTimeDeleteModalComponent);
        modalRef.componentInstance.openTime = openTime;
        modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
        modalRef.componentInstance.deleteOpenTimeEmitter.subscribe(function (openTime) {
            _this.dispensary.openTimes = _this.dispensary.openTimes.filter(function (f) { return f.dayOfWeek !== openTime.dayOfWeek || f.order !== openTime.order; });
        });
    };
    AdminDispensariesEditComponent.prototype.onLocationChange = function () {
        var _this = this;
        if (!this.geocoder)
            this.geocoder = new google.maps.Geocoder();
        var address = [
            this.dispensary.businessAddress,
            this.dispensary.businessCityName,
            this.dispensary.businessStateCode
        ]
            .filter(function (x) { return !!x; })
            .join(" ");
        this.geocoder.geocode({
            address: address
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results[0].geometry.location) {
                    _this.dispensary.latitude = results[0].geometry.location.lat();
                    _this.dispensary.longitude = results[0].geometry.location.lng();
                    _this.dispensary.zoom = 15;
                }
                _this.map.triggerResize();
            }
            else {
                console.log("Sorry, this search produced no results.");
            }
        });
    };
    AdminDispensariesEditComponent.prototype.onSubmit = function () {
        var _this = this;
        this.locationService.updateDispensary(this.dispensary, this.forceInvalidation).then(function (success) {
            _this.showForm = false;
            var dispensaryAdditionalUrl = _this.locationService.getInvalidateLink("/dispensaries/" + _this.dispensary.businessStateCode.toLowerCase() + "/" + _this.dispensary.city.counties[0].countySlug + "/" + _this.dispensary.city.citySlug + "/" + _this.dispensary.businessSlug + "/");
            _this.webAppInvalidateUrl = "https://www.mamasganja.com" + dispensaryAdditionalUrl;
            _this.webAppInvalidateUrlSource = "https://prod-mamasganja.herokuapp.com" + dispensaryAdditionalUrl;
        });
    };
    return AdminDispensariesEditComponent;
}(BaseComponent));
export { AdminDispensariesEditComponent };
