import { EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location.service';
var AdminAdsPhotoAddModalComponent = /** @class */ (function () {
    function AdminAdsPhotoAddModalComponent(addPhotoModal, locationService) {
        this.addPhotoModal = addPhotoModal;
        this.locationService = locationService;
        this.addPhotoEmitter = new EventEmitter();
        this.altText = '';
        this.adUrl = '';
        this.adCode = '';
    }
    Object.defineProperty(AdminAdsPhotoAddModalComponent.prototype, "formDisabled", {
        get: function () {
            return !this.adCode && (!this.photo || !this.altText.length || !this.adUrl.length);
        },
        enumerable: true,
        configurable: true
    });
    AdminAdsPhotoAddModalComponent.prototype.onFileSelect = function (event) {
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            this.photo = file;
        }
    };
    AdminAdsPhotoAddModalComponent.prototype.ngOnInit = function () {
    };
    AdminAdsPhotoAddModalComponent.prototype.add = function () {
        var _this = this;
        this.locationService.uploadAdImage(this.photo, this.altText, this.adUrl, this.adCode).subscribe(function (res) {
            _this.addPhotoEmitter.emit(res);
            _this.addPhotoModal.close();
        }, function (err) {
            console.error(err);
            alert(err.error.message);
        });
    };
    return AdminAdsPhotoAddModalComponent;
}());
export { AdminAdsPhotoAddModalComponent };
