import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Business } from '../../../model/business';
import { LocationService } from '../../../services/location.service';

import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { County } from '../../../model/county';
import { State } from '../../../model/state';
import { City } from '../../../model/city';
import { AdminAdsPhotoAddModalComponent } from '../admin-ads-photo-add-modal/admin-ads-photo-add-modal.component';
import { environment } from '../../../../environments/environment';
import { Photo } from '../../../model/photo';

export enum TopperMode {
  State = 'State',
  City = 'City',
  County = 'County'
}

@Component({
  selector: "app-admin-location-edit",
  templateUrl: "./admin-location-edit.component.html",
  styles: [`
  .buttons-wrapper { margin-bottom: 2rem; }
  .buttons-wrapper button { margin-right: 1rem; }
  .buttons-wrapper button.selected { background: #5279a4; color: #fff; }
`]
})
export class AdminLocationEditComponent extends BaseComponent implements OnInit {

  cdnRoot = environment.cdnRoot;

  dispensary: Business;
  statesList: State[];
  counties: County[];
  dispensaries: Business[];
  citySelectItems: City[];

  businessCitySettings: any = {};
  businessCountySettings: any = {};
  businessSettings: any = {};

  topperLocationName: string; // Can be stateName | countyName | cityName, visible on UI

  selectedState: State;
  selectedCity: City;
  selectedCounty: County;

  // What we're currently viewing & editing
  currentCollection: State | County | City;

  // Toppers & Ads
  selectedDispensaryOne: any[] = [];
  selectedDispensaryTwo: any[] = [];
  selectedDispensaryThree: any[] = [];
  selectedAd: number;

  // For display
  adImage: Partial<Photo>;
  adImageSrc: string;

  topperEditMode: string;
  TopperMode = TopperMode;

  forceInvalidation: boolean = false;
  forceInvalidationState: boolean = false;

  get canEditToppers() {
    return this.selectedState && this.selectedState.stateName
      && (
        this.topperEditMode === TopperMode.City && this.selectedCity ? true : false
        ||
        this.topperEditMode === TopperMode.County && this.selectedCounty ? true : false
        ||
        this.topperEditMode === TopperMode.State && this.selectedState.stateName ? true : false
      )
  }

  constructor(
    private seo: SeoService,
    private locationService: LocationService,
    private modalService: NgbModal
  ) {
    super();

    this.locationService.getAllDispensaries().subscribe(dispensaries => {
      dispensaries.map((business: Business) => {
          business.dba = `${business.dba} - ${business.businessCityName}, ${business.businessStateCode}`
          return business;
        });

      console.log({dispensaries});

      this.dispensaries = dispensaries;
    });

    this.refreshStates();

    this.index = false;
    this.follow = false;
  }

  refreshStates(selectedState?) {
    this.locationService.getStateList().subscribe(states => {
      this.statesList = states;
      if (selectedState) {
        this.selectedState = states.find(state => state.id === selectedState.id);
        if (this.selectedState.adPhotoId) {
          this.displayAdImage(this.selectedState.adPhotoId);
        }
      }
    });
  }

  refreshCities(selectedState) {
    this.locationService.getAllCities(selectedState.stateCode).subscribe(cityList => {
      // console.log({cityList});
      this.citySelectItems = cityList;
    })
  }

  refreshCounties(selectedState) {
    this.locationService.getAllCounties(selectedState.stateCode).subscribe(counties => {
      // console.log({counties})
      this.counties = counties;
    })
  }

  getStateInfo(selectedState) {
    // console.log({selectedState});

    this.changeEditMode(TopperMode.State, selectedState);
    this.topperLocationName = selectedState.stateName;

    this.refreshCities(selectedState);
    this.refreshCounties(selectedState);
  }

  changeEditMode(type, collection?) {
    console.log({topperEditMode: type})

    if (type === 'State') {
      this.topperLocationName = this.selectedState.stateName;
    }

    this.selectedCity = null;
    this.selectedCounty = null;

    this.selectedDispensaryOne = null;
    this.selectedDispensaryTwo = null;
    this.selectedDispensaryThree = null;

    this.selectedAd = null;
    this.adImage = null;
    this.adImageSrc = null;

    if (collection) {
      this.fillSelections(collection);
    }

    this.topperEditMode = TopperMode[type];
  }

  fillSelections(collection: State | City | County) {
    console.log('\n>> fillSelections >>');
    console.log(collection);

    this.currentCollection = collection;

    this.selectedDispensaryOne = collection.topperOneBusinessId ? [
      this.dispensaries.filter(d => d.id === collection.topperOneBusinessId)[0]
    ] : [];
    this.selectedDispensaryTwo = collection.topperTwoBusinessId ? [
      this.dispensaries.filter(d => d.id === collection.topperTwoBusinessId)[0]
    ] : [];
    this.selectedDispensaryThree = collection.topperThreeBusinessId ? [
      this.dispensaries.filter(d => d.id === collection.topperThreeBusinessId)[0]
    ] : [];
    this.selectedAd = collection.adPhotoId;

    // Show uploaded photo
    if (collection.adPhotoId) {
      this.displayAdImage(collection.adPhotoId);
    } else {
      this.adImage = null;
      this.adImageSrc = '';
    }

  }


  ngOnInit() {

    this.seo.addTags([
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
    ]);

    this.businessCitySettings = {
      singleSelection: true,
      idField: "cityId",
      textField: "cityName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      defaultOpen: true
    };

    this.businessCountySettings = {
      singleSelection: true,
      idField: "id",
      textField: "countyName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      defaultOpen: true
    };

    this.businessSettings = {
      singleSelection: true,
      idField: "id",
      textField: "dba",
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onDispensarySelect(dispensary, topperLevel: number) {
    console.log({ dispensary, topperLevel})
  }
  onDispensaryDeSelect(dispensary, topperLevel: number) {
    console.log({ dispensary, topperLevel})
  }

  onBusinessCitySelect(city) {
    // console.log({city})
    this.topperLocationName = city.cityName;
    const fullCityData = this.citySelectItems.filter((c: any) => c.cityId === city.cityId)[0];
    this.fillSelections(fullCityData);
  }
  onBusinessCityDeSelect() {
    this.selectedCity = null;
  }

  onCountySelect(county) {
    // console.log({county});
    this.topperLocationName = county.countyName;
    const fullCountyData = this.counties.filter(c => c.id === county.id)[0];
    this.fillSelections(fullCountyData);
  }
  onCountyDeSelect() {
    this.selectedCounty = null;
  }

  onSubmit() {
    const getValue = (item) => {
      return item && item.length ? item[0] && item[0].id : null;
    }

    const toppersAndAds = {
      topperOneBusinessId: getValue(this.selectedDispensaryOne),
      topperTwoBusinessId: getValue(this.selectedDispensaryTwo),
      topperThreeBusinessId: getValue(this.selectedDispensaryThree),
      adPhotoId: this.selectedAd,
    };

    const stateCode = this.selectedState.stateCode.toLowerCase();

    this.currentCollection = {
      ...this.currentCollection,
      ...toppersAndAds
    };

    // console.log(`\n\n EDIT MODE : ${this.topperEditMode}\n\n`);
    // console.log({ currentCollection: this.currentCollection });

    switch (this.topperEditMode) {

      case this.TopperMode.City:
        const city = this.currentCollection as City;
        this.locationService.updateCity(city, this.forceInvalidation)
          .subscribe((res: any) => {
            if (res.status === 200) {
              alert('Saved!');
              this.refreshCities(this.selectedState);
            }
          })
        return;

      case this.TopperMode.County:
        const county = this.currentCollection as County;
        this.locationService.updateCounty(county, this.forceInvalidation)
          .subscribe((res: any) => {
            if (res.status === 200) {
              alert('Saved!');
              this.refreshCounties(this.selectedState);
            }
          })
        return;

      case this.TopperMode.State:
        const state = this.currentCollection as State;
        this.locationService.updateState(state, this.forceInvalidation)
          .subscribe((res: any) => {
            if (res.status === 200) {
              alert('Saved!');
              this.refreshStates(this.selectedState);
            }
          })
        return;

      default:
        return;
    }
  }

  updateAllStateLocations() {

    const confirmed = window.confirm(`WARNING:
This will Save this Ad to ALL Counties & Cities for: ${this.topperLocationName}
(that do -NOT- already have an Ad selected)

>> This process is irreversible <<
------------------------------------
Are you sure you want to continue?`);

    if (confirmed) {
      this.locationService.updateAdToAllLocationsInState(this.currentCollection as State, this.forceInvalidationState)
        .subscribe((res: any) => {
          if (res.status === 20) {
            console.log(res);
            this.refreshStates(this.selectedState);
            this.refreshCities(this.selectedState);
            this.refreshCounties(this.selectedState);

            window.location.reload(true);
          }
        });
    }

  }

  imageModal(type?: string, editMode: boolean = false) {
    const modalRef = this.modalService.open(
      AdminAdsPhotoAddModalComponent
    );

    modalRef.componentInstance.editMode = editMode;
    modalRef.componentInstance.photoType = 'ad';
    modalRef.componentInstance.businessId = '';

    modalRef.componentInstance.addPhotoEmitter.subscribe(photo => {
      switch (type) {
        case 'ad':
          this.displayAdImage(null, photo)
          this.selectedAd = photo.id;
          return;

        default:
          console.log('Did you forget to pass a photo type?');
          return;
      }
    });
  }

  displayAdImage(id, photo?: Photo) {
    // We have the Name of the file from processed upload
    if (photo) {
      console.log('we just uploaded a photo!');
      console.log(photo.photoId)
      const url = `${this.cdnRoot}/images/ad/${photo.photoId}`;
      this.adImage = photo;
      this.adImageSrc = url;
      return;
    }

    // Get image by ID
    this.locationService.getImageById(id)
    .subscribe((image: Photo) => {
      const url = `${this.cdnRoot}/images/ad/${image.photoId}`;

      this.adImage = image;
      this.adImageSrc = url;
    });

  }

}
