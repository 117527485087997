import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location.service';
import { Business } from '../../../model/business';

@Component({
  selector: 'app-admin-dispensaries-edit-photo-add-modal',
  templateUrl: './admin-dispensaries-edit-photo-add-modal.component.html'
})
export class AdminDispensariesEditPhotoAddModalComponent implements OnInit {
  @Input() public businessId: number;
  @Input() public business: Business;
  @Input() public editMode: boolean;
  @Input() public order: number;
  @Input() public photoType: 'profile' | 'logo' | 'gallery';
  @Output() addPhotoEmitter: EventEmitter<any> = new EventEmitter();

  public altText: string;
  public fileName: string;

  public photo: any = {
    businessId: -1,
    photoId: '',
    photoExt: '',
    altText: ''
  };

  constructor(
    public addPhotoModal: NgbActiveModal,
    private locationService: LocationService
  ) {
  }

  get formDisabled() {
    return !this.photo || !this.altText;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);

      // LOGO -
      // <DBA Name> Dispensary Logo Image

      // PROFILE -
      // <DBA Name> Dispensary Profile Image

      // ALL GALLERY (3) -
      // <DBA Name> Legal Marijuana Dispensary

      if (['profile', 'logo'].includes(this.photoType)) {
        const imageType = this.photoType.charAt(0).toUpperCase() + this.photoType.slice(1);
        this.altText = `${(this.business.dba || this.business.businessName)} Dispensary ${imageType} Image`;
      } else {
        // gallery images
        this.altText = `${(this.business.dba || this.business.businessName)} Legal Marijuana Dispensary`;
      }

      this.photo = file;
    }
  }

  ngOnInit() {
    // console.log(this.business.businessName);
    // console.log(this.business.dba);

    this.fileName = `${this.photoType}`;

    if (this.photoType === 'gallery') {
      this.fileName = `${this.fileName}${this.order ? ('-' + this.order) : ''}-${this.photoType}`;
      console.log(this.fileName);
    }

    this.photo.businessId = this.businessId;
    this.photo.photoType = this.photoType;
  }

  add() {
    this.locationService.uploadImage(this.photo, this.fileName, this.photoType, this.order).subscribe((res: any) => {
      console.log('Response from uploading image?')
      console.log(res);

      this.photo = {
        ...this.photo,
        altText: this.altText,
        businessId: this.businessId,
        photoExt: res.photoExt,
        photoId: res.photoId,
      };

      this.addPhotoEmitter.emit(this.photo);
      this.addPhotoModal.close();
    });
  }

}
