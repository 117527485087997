<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Delete Open Time?</h4>
  <button type="button" class="close" aria-label="Close" (click)="deleteOpenTimeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to delete this open time?</p>

  <p>
    {{ showDayName(openTime.dayOfWeek) }}<br />
    {{ openTime.startTime }}<br />
    {{ openTime.endTime }}<br />
    {{ openTime.order }}
  </p>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deleteOpenTimeModal.dismiss()">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="delete(openTime)">Yes</button>
</div>