import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSite } from '../../../model/review-site';

@Component({
    selector: 'app-admin-dispensaries-edit-review-site-add-modal',
    templateUrl: './admin-dispensaries-edit-review-site-add-modal.component.html'
})
export class AdminDispensariesEditReviewSiteAddModalComponent implements OnInit {
    @Input() public businessId: number;
    @Input() public reviewSites: any[];
    @Output() addReviewSiteEmitter: EventEmitter<any> = new EventEmitter();

    review: ReviewSite;
    reviewSite: any[] = [];
    reviewSiteSettings: any = {};

    constructor(public addReviewSiteModal: NgbActiveModal) {
        this.review  = {
          id: -1,
          name: '',
          icon: '',
          baseUrl: '',
          review: {
            businessId: -1,
            reviewSiteId: -1,
            slug: '',
            reviewCount: 0,
            rating: 0.0
          }
        };

    }

    ngOnInit() {
        this.review.review.businessId = this.businessId;
        this.reviewSiteSettings = {
            singleSelection: true,
            idField: 'reviewSiteId',
            textField: 'reviewSiteName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
    }

    onReviewSiteSelect(reviewSite) {
      this.review.id = reviewSite.reviewSiteId;
      this.review.name = reviewSite.reviewSiteName;
      this.review.review.reviewSiteId = reviewSite.reviewSiteId;
    }

    onReviewSiteDeSelect(reviewSite) {
      this.review.id = -1;
      this.review.name = '';
      this.review.review.reviewSiteId = -1;
    }

    add(reviewSite) {
      this.addReviewSiteEmitter.emit(reviewSite);
      this.addReviewSiteModal.close();
    }
}
