<div class="col-lg-6 offset-lg-3">
  <div id="wrapper">
    <form #searchForm="ngForm">
      <div class="form-group">
        <label for="dispensarySearch">Dispensary</label>
        <input
          type="text"
          id="dispensarySearch"
          [(ngModel)]="q"
          name="dispensarySearch"
          class="form-control"
          required
        />
      </div>
      <div class="form-group">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="searchType" [(ngModel)]="searchType">
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="name"> Name
          </label>
          <label ngbButtonLabel class="btn-primary">
            <input ngbButton type="radio" value="dba"> DBA
          </label>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-block"
        id="submit"
        [disabled]="!searchForm.form.valid"
        (click)="searchDispensaries()"
      >
        Search
      </button>
    </form>
  </div>
  <div *ngIf="matches">
    <div *ngIf="matches.length">
      <div *ngFor="let match of matches" class="match-container">
        <span>
          <em>
            <strong>{{ match.businessName }}</strong>
            {{ match.businessCityName }}
          </em>
          &nbsp;({{match.dba}})
        </span>
        <span class="pull-right">
          <button class="btn btn-sm btn-success" (click)="edit(match.id)">
            <i class="fa fa-edit"></i>
          </button>
          <button class="btn btn-sm btn-danger" (click)="delete(match.id)">
            <i class="fa fa-trash"></i>
          </button>
        </span>
      </div>
    </div>
    <div *ngIf="!matches.length">
      No matches.
    </div>
  </div>
</div>
