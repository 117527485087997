<div class="col-lg-6 offset-lg-3" *ngIf="isAdmin">
    <div id="wrapper">
        <a class="btn btn-block btn-lg btn-primary" href="admin/dispensaries/search">
            Dispensaries
        </a>
        <a class="btn btn-block btn-lg btn-primary" href="admin/location/edit">
            Toppers & Ads
        </a>
        <a class="btn btn-block btn-lg btn-primary" href="admin/featured/">
            Featured Dispensaries
        </a>
    </div>
</div>
<div class="col-lg-6 offset-lg-3" *ngIf="!isAdmin">
  <div *ngIf="stateButtons && !stateClicked">

    <div *ngFor="let t of temp; let i = index" class="row">
      <div *ngFor="let stateButton of stateButtons.slice(4*i,4*i + 4);" class="col-3">
        <button class="btn btn-primary btn-block my-3" (click)="getBusinesses(stateButton.id)">
          {{stateButton.stateName}}
        </button>
      </div>
    </div>
  </div>

    <div *ngIf="businesses && stateClicked">
      <div style="text-align: center;">
        [
        <a href="/admin/"
          >Back to State List</a
        >
        ]
      </div>

        <div *ngIf="businesses.length">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">DBA</th>
                <th scope="col">Company Name</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let biz of businesses">
                <td>{{ biz.dba }}</td>
                <td>{{ biz.businessName }}</td>
                <td>{{ biz.businessCityName }}</td>
                <td>{{ biz.businessStateCode }}</td>
                <td>
                  <button class="btn btn-sm btn-success" (click)="edit(biz.id)">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
                <td>
                  <span class="badge badge-pill badge-danger" 
                      *ngIf="!biz.openClosed && !biz.isPermanentlyClosed && !biz.hasStartedEditing">
                    Needs Data
                  </span>
                  <span class="badge badge-pill badge-warning" 
                      *ngIf="!biz.openClosed && !biz.isPermanentlyClosed && biz.hasStartedEditing">
                    Not Open Yet
                  </span>
                  <span class="badge badge-pill badge-dark" 
                      *ngIf="biz.isPermanentlyClosed">
                    Permanently Closed
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!businesses.length">
          No unverified businesses.
        </div>
      </div>
</div>
