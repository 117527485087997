import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Photo } from '../../../model/photo';

@Component({
    selector: 'app-admin-dispensaries-edit-photo-delete-modal',
    templateUrl: './admin-dispensaries-edit-photo-delete-modal.component.html'
})
export class AdminDispensariesEditPhotoDeleteModalComponent {
    @Input() photo: Photo;
    @Output() deletePhotoEmitter: EventEmitter<any> = new EventEmitter();

    constructor(public deletePhotoModal: NgbActiveModal) {}

    delete(photo) {
        this.deletePhotoEmitter.emit(photo);
        this.deletePhotoModal.close();
    }
}
