import { isPlatformBrowser } from '@angular/common';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
//(window as any).global = window;
var AuthService = /** @class */ (function () {
    function AuthService(router, platformId) {
        this.router = router;
        this.platformId = platformId;
        this.auth0 = new auth0.WebAuth({
            clientID: environment.auth.clientID,
            domain: environment.auth.domain,
            responseType: 'token',
            redirectUri: environment.auth.redirect,
            audience: environment.auth.audience,
            scope: environment.auth.scope
        });
    }
    AuthService.prototype.login = function () {
        if (isPlatformBrowser(this.platformId)) {
            this.auth0.authorize();
        }
    };
    // TODO: Fix this
    AuthService.prototype.handleLoginCallback = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.auth0.parseHash(function (err, authResult) {
                if (authResult && authResult.accessToken) {
                    window.location.hash = '';
                    _this.getUserInfo(authResult).then(function () {
                        _this.router.navigate(['/admin/']);
                    });
                }
                else if (err) {
                    console.error("Error: " + err.error);
                }
            });
        }
    };
    AuthService.prototype.getAccessToken = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.auth0.checkSession({}, function (err, authResult) {
                if (authResult && authResult.accessToken) {
                    _this.getUserInfo(authResult).then(function (x) {
                        resolve(x);
                    });
                }
                else {
                    resolve(null);
                }
            });
        });
    };
    AuthService.prototype.getUserEmail = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.auth0.checkSession({}, function (err, authResult) {
                if (authResult && authResult.accessToken) {
                    _this.getUserInfo(authResult).then(function (x) {
                        resolve(x['userProfile'].email);
                    });
                }
                else {
                    resolve(null);
                }
            });
        });
    };
    AuthService.prototype.isAdminEmail = function (email) {
        var adminEmails = [
            'mlybrand+ganjadev@gmail.com',
            'george@newmediaroots.com',
        ];
        //return false;
        return adminEmails.includes(email);
    };
    AuthService.prototype.getUserInfo = function (authResult) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.auth0.client.userInfo(authResult.accessToken, function (err, profile) {
                if (profile) {
                    resolve(_this._setSession(authResult, profile));
                }
            });
        });
    };
    AuthService.prototype._setSession = function (authResult, profile) {
        var returnObj = {
            expiresAt: authResult.expiresIn * 1000 + Date.now(),
            accessToken: authResult.accessToken,
            userProfile: profile,
            authenticated: true
        };
        return returnObj;
    };
    AuthService.prototype.logout = function () {
        if (isPlatformBrowser(this.platformId)) {
            this.auth0.logout({
                returnTo: environment.apiRoot,
                clientID: environment.auth.clientID
            });
        }
    };
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            if (isPlatformBrowser(this.platformId)) {
                return this.getAccessToken()
                    .then(function (token) {
                    if (!token) {
                        return false;
                    }
                    console.log('email');
                    console.log(token['userProfile'].email);
                    return Date.now() < token['expiresAt'] && token['authenticated'];
                });
            }
            else {
                return new Promise(function (resolve) {
                    resolve(true);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isAdmin", {
        get: function () {
            var _this = this;
            if (isPlatformBrowser(this.platformId)) {
                return this.getUserEmail()
                    .then(function (email) {
                    if (!email) {
                        return false;
                    }
                    return _this.isAdminEmail(email);
                });
            }
            else {
                return new Promise(function (resolve) {
                    resolve(true);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    return AuthService;
}());
export { AuthService };
