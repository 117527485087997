import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialMediaSite } from '../../../model/social-media-site';

@Component({
    selector: 'app-admin-dispensaries-edit-social-media-site-delete-modal',
    templateUrl: './admin-dispensaries-edit-social-media-site-delete-modal.component.html'
})
export class AdminDispensariesEditSocialMediaSiteDeleteModalComponent {
    @Input() socialMediaSite: SocialMediaSite;
    @Output() deleteSocialMediaSiteEmitter: EventEmitter<any> = new EventEmitter();

    constructor(public deleteSocialMediaSiteModal: NgbActiveModal) {}

    delete(socialMediaSite) {
        this.deleteSocialMediaSiteEmitter.emit(socialMediaSite);
        this.deleteSocialMediaSiteModal.close();
    }
}
