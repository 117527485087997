import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';

@Component({
    selector: 'app-admin-dispensaries-delete',
    templateUrl: './admin-dispensaries-delete.component.html'
})
export class AdminDispensariesDeleteComponent extends BaseComponent implements OnInit {

    constructor(
        private seo: SeoService
    ) {
        super();
        this.index = false;
        this.follow = false;
    }

    ngOnInit() {
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
          ]);
    }
}
