var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location.service';
var AdminDispensariesEditPhotoAddModalComponent = /** @class */ (function () {
    function AdminDispensariesEditPhotoAddModalComponent(addPhotoModal, locationService) {
        this.addPhotoModal = addPhotoModal;
        this.locationService = locationService;
        this.addPhotoEmitter = new EventEmitter();
        this.photo = {
            businessId: -1,
            photoId: '',
            photoExt: '',
            altText: ''
        };
    }
    Object.defineProperty(AdminDispensariesEditPhotoAddModalComponent.prototype, "formDisabled", {
        get: function () {
            return !this.photo || !this.altText;
        },
        enumerable: true,
        configurable: true
    });
    AdminDispensariesEditPhotoAddModalComponent.prototype.onFileSelect = function (event) {
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            console.log(file);
            // LOGO -
            // <DBA Name> Dispensary Logo Image
            // PROFILE -
            // <DBA Name> Dispensary Profile Image
            // ALL GALLERY (3) -
            // <DBA Name> Legal Marijuana Dispensary
            if (['profile', 'logo'].includes(this.photoType)) {
                var imageType = this.photoType.charAt(0).toUpperCase() + this.photoType.slice(1);
                this.altText = (this.business.dba || this.business.businessName) + " Dispensary " + imageType + " Image";
            }
            else {
                // gallery images
                this.altText = (this.business.dba || this.business.businessName) + " Legal Marijuana Dispensary";
            }
            this.photo = file;
        }
    };
    AdminDispensariesEditPhotoAddModalComponent.prototype.ngOnInit = function () {
        // console.log(this.business.businessName);
        // console.log(this.business.dba);
        this.fileName = "" + this.photoType;
        if (this.photoType === 'gallery') {
            this.fileName = "" + this.fileName + (this.order ? ('-' + this.order) : '') + "-" + this.photoType;
            console.log(this.fileName);
        }
        this.photo.businessId = this.businessId;
        this.photo.photoType = this.photoType;
    };
    AdminDispensariesEditPhotoAddModalComponent.prototype.add = function () {
        var _this = this;
        this.locationService.uploadImage(this.photo, this.fileName, this.photoType, this.order).subscribe(function (res) {
            console.log('Response from uploading image?');
            console.log(res);
            _this.photo = __assign({}, _this.photo, { altText: _this.altText, businessId: _this.businessId, photoExt: res.photoExt, photoId: res.photoId });
            _this.addPhotoEmitter.emit(_this.photo);
            _this.addPhotoModal.close();
        });
    };
    return AdminDispensariesEditPhotoAddModalComponent;
}());
export { AdminDispensariesEditPhotoAddModalComponent };
