var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { SeoService } from '../../../services/seo.service';
import { AuthService } from '../../../services/auth.service';
import { LocationService } from '../../../services/location.service';
var AdminIndexComponent = /** @class */ (function (_super) {
    __extends(AdminIndexComponent, _super);
    function AdminIndexComponent(seo, locationService, authService, router) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.locationService = locationService;
        _this.authService = authService;
        _this.router = router;
        _this.isAdmin = false;
        _this.math = Math;
        _this.stateClicked = false;
        _this.index = false;
        _this.follow = false;
        return _this;
    }
    AdminIndexComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.isAdmin.then(function (state) {
            _this.isAdmin = state;
            if (!_this.isAdmin) {
                _this.locationService.getPendStates().subscribe(function (stateButtons) {
                    _this.stateButtons = stateButtons;
                    _this.temp = new Array(Math.ceil(stateButtons.length / 4));
                });
            }
        });
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
    };
    AdminIndexComponent.prototype.edit = function (id) {
        this.router.navigate(["admin/dispensaries/copyedit/" + id]);
    };
    AdminIndexComponent.prototype.getBusinesses = function (stateId) {
        var _this = this;
        this.stateClicked = true;
        this.locationService.getPendList(stateId).subscribe(function (businesses) {
            _this.businesses = businesses;
        });
    };
    return AdminIndexComponent;
}(BaseComponent));
export { AdminIndexComponent };
