import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';


import { LocationService } from './services/location.service';

@Injectable()
export class StateGuard implements CanActivate {

    constructor(
        private router: Router,
        private location: Location,
        private locationService: LocationService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean>|Promise<boolean>|boolean {
        let stateCode = route.params.state;

        return this.locationService.checkState(stateCode)
            .pipe(map(isValid => {
                if (!isValid) {
                    this.router.navigate(['404']);
                    let url = state.url;
                    if (!url.endsWith('/')) {
                        url = `${url}/`
                    }
                    setTimeout(() => this.location.replaceState(url));
                }
                return isValid;
            }));
    }
}
