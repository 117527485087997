import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenTime } from '../../../model/open-time';

@Component({
  selector: 'app-admin-dispensaries-edit-open-time-add-modal',
  templateUrl: './admin-dispensaries-edit-open-time-add-modal.component.html'
})
export class AdminDispensariesEditOpenTimeAddModalComponent implements OnInit {
  @Input() public businessId: number;
  @Input() public daysOfWeek: any[];
  @Output() addOpenTimeEmitter: EventEmitter<any> = new EventEmitter();

  dayOfWeek: any[] = [];
  daysOfWeekSettings: any = {};

  public openTime: OpenTime = {
    businessId: null,
    dayOfWeek: null,
    startTime: '',
    endTime: '',
    order: null
  };

  constructor(public addOpenTimeModal: NgbActiveModal) { }

  ngOnInit() {
    this.openTime.businessId = this.businessId;

    this.daysOfWeekSettings = {
      singleSelection: true,
      idField: 'dayOfWeekId',
      textField: 'dayOfWeekName',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

  }

  add(openTime) {
    this.addOpenTimeEmitter.emit(openTime);
    this.addOpenTimeModal.close();
  }

  onDayOfWeekSelect(day) {
    this.openTime.dayOfWeek = day.dayOfWeekId;
    this.dayOfWeek = [
        {
          dayOfWeekId: day.dayOfWeekId,
          dayOfWeekName: day.dayOfWeekName
        }
    ];
  }

  onDayOfWeekDeSelect(day) {
    this.openTime.dayOfWeek = null;
    this.dayOfWeek = [];
  }

}
