<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Delete photo?</h4>
  <button type="button" class="close" aria-label="Close" (click)="deletePhotoModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to delete this photo?</p>

  <p>
    {{ photo.photoId }}.{{ photo.photoExt }}
  </p>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deletePhotoModal.dismiss()">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="delete(photo)">Yes</button>
</div>