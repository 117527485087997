var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService }  from './in-memory-data.service';
var TrailingSlashUrlSerializer = /** @class */ (function (_super) {
    __extends(TrailingSlashUrlSerializer, _super);
    function TrailingSlashUrlSerializer(platformId, appId) {
        var _this = _super.call(this) || this;
        _this.platformId = platformId;
        _this.appId = appId;
        return _this;
    }
    TrailingSlashUrlSerializer.prototype.serialize = function (tree) {
        var path = _super.prototype.serialize.call(this, tree);
        if (!isPlatformBrowser(this.platformId)) {
            return path;
        }
        var newPath = "";
        var _a = path.split("#"), pathQueryString = _a[0], anchor = _a[1];
        var _b = pathQueryString.split("?"), base = _b[0], queryString = _b[1];
        newPath = base;
        if (!base.endsWith("/")) {
            newPath += "/";
        }
        if (queryString) {
            newPath += "?" + queryString;
        }
        if (anchor) {
            newPath += "#" + anchor;
        }
        return newPath;
    };
    return TrailingSlashUrlSerializer;
}(DefaultUrlSerializer));
export { TrailingSlashUrlSerializer };
var AppModule = /** @class */ (function () {
    function AppModule(platformId, appId) {
        this.platformId = platformId;
        this.appId = appId;
        var platform = isPlatformBrowser(platformId)
            ? "in the browser"
            : "on the server";
        // console.log(`Running ${platform} with appId=${appId}`);
    }
    return AppModule;
}());
export { AppModule };
