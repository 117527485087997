import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSite } from '../../../model/review-site';

@Component({
    selector: 'app-admin-dispensaries-edit-review-site-edit-modal',
    templateUrl: './admin-dispensaries-edit-review-site-edit-modal.component.html'
})
export class AdminDispensariesEditReviewSiteEditModalComponent implements OnInit {
    @Input() public businessId: number;
    @Input() public review: ReviewSite;
    @Input() public reviewSites: any[];
    @Output() editReviewSiteEmitter: EventEmitter<any> = new EventEmitter();

    reviewSite: any[] = [];
    reviewSiteSettings: any = {};

    constructor(public editReviewSiteModal: NgbActiveModal) {
        this.review  = {
          id: -1,
          name: '',
          icon: '',
          baseUrl: '',
          review: {
            businessId: -1,
            reviewSiteId: -1,
            slug: '',
            reviewCount: 0,
            rating: 0
          }
        };
    }

    ngOnInit() {
        this.review.review.businessId = this.businessId;
        this.reviewSiteSettings = {
            singleSelection: true,
            idField: 'reviewSiteId',
            textField: 'reviewSiteName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };

        this.reviewSite = [
          {
            reviewSiteId: this.review.id,
            reviewSiteName: this.review.name
          }
        ];
    }

    onReviewSiteSelect(reviewSite) {
      this.review.id = reviewSite.reviewSiteId;
      this.review.name = reviewSite.reviewSiteName;
      this.review.review.reviewSiteId = reviewSite.reviewSiteId;
    }

    onReviewSiteDeSelect(reviewSite) {
      this.review.id = -1;
      this.review.name = '';
      this.review.review.reviewSiteId = -1;
    }

    edit(reviewSite) {
      this.editReviewSiteEmitter.emit(reviewSite);
      this.editReviewSiteModal.close();
    }
}
