<div class="col-lg-8 offset-lg-2">
  <div id="wrapper">
    <form #editDispensaryForm="ngForm" (submit)="onSubmit()" type="multipart/form-data" *ngIf="showForm">
      <div
        class="form-group"
        [class.is-invalid]="businessName.touched && !businessName.valid"
      >
        <label for="businessName"
          >Business name <span class="require-symbol">*</span></label
        >
        <input
          #businessName="ngModel"
          [(ngModel)]="dispensary.businessName"
          type="text"
          id="businessName"
          name="businessName"
          class="form-control"
          required
        />
        <span
          class="help-block"
          *ngIf="
            businessName.errors &&
            businessName.errors.required &&
            businessName.touched
          "
        >
          Business name is required.
        </span>
      </div>
      <div class="form-group">
        <label for="dba">DBA</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="For 1 location use just doing business as name, Called  'DBA' (Example: Uncle Ike's). For 2 or more locations, use doing business as DBA name and add a location identifier (Example: Uncle Ike's Central District). Usually, when a business has 2 or more locations the business will use an identifier on the business website, or Google business listing. You can use the same one or make one up. Finally, the word Dispensary should NOT be used in the DBA. If the word Dispensary is in the DBA please replace with the word Marijuana.">Tip</button>
        <input
          [(ngModel)]="dispensary.dba"
          type="text"
          id="dba"
          name="dba"
          class="form-control"
        />
      </div>
      <div
        class="form-group"
        [class.is-invalid]="businessAddress.touched && !businessAddress.valid"
      >
        <label for="businessAddress"
          >Business address</label
        >
        <button type="button" class="btn btn-link pull-right" ngbPopover="Address location customers can visit. Can be found from the business website, or Google business listing.">Tip</button>
        <input
          #businessAddress="ngModel"
          [(ngModel)]="dispensary.businessAddress"
          type="text"
          id="businessAddress"
          name="businessAddress"
          class="form-control"
          (change)="onLocationChange()"
        />
      </div>
      <div class="form-group">
        <label for="businessStateCode">Business state code</label>
        <select
          [(ngModel)]="dispensary.businessStateCode"
          id="businessStateCode"
          name="businessStateCode"
          class="form-control"
          (change)="updateCities()"
        >
          <option *ngFor="let code of stateCodeList" [ngValue]="code">
            {{ code }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="businessCityName">City</label>
        <ng-multiselect-dropdown
          name="businessCity"
          [(ngModel)]="businessCity"
          [placeholder]="'Select City'"
          [data]="citySelectItems"
          [settings]="businessCitySettings"
          (onSelect)="onBusinessCitySelect($event)"
          (onDeSelect)="onBusinessCityDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="businessZip">ZIP Code</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="Always a 5-digit number (Example: 98122). Can be found from the business website, or Google business listing.">Tip</button>
        <input
          [(ngModel)]="dispensary.businessZip"
          type="text"
          id="businessZip"
          name="businessZip"
          class="form-control"
        />
      </div>
      <div
        class="form-group"
        [class.is-invalid]="businessPhone.touched && !businessPhone.valid"
      >
        <label for="businessPhone"
          >Phone number <span class="require-symbol">*</span></label
        >
        <button type="button" class="btn btn-link pull-right" ngbPopover="Always digits only (Example: 9498377889). Can be found from the business website, or Google business listing.">Tip</button>
        <input
          #businessPhone="ngModel"
          [(ngModel)]="dispensary.businessPhone"
          type="text"
          id="businessPhone"
          name="businessPhone"
          class="form-control"
          required
        />
        <span
          class="help-block"
          *ngIf="
            businessPhone.errors &&
            businessPhone.errors.required &&
            businessPhone.touched
          "
        >
          Phone number is required.
        </span>
      </div>

      <div
      class="form-group"
      [class.is-invalid]="businessEmail.touched && !businessEmail.valid"
    >
      <label class="form-label" for="businessEmail"
        >Email</label
      >
      <button type="button" class="btn btn-link pull-right" ngbPopover="Usually found on the businesses website or the 'Details' section of the Weedmaps listing.">Tip</button>
      <input
        #businessEmail="ngModel"
        [(ngModel)]="dispensary.businessEmail"
        type="text"
        id="businessEmail"
        name="businessEmail"
        class="form-control"
        email
      />
      <span
        class="help-block"
        *ngIf="
          businessEmail.errors &&
          businessEmail.errors.required &&
          businessEmail.touched
        "
      >
        Email is required.
      </span>
      <span
        class="help-block"
        *ngIf="
          businessEmail.errors &&
          businessEmail.errors.email &&
          businessEmail.touched
        "
      >
        Email is invalid.
      </span>
      </div>

      <div class="form-group">
        <label for="businessContactName">Contact name</label>
        <input
          [(ngModel)]="dispensary.businessContactName"
          type="text"
          id="businessContactName"
          name="businessContactName"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="businessWebsite"
          >Website</label
        >
        <button type="button" class="btn btn-link pull-right" ngbPopover="The homepage URL of the businesses website. For businesses with 2 or more locations, it is ok to use the same main homepage website URL for multiple locations.">Tip</button>
        <input
          #businessWebsite="ngModel"
          [(ngModel)]="dispensary.businessWebsite"
          type="text"
          id="businessWebsite"
          name="businessWebsite"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="webMenu">Web Menu</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is the URL of the business menu that customers can view, and potentially place orders. Businesses with multiple locations may have different menu's for each location. IMPT: Some menu's may not have ordering and just display products and prices. Also, in cases where the businesses website does not have a menu, but Weedmaps does have a menu for this business, ok to use the Weedmaps menu URL.">Tip</button>
        <input
          [(ngModel)]="dispensary.webMenu"
          type="text"
          id="webMenu"
          name="webMenu"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="businessType">Business Type</label>
        <ng-multiselect-dropdown
          name="businessType"
          [(ngModel)]="businessType"
          [placeholder]="'Select Business Type'"
          [data]="businessTypeSelectItems"
          [settings]="businessTypeSettings"
          (onSelect)="onBusinessTypeSelect($event)"
          (onDeSelect)="onBusinessTypeDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="licenseType">License Type</label>
        <ng-multiselect-dropdown
          name="licenseType"
          [(ngModel)]="licenseType"
          [placeholder]="'Select License Type'"
          [data]="licenseTypeSelectItems"
          [settings]="licenseTypeSettings"
          (onSelect)="onLicenseTypeSelect($event)"
          (onDeSelect)="onLicenseTypeDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="licenseExpiryDate">License Expiry Date</label>
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="licenseExpiryDate"
            ngbDatepicker
            #d="ngbDatepicker"
            (ngModelChange)="dateChanged($event)"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar fa fa-calendar"
              (click)="d.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="licenseNumber">License Number</label>
        <input
          [(ngModel)]="dispensary.licenseNumber"
          type="text"
          id="licenseNumber"
          name="licenseNumber"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="licDesignations">License Designations</label>
        <ng-multiselect-dropdown
          name="licDesignations"
          [(ngModel)]="licDesignations"
          [placeholder]="'Select License Designations'"
          [data]="licDesignationTypeSelectItems"
          [settings]="licDesignationsSettings"
          (onSelect)="onLicDesignationSelect($event)"
          (onDeSelect)="onLicDesignationDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>


      <div class="form-group">
        <label for="licenseSource">License Source</label>
        <input
          [(ngModel)]="dispensary.licenseSource"
          type="text"
          id="licenseSource"
          name="licenseSource"
          class="form-control"
        />
      </div>

      <div *ngIf="dispensary.dba; else noProfileLogoImageUpload" style="border: 3px #fff solid; padding: 1rem;">
        <!-- Profile photo -->
        <div style="width: 45%; display:inline-block; margin-right: 5%">
          <button type="button" class="btn btn-link d-block" style="padding-left: 180px;" ngbPopover="Image of the outside or inside of the business. One way to find this is to Google the DBA name and the word outside or inside (Example: Search Google for Uncle Ike's east union st outside). Purpose of this image is to help consumer find the business when they visit because they have seen a photo of it.">Tip</button>
          <button
            type="button"
            class="btn btn-success"
            (click)="addPhoto('profile')"
          >
            <span class="fa fa-plus"> Upload New Profile Photo</span>
          </button>
          <br>
          <br>

          <div class="form-group">
            <label for="profileImageId">Profile Image ID</label><br>
            <img *ngIf="dispensary.profileImageId" [src]="cdnRoot + '/images/business/sm-' + dispensary.profileImageId" width="50" height="auto"><br>
            <br>
            <input
              [(ngModel)]="dispensary.profileImageId"
              disabled
              type="text"
              id="profileImageId"
              name="profileImageId"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="profileImageExt">Profile Image Extension</label>
            <input
              [(ngModel)]="dispensary.profileImageExt"
              type="text"
              disabled
              id="profileImageExt"
              name="profileImageExt"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="profileImageAlt">Profile Image Alternate Text</label>
            <input
              [(ngModel)]="dispensary.profileImageAlt"
              [disabled]="!dispensary.profileImageId"
              type="text"
              id="profileImageAlt"
              name="profileImageAlt"
              class="form-control"
            />
          </div>
        </div>

        <!-- Logo -->
        <div style="width: 45%; display:inline-block;">
          <button type="button" class="btn btn-link d-block" style="padding-left: 130px;" ngbPopover="Logo image for the business. Best way to find this is to Google the DBA name and the word logo (Example: Search google for Uncle Ike's Logo). Try and get a clean nice logo. For businesses with 2 or more locations, please use the same nice logo.">Tip</button>
          <button
            type="button"
            class="btn btn-success"
            (click)="addPhoto('logo')"
          >
            <span class="fa fa-plus"> Upload New Logo</span>
          </button>
          <br>
          <br>

          <div class="form-group">
            <label for="logoImageId">Logo Image ID</label>
            <br>
            <img *ngIf="dispensary.logoImageId" [src]="cdnRoot + '/images/business/sm-' + dispensary.logoImageId" width="50" height="auto">
            <br><br>
            <input
              [(ngModel)]="dispensary.logoImageId"
              disabled
              type="text"
              id="logoImageId"
              name="logoImageId"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="logoImageExt">Logo Image Extension</label>
            <input
              [(ngModel)]="dispensary.logoImageExt"
              disabled
              type="text"
              id="logoImageExt"
              name="logoImageExt"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="logoImageAltText">Logo Image Alternate Text</label>
            <input
              [(ngModel)]="dispensary.logoImageAltText"
              [disabled]="!dispensary.logoImageId"
              type="text"
              id="logoImageAltText"
              name="logoImageAltText"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <ng-template #noProfileLogoImageUpload>
        <div class="m-5">
          <h2>Set the DBA to upload Photos</h2>
        </div>
      </ng-template>

      <div class="form-group">
        <label for="priceRangeLow">Price Range Low End</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is the lowest price of products on the menu. Easiest way to get this information is by doing a Google search and looking at the search results. There may be Price Range data in the search results for the businesses Weedmaps link.">Tip</button>
        <input
          [(ngModel)]="dispensary.priceRangeLow"
          type="text"
          id="priceRangeLow"
          name="priceRangeLow"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="priceRangeHigh">Price Range High End</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is the highest price of products on the menu. Easiest way to get this information is by doing a Google search and looking at the search results. There may be Price Range data in the search results for the businesses Weedmaps link.">Tip</button>
        <input
          [(ngModel)]="dispensary.priceRangeHigh"
          type="text"
          id="priceRangeHigh"
          name="priceRangeHigh"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="shortDesc">Short Description</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is a short description of the business highlighting unique features and is more promotional. This content can be gathered from the businesses website, Weedmaps listing Details section, Yelp! About section, or Google business about section. DO NOT just copy and paste what you find. Please craft the content in 3rd person and in complete sentences. Please do not use or type the Address in the Short Description. If available, try to start the text with a first-time customer discount promotion that the business has like, 10% off you first order. IMPT: It should not be longer than 350 characters with spaces.">Tip</button>
        <textarea
          [(ngModel)]="dispensary.shortDesc"
          id="shortDesc"
          name="shortDesc"
          class="form-control"
          rows="3"
          maxlength="350"
          (ngModelChange)="shortDescChange(dispensary.shortDesc)"
        >
        </textarea>
        <span class="counter">{{ shortDescRemaining }}</span>
      </div>

      <div class="form-group">
        <label for="aboutBio">About Us</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is a biography about the business and is more informational. This content shows on the business page and is more detailed including a hisgtory of the business. This content can be gathered from the businesses website, Weedmaps listing Details section, Yelp! About section, or Google business about section. DO NOT just copy and paste what you find. Please craft the content in 3rd person and in complete sentences. Please do not use or type the Address in the Short Description. IMPT: It should not be longer than 600 characters with spaces.">Tip</button>
        <textarea
          [(ngModel)]="dispensary.aboutBio"
          id="aboutBio"
          name="aboutBio"
          class="form-control"
          rows="3"
          maxlength="600"
          (ngModelChange)="aboutBioChange(dispensary.aboutBio)"
        >
        </textarea>
        <span class="counter">{{ aboutBioRemaining }}</span>
      </div>

      <div class="form-group">
        <label for="announcement">Announcements</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is any news or updates that the business may have (Example: Now open 7 days a week!).  DO NOT just copy and paste what you find. Please craft the content in 3rd person and in complete sentences. IMPT: It should not be longer than 350 characters with spaces.">Tip</button>
        <textarea
          [(ngModel)]="dispensary.announcement"
          id="announcement"
          name="announcement"
          class="form-control"
          rows="3"
          maxlength="350"
          (ngModelChange)="announcementChange(dispensary.announcement)"
        >
        </textarea>
        <span class="counter">{{ announcementRemaining }}</span>
      </div>

      <div class="form-group">
        <label for="discounts">Discounts</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="This is any discount information that the business may have. Discounts could be for each day or general. If no discounts are found, then please use the following: Please call the dispensary or check the dispensary website for updated specials and discounts.  DO NOT just copy and paste what you find. Please craft the content in 3rd person and in complete sentences. IMPT: It should not be longer than 350 characters with spaces.">Tip</button>
        <input
          [(ngModel)]="dispensary.discounts"
          type="text"
          id="discounts"
          name="discounts"
          class="form-control"
          maxlength="350"
          (ngModelChange)="discountsChange(dispensary.discounts)"
        />
        <span class="counter">{{ discountsRemaining }}</span>
      </div>

      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.openClosed"
          id="openClosed"
          name="openClosed"
          data-md-icheck
        />
        Open
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.adaAccessible"
          id="adaAccessible"
          name="adaAccessible"
          data-md-icheck
        />
        ADA Accessible
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.veteranDiscount"
          id="veteranDiscount"
          name="veteranDiscount"
          data-md-icheck
        />
         Veteran Discount
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.atmOnsite"
          id="atmOnsite"
          name="atmOnsite"
          data-md-icheck
        />
        ATM Onsite
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.isPermanentlyClosed"
          id="isPermanentlyClosed"
          name="isPermanentlyClosed"
          data-md-icheck
        />
        Is Permanently Closed
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.hasStartedEditing"
          id="hasStartedEditing"
          name="hasStartedEditing"
          data-md-icheck
        />
        Has Started Editing
        <span class="checkmark"></span>
      </label>

      <table class="table table-striped">
        <thead>
          <tr>
            <td colspan="5">
              <button type="button" class="btn btn-link pull-right" ngbPopover="8:00am format please! These can usually be found on the businesses website or on their google business listing. IMPT: No need to sort or order the days.">Tip</button>
            </td>
          </tr>
          <tr>
            <th scope="col">Day of the Week</th>
            <th scope="col">Start Time</th>
            <th scope="col">End Time</th>
            <th scope="col">Order</th>
            <th class="button-container">
              <button type="button" class="btn btn-success" (click)="addTime()">
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let openTime of sortedOpenTimes()">
            <td>{{ showDayName(openTime.dayOfWeek) }}</td>
            <td>{{ openTime.startTime }}</td>
            <td>{{ openTime.endTime }}</td>
            <td>{{ openTime.order }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editTime(openTime)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteTime(openTime)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="form-group">
        <label for="payments">Payment Methods</label>
        <button type="button" class="btn btn-link pull-right" ngbPopover="Cash is default. Other payment options can usually be found on the businesses website or the businesses Weedmaps listing.">Tip</button>
        <ng-multiselect-dropdown
          name="payments"
          [(ngModel)]="payments"
          [placeholder]="'Select Payment Methods'"
          [data]="paymentMethodItems"
          [settings]="paymentMethodSettings"
          (onSelect)="onPaymentMethodSelect($event)"
          (onDeSelect)="onPaymentMethodDeSelect($event)"
          (onSelectAll)="onPaymentMethodSelectAll($event)"
          (onDeSelectAll)="onPaymentMethodDeSelectAll($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <table class="table table-striped">
        <thead>
          <tr>
            <td colspan="3">
              <button type="button" class="btn btn-link pull-right" ngbPopover="These can usually be found on the businesses website or the businesses Weedmaps listing, or by searching Google for the DBA name and Facebook, or Twitter, or Instagram. Please click and visit each social link to make sure they are still active. If the socail site is not active, do not add the site and username.">Tip</button>
            </td>
          </tr>
          <tr>
            <th scope="col">Social Media Site</th>
            <th scope="col">User Name</th>
            <th class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="addSocialMediaSite()"
              >
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of dispensary.socialMediaSites">
            <td>{{ site.name }}</td>
            <td>{{ site.socialMedia.userName }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editSocialMediaSite(site)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteSocialMediaSite(site)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped">
        <thead>
          <tr>
            <td colspan="5">
              <button type="button" class="btn btn-link pull-right" ngbPopover="This is the Leafly, Yelp!, Weedmaps, and Google Business listing, rating and rating count. You can usually find these by searching the business DBA in Google. At times, there may be a listing be no reviews yet, that's ok, just put 0 in fields and add the review site URL.">Tip</button>
            </td>
          </tr>
          <tr>
            <th scope="col">Review Site</th>
            <th scope="col">Slug</th>
            <th scope="col">Review Count</th>
            <th scope="col">Rating</th>
            <th class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="addReviewSite()"
              >
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of dispensary.reviewSites">
            <td>{{ site.name }}</td>
            <td>{{ site.review.slug }}</td>
            <td>{{ site.review.reviewCount }}</td>
            <td>{{ site.review.rating }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editReviewSite(site)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteReviewSite(site)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="form-group" *ngIf="hasDeliveryLicense()">
        <label for="deliveryCities">Delivery Cities</label>
        <ng-multiselect-dropdown
          name="deliveryCities"
          [(ngModel)]="deliveryCities"
          [placeholder]="'Select Cities'"
          [data]="citySelectItems"
          [settings]="deliveryCitiesSettings"
          (onSelect)="onDeliveryCitySelect($event)"
          (onDeSelect)="onDeliveryCityDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div *ngIf="dispensary.dba; else noGalleryImageUpload" class="m-3 p-3" style="background: #fff;">
        <h3>Image Gallery (3 images max)</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Photo Id</th>
              <th scope="col">Photo Extension</th>
              <th scope="col">Alt Text</th>
              <th class="button-container">
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="addPhoto('gallery')"
                >
                  <span class="fa fa-plus"></span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let photo of dispensary.photos">
              <td>
                {{ photo.photoId }}<br>
                <img [src]="cdnRoot + '/images/business/sm-' + photo.photoId" width="50" height="auto">
              </td>
              <td>{{ photo.photoExt }}</td>
              <td>{{ photo.altText }}</td>
              <td class="button-container">
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="editPhoto(photo)"
                >
                  <span class="fa fa-edit"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deletePhoto(photo)"
                >
                  <span class="fa fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-template #noGalleryImageUpload>
        <div class="m-5">
          <h2>Set the DBA to upload Gallery Photos</h2>
        </div>
      </ng-template>

      <button
        type="submit"
        class="btn btn-primary btn-block"
        id="submit"
        [disabled]="!editDispensaryForm.form.valid"
      >
        Submit
      </button>
    </form>
    <div id="map-wrapper">
      <agm-map
        [latitude]="dispensary.latitude || 0"
        [longitude]="dispensary.longitude || 0"
        [zoom]="dispensary.zoom || 0"
        [disableDefaultUI]="true"
        [zoomControl]="true"
        [style.height.px]="showForm ? '0' : '300'"
        title=""
      >
        <agm-marker
          [latitude]="dispensary.latitude || 0"
          [longitude]="dispensary.longitude || 0"
        >
          <agm-snazzy-info-window
            [maxWidth]="200"
            [closeWhenOthersOpen]="true"
            [isOpen]="!showForm"
          >
            <ng-template>
              {{ dispensary.dba }}
            </ng-template>
          </agm-snazzy-info-window>
        </agm-marker>
      </agm-map>
      <div style="text-align: center;">
        <ng-container *ngIf="webAppInvalidateUrlSource">
          [
          <a [href]="webAppInvalidateUrlSource" target="_blank">Dispensary link</a>
          ]
        </ng-container>
        [
        <a href="/admin/dispensaries/copyedit/{{ dispensary.id }}"
          >Back to {{ dispensary.businessName }}</a
        >
        ] [ <a href="/admin/">Back to List</a> ]
      </div>
    </div>
  </div>
</div>
