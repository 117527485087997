/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./admin-dispensaries-edit-social-media-site-delete-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var styles_AdminDispensariesEditSocialMediaSiteDeleteModalComponent = [];
var RenderType_AdminDispensariesEditSocialMediaSiteDeleteModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminDispensariesEditSocialMediaSiteDeleteModalComponent, data: {} });
export { RenderType_AdminDispensariesEditSocialMediaSiteDeleteModalComponent as RenderType_AdminDispensariesEditSocialMediaSiteDeleteModalComponent };
export function View_AdminDispensariesEditSocialMediaSiteDeleteModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete social media site?"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteSocialMediaSiteModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Are you sure you want to delete this social media site?"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteSocialMediaSiteModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["No"])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_co.socialMediaSite) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Yes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.socialMediaSite.name; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.socialMediaSite.socialMedia.userName; _ck(_v, 12, 0, currVal_1); }); }
export function View_AdminDispensariesEditSocialMediaSiteDeleteModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-dispensaries-edit-social-media-site-delete-modal", [], null, null, null, View_AdminDispensariesEditSocialMediaSiteDeleteModalComponent_0, RenderType_AdminDispensariesEditSocialMediaSiteDeleteModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.AdminDispensariesEditSocialMediaSiteDeleteModalComponent, [i2.NgbActiveModal], null, null)], null, null); }
var AdminDispensariesEditSocialMediaSiteDeleteModalComponentNgFactory = i0.ɵccf("app-admin-dispensaries-edit-social-media-site-delete-modal", i1.AdminDispensariesEditSocialMediaSiteDeleteModalComponent, View_AdminDispensariesEditSocialMediaSiteDeleteModalComponent_Host_0, { socialMediaSite: "socialMediaSite" }, { deleteSocialMediaSiteEmitter: "deleteSocialMediaSiteEmitter" }, []);
export { AdminDispensariesEditSocialMediaSiteDeleteModalComponentNgFactory as AdminDispensariesEditSocialMediaSiteDeleteModalComponentNgFactory };
