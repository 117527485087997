<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete social media site?</h4>
    <button type="button" class="close" aria-label="Close" (click)="deleteSocialMediaSiteModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this social media site?</p>
  
    <p>
      {{ socialMediaSite.name }}<br />
      {{ socialMediaSite.socialMedia.userName }}
    </p>
  
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="deleteSocialMediaSiteModal.dismiss()">No</button>
      <button type="button" class="btn btn-outline-dark" (click)="delete(socialMediaSite)">Yes</button>
  </div>