import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Business } from '../../../model/business';
import { CitySelectItem } from '../../../model/city-select-item';
import { LocationService } from '../../../services/location.service';

import { AdminDispensariesEditPhotoAddModalComponent } from '../admin-dispensaries-edit-photo-add-modal/admin-dispensaries-edit-photo-add-modal.component';
import { AdminDispensariesEditPhotoEditModalComponent } from '../admin-dispensaries-edit-photo-edit-modal/admin-dispensaries-edit-photo-edit-modal.component';
import { AdminDispensariesEditPhotoDeleteModalComponent } from '../admin-dispensaries-edit-photo-delete-modal/admin-dispensaries-edit-photo-delete-modal.component';
import { BusinessTypeSelectItem } from '../../../model/business-type-select-item';
import { LicenseTypeSelectItem } from '../../../model/license-type-select-item';
import { PaymentMethodSelectItem } from '../../../model/payment-method-select-item';
import { AdminDispensariesEditSocialMediaSiteAddModalComponent } from '../admin-dispensaries-edit-social-media-site-add-modal/admin-dispensaries-edit-social-media-site-add-modal.component';
import { SocialMediaSiteSelectItem } from '../../../model/social-media-site-select-item';
import { AdminDispensariesEditSocialMediaSiteEditModalComponent } from '../admin-dispensaries-edit-social-media-site-edit-modal/admin-dispensaries-edit-social-media-site-edit-modal.component';
import { AdminDispensariesEditSocialMediaSiteDeleteModalComponent } from '../admin-dispensaries-edit-social-media-site-delete-modal/admin-dispensaries-edit-social-media-site-delete-modal.component';
import { AdminDispensariesEditReviewSiteAddModalComponent } from '../admin-dispensaries-edit-review-site-add-modal/admin-dispensaries-edit-review-site-add-modal.component';
import { ReviewSiteSelectItem } from '../../../model/review-site-select-item';
import { AdminDispensariesEditReviewSiteEditModalComponent } from '../admin-dispensaries-edit-review-site-edit-modal/admin-dispensaries-edit-review-site-edit-modal.component';
import { AdminDispensariesEditReviewSiteDeleteModalComponent } from '../admin-dispensaries-edit-review-site-delete-modal/admin-dispensaries-edit-review-site-delete-modal.component';
import { AdminDispensariesEditOpenTimeAddModalComponent } from '../admin-dispensaries-edit-open-time-add-modal/admin-dispensaries-edit-open-time-add-modal.component';
import { AdminDispensariesEditOpenTimeEditModalComponent } from '../admin-dispensaries-edit-open-time-edit-modal/admin-dispensaries-edit-open-time-edit-modal.component';
import { AdminDispensariesEditOpenTimeDeleteModalComponent } from '../admin-dispensaries-edit-open-time-delete-modal/admin-dispensaries-edit-open-time-delete-modal.component';
import { LicDesignationTypeSelectItem } from '../../../model/lic-designation-type-select-item';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { Photo } from '../../../model/photo';
import { environment } from '../../../../environments/environment';

declare var google: any;

@Component({
  selector: "app-admin-dispensaries-copyedit",
  templateUrl: "./admin-dispensaries-copyedit.component.html"
})
export class AdminDispensariesCopyeditComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: any;
  public cdnRoot = environment.cdnRoot;
  public dispensary: Business;
  
  shortDescRemaining: number;
  aboutBioRemaining: number;
  announcementRemaining: number;
  discountsRemaining: number;
  
  webAppInvalidateUrlSource: string;
  stateCodeList: string[];
  citySelectItems: CitySelectItem[];
  businessTypeSelectItems: BusinessTypeSelectItem[];
  licDesignationTypeSelectItems: LicDesignationTypeSelectItem[];
  licenseExpiryDate: NgbDate;
  paymentMethodItems: PaymentMethodSelectItem[];
  socialMediaSites: SocialMediaSiteSelectItem[];
  daysOfWeek: any[] = [
    { dayOfWeekId: 0, dayOfWeekName: "Sunday" },
    { dayOfWeekId: 1, dayOfWeekName: "Monday" },
    { dayOfWeekId: 2, dayOfWeekName: "Tuesday" },
    { dayOfWeekId: 3, dayOfWeekName: "Wednesday" },
    { dayOfWeekId: 4, dayOfWeekName: "Thursday" },
    { dayOfWeekId: 5, dayOfWeekName: "Friday" },
    { dayOfWeekId: 6, dayOfWeekName: "Saturday" }
  ];
  reviewSites: ReviewSiteSelectItem[];
  licenseTypeSelectItems: LicenseTypeSelectItem[];
  stateId: number;
  showForm: boolean = true;
  geocoder: any;
  @ViewChild(AgmMap, { static: true }) map: AgmMap;

  businessCity: any[] = [];
  businessCitySettings: any = {};

  businessType: any[] = [];
  businessTypeSettings: any = {};

  licenseType: any[] = [];
  licenseTypeSettings: any = {};

  licDesignations: any[] = [];
  licDesignationsSettings: any = {};

  deliveryCities: any[] = [];
  deliveryCitiesSettings: any = {};

  payments: any[] = [];
  paymentMethodSettings: any = {};

  constructor(
    private seo: SeoService,
    private actr: ActivatedRoute,
    private locationService: LocationService,
    public mapsApiLoader: MapsAPILoader,
    private zone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private modalService: NgbModal
  ) {
    super();

    this.shortDescRemaining = 350;
    this.aboutBioRemaining = 600;
    this.announcementRemaining = 350;
    this.discountsRemaining = 350;

    this.index = false;
    this.follow = false;

    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;

    let today = new Date();
    this.licenseExpiryDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());

    this.sub = this.actr.data.subscribe(data => {
      this.dispensary = data.dispensary as Business;

      this.locationService.getGalleryImages(this.dispensary.id)
        .subscribe(images => {
          console.log(images);
          this.dispensary.photos = images as Photo[];
        })

      this.stateId = this.dispensary.licenseType.stateId;

      this.dispensary.licenseExpiryDate = new Date(
        this.dispensary.licenseExpiryDate
      );

      this.licenseExpiryDate.day =
        this.dispensary.licenseExpiryDate.getDate();
      this.licenseExpiryDate.month =
        this.dispensary.licenseExpiryDate.getMonth() + 1;
      this.licenseExpiryDate.year =
        this.dispensary.licenseExpiryDate.getFullYear();

      this.businessType = [
        {
          businessTypeId: this.dispensary.businessType.id,
          businessTypeName: this.dispensary.businessType.name
        }
      ];

      this.licenseType = [
        {
          licenseTypeId: this.dispensary.licenseType.id,
          licenseTypeName: this.dispensary.licenseType.name
        }
      ];

      this.businessCity = [
        {
          cityId: this.dispensary.cityId,
          cityName: this.dispensary.businessCityName
        }
      ];

      this.dispensary.licDesignationTypes.forEach(designation => {
        this.licDesignations.push({
          licDesignationTypeId: designation.id,
          licDesignationTypeName: designation.name
        });
      });

      this.dispensary.deliveryCities.forEach(city => {
        this.deliveryCities.push({
          cityId: city.cityId,
          cityName: city.cityName
        });
      });

      this.dispensary.paymentTypes.forEach(payment => {
        this.payments.push({
          paymentMethodId: payment.id,
          paymentMethodName: payment.name
        });
      });

      this.stateCodeList = data.stateCodeList as string[];
    });
  }

  ngOnInit() {

    this.seo.addTags([
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
    ]);

    this.businessCitySettings = {
      singleSelection: true,
      idField: "cityId",
      textField: "cityName",
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.businessTypeSettings = {
      singleSelection: true,
      idField: 'businessTypeId',
      textField: 'businessTypeName',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.licenseTypeSettings = {
      singleSelection: true,
      idField: 'licenseTypeId',
      textField: 'licenseTypeName',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.licDesignationsSettings = {
      singleSelection: false,
      idField: "licDesignationTypeId",
      textField: "licDesignationTypeName",
      enableCheckAll: false,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.deliveryCitiesSettings = {
      singleSelection: false,
      idField: "cityId",
      textField: "cityName",
      enableCheckAll: false,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.paymentMethodSettings = {
      singleSelection: false,
      idField: "paymentMethodId",
      textField: "paymentMethodName",
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.locationService
      .getStateCityList(this.dispensary.businessStateCode)
      .subscribe(cities => {
        this.citySelectItems = cities;
      });

    this.locationService
      .getBusinessTypes()
      .subscribe(types => {
        this.businessTypeSelectItems = types;
      });

    this.locationService
      .getLicDesignationTypes()
      .subscribe(types => {
        this.licDesignationTypeSelectItems = types;
      })

    this.locationService
      .getLicenseTypesByState(this.dispensary.businessStateCode)
      .subscribe(types => {
        this.licenseTypeSelectItems = types;
      });

    this.locationService.getSocialMediaSites().subscribe(socialMediaSites => {
      this.socialMediaSites = socialMediaSites;
    });

    this.locationService.getReviewSites().subscribe(reviewSites => {
      this.reviewSites = reviewSites;
    });

    this.locationService.getPaymentMethods().subscribe(paymentMethods => {
      this.paymentMethodItems = paymentMethods;
    });
  }

  shortDescChange(value) {
    this.shortDescRemaining = 350 - value.length;
  }

  aboutBioChange(value) {
    this.aboutBioRemaining = 600 - value.length;
  }

  announcementChange(value) {
    this.announcementRemaining = 350 - value.length;
  }

  discountsChange(value) {
    this.discountsRemaining = 350 - value.length;
  }

  updateCities() {
    this.dispensary.cityId = -1;
    this.dispensary.businessCityName = "";
    this.dispensary.city = null;

    this.locationService
      .getStateCityList(this.dispensary.businessStateCode)
      .subscribe(cities => {
        this.citySelectItems = cities;
        this.businessCity = [];
        this.deliveryCities = [];
      });

    this.locationService
      .getLicenseTypesByState(this.dispensary.businessStateCode)
      .subscribe(types => {
        this.licenseTypeSelectItems = types;
      });

    this.locationService
      .getStateIdFromStateCode(this.dispensary.businessStateCode)
      .subscribe(id => {
        this.stateId = id;
      });

    this.onLocationChange();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  hasDeliveryLicense() {
    if (!this.dispensary.businessType) {
      return false;
    }
    return this.dispensary
      .businessType.name
      .toLowerCase()
      .includes("delivery");
  }

  onBusinessCitySelect(city) {
    this.businessCity = [city];
    this.dispensary.cityId = city.cityId;
    this.dispensary.businessCityName = city.cityName;

    this.onLocationChange();
  }

  onBusinessCityDeSelect(city) {
    this.businessCity = [];
    this.dispensary.cityId = -1;
    this.dispensary.businessCityName = "";

    this.onLocationChange();
  }

  onBusinessTypeSelect(type) {
    this.businessType = [type];
    this.dispensary.businessTypeId = type.businessTypeId;
    this.dispensary.businessType = {
      id: type.businessTypeId,
      name: type.businessTypeName
    };
  }

  onBusinessTypeDeSelect(type) {
    this.businessType = [];
    this.dispensary.businessTypeId = -1;
    this.dispensary.businessType = null;
  }

  onLicenseTypeSelect(type) {
    this.licenseType = [type];
    this.dispensary.licenseTypeId = type.licenseTypeId;
    this.dispensary.licenseType = {
      id: type.licenseTypeId,
      name: type.licenseTypeName,
      stateId: this.stateId
    };
  }

  onLicenseTypeDeSelect(type) {
    this.licenseType = [];
    this.dispensary.licenseTypeId = -1;
    this.dispensary.licenseType = null;
  }

  dateChanged(dateObj) {
    if (typeof dateObj === 'string') {
        return;
    }

    if (!dateObj) {
        return;
    }

    this.dispensary.licenseExpiryDate =
        new Date(dateObj.year, dateObj.month - 1, dateObj.day);
  }

  onLicDesignationSelect(designation) {
    this.licDesignations.push(designation);
    this.dispensary.licDesignationTypes.push({
      id: designation.licDesignationTypeId,
      name: designation.licDesignationTypeName,
      licDesignation: {
        businessId: this.dispensary.id,
        licDesignationTypeId: designation.licDesignationTypeId
      }
    });
  }

  onLicDesignationDeSelect(designation) {
    this.licDesignations = this.licDesignations.filter(
      x => x.licDesignationTypeId !== designation.licDesignationTypeId
    );
    this.dispensary.licDesignationTypes = this.dispensary.licDesignationTypes.filter(
      x => x.id !== designation.licDesignationTypeId
    );
  }

  onDeliveryCitySelect(city) {
    this.deliveryCities.push(city);
    this.dispensary.deliveryCities.push({
      cityId: city.cityId,
      cityName: city.cityName,

      counties: [],
    });
  }

  onDeliveryCityDeSelect(city) {
    this.deliveryCities = this.deliveryCities.filter(
      x => x.cityId !== city.cityId
    );
    this.dispensary.deliveryCities = this.dispensary.deliveryCities.filter(
      x => x.cityId !== city.cityId
    );
  }

  onPaymentMethodSelect(paymentMethod) {
    this.payments.push(paymentMethod);
    this.dispensary.paymentTypes.push({
      id: paymentMethod.paymentMethodId,
      name: paymentMethod.paymentMethodName
    });
  }

  onPaymentMethodDeSelect(paymentMethod) {
    this.payments = this.payments.filter(
      x => x.paymentMethodId !== paymentMethod.paymentMethodId
    );
    this.dispensary.paymentTypes = this.dispensary.paymentTypes.filter(
      x => x.id !== paymentMethod.paymentMethodId
    );
  }

  onPaymentMethodSelectAll(paymentMethods) {
    this.payments = paymentMethods;
    this.dispensary.paymentTypes = this.payments.map(x => {
      return {
        id: x.paymentMethodId,
        name: x.paymentMethodName
      };
    });
  }

  onPaymentMethodDeSelectAll() {
    this.payments = [];
    this.dispensary.paymentTypes = [];
  }

  addSocialMediaSite() {
    const modalRef = this.modalService.open(
      AdminDispensariesEditSocialMediaSiteAddModalComponent
    );
    modalRef.componentInstance.businessId = this.dispensary.id;
    modalRef.componentInstance.socialMediaSites = this.socialMediaSites;
    modalRef.componentInstance.addSocialMediaSiteEmitter.subscribe(
      socialMediaSite => {
        this.dispensary.socialMediaSites.push(socialMediaSite);
      }
    );
  }

  editSocialMediaSite(socialMedia) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditSocialMediaSiteEditModalComponent
    );
    modalRef.componentInstance.businessId = this.dispensary.id;
    modalRef.componentInstance.socialMedia = socialMedia;
    modalRef.componentInstance.socialMediaSites = this.socialMediaSites;
    modalRef.componentInstance.editSocialMediaSiteEmitter.subscribe(
      socialMediaSite => {}
    );
  }

  deleteSocialMediaSite(socialMediaSite) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditSocialMediaSiteDeleteModalComponent
    );
    modalRef.componentInstance.socialMediaSite = socialMediaSite;
    modalRef.componentInstance.deleteSocialMediaSiteEmitter.subscribe(
      socialMediaSite => {
        this.dispensary.socialMediaSites = this.dispensary.socialMediaSites.filter(
          s => s.id !== socialMediaSite.id
        );
      }
    );
  }

  addReviewSite() {
    const modalRef = this.modalService.open(
      AdminDispensariesEditReviewSiteAddModalComponent
    );
    modalRef.componentInstance.businessId = this.dispensary.id;
    modalRef.componentInstance.reviewSites = this.reviewSites;
    modalRef.componentInstance.addReviewSiteEmitter.subscribe(reviewSite => {
      this.dispensary.reviewSites.push(reviewSite);
    });
  }

  editReviewSite(review) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditReviewSiteEditModalComponent
    );
    modalRef.componentInstance.businessId = this.dispensary.id;
    modalRef.componentInstance.review = review;
    modalRef.componentInstance.reviewSites = this.reviewSites;
    modalRef.componentInstance.editReviewSiteEmitter.subscribe(
      reviewSite => {}
    );
  }

  deleteReviewSite(reviewSite) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditReviewSiteDeleteModalComponent
    );
    modalRef.componentInstance.reviewSite = reviewSite;
    modalRef.componentInstance.deleteReviewSiteEmitter.subscribe(reviewSite => {
      this.dispensary.reviewSites = this.dispensary.reviewSites.filter(
        s => s.id !== reviewSite.id
      );
    });
  }

  addPhoto(type?: string) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditPhotoAddModalComponent
    );

    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.photoType = type;
    modalRef.componentInstance.business = this.dispensary;
    modalRef.componentInstance.businessId = this.dispensary.id;

    const currentOrder = this.dispensary.photos ? this.dispensary.photos.length + 1 : 1;

    if (currentOrder > 3) {
      modalRef.componentInstance.order = 1;
    } else {
      modalRef.componentInstance.order = currentOrder;
    }


    modalRef.componentInstance.addPhotoEmitter.subscribe(photo => {
      console.log('UPLOADED PHOTO?');
      console.log(photo);

      switch (type) {
        case 'profile':
          this.dispensary.profileImageId = photo.photoId;
          this.dispensary.profileImageExt = photo.photoExt;
          this.dispensary.profileImageAlt = photo.altText;
          return;

        case 'gallery':
          // If already 3 items exist, remove last item & add new item as #1
          if (this.dispensary.photos.length >= 3) {
            this.dispensary.photos.pop();
            this.dispensary.photos.unshift(photo);
          } else {
            // Add to end if one of first 3 items
            this.dispensary.photos.push(photo);
          }
          return;

        case 'logo':
          console.log(photo);
          this.dispensary.logoImageId = photo.photoId;
          this.dispensary.logoImageExt = photo.photoExt;
          this.dispensary.logoImageAltText = photo.altText;
        return;

        default:
          console.log('Did you forget to pass a photo type? [ profile | gallery | logo.')
          return;
      }

    });
  }

  editPhoto(photo) {
    // Why are we even editing them, they should just have to delete and re-upload an image
    const modalRef = this.modalService.open(
      AdminDispensariesEditPhotoEditModalComponent
    );
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.photo = photo;
    modalRef.componentInstance.editPhotoEmitter.subscribe(photo => {
      //
    });
  }

  deletePhoto(photo) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditPhotoDeleteModalComponent
    );
    modalRef.componentInstance.photo = photo;
    modalRef.componentInstance.deletePhotoEmitter.subscribe(photo => {
      this.dispensary.photos = this.dispensary.photos.filter(
        f => f.photoId !== photo.photoId
      );
    });
  }

  showDayName(dayId) {
    return this.daysOfWeek.find(x => x.dayOfWeekId === dayId).dayOfWeekName;
  }

  sortedOpenTimes() {
    return this.dispensary.openTimes.sort((a, b) => {
      if (a.dayOfWeek === b.dayOfWeek) {
        return a.order - b.order;
      } else {
        return a.dayOfWeek - b.dayOfWeek;
      }
    });
  }

  addTime() {
    const modalRef = this.modalService.open(
      AdminDispensariesEditOpenTimeAddModalComponent
    );
    modalRef.componentInstance.businessId = this.dispensary.id;
    modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
    modalRef.componentInstance.addOpenTimeEmitter.subscribe(openTime => {
      this.dispensary.openTimes.push(openTime);
    });
  }

  editTime(openTime) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditOpenTimeEditModalComponent
    );
    modalRef.componentInstance.openTime = openTime;
    modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
    modalRef.componentInstance.editOpenTimeEmitter.subscribe(openTime => {
      // no action?
    });
  }

  deleteTime(openTime) {
    const modalRef = this.modalService.open(
      AdminDispensariesEditOpenTimeDeleteModalComponent
    );
    modalRef.componentInstance.openTime = openTime;
    modalRef.componentInstance.daysOfWeek = this.daysOfWeek;
    modalRef.componentInstance.deleteOpenTimeEmitter.subscribe(openTime => {
      this.dispensary.openTimes = this.dispensary.openTimes.filter(
        f => f.dayOfWeek !== openTime.dayOfWeek || f.order !== openTime.order
      );
    });
  }

  onLocationChange() {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder();

    let address = [
      this.dispensary.businessAddress,
      this.dispensary.businessCityName,
      this.dispensary.businessStateCode
    ]
      .filter(x => !!x)
      .join(" ");

    this.geocoder.geocode(
      {
        address: address
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0].geometry.location) {
            this.dispensary.latitude = results[0].geometry.location.lat();
            this.dispensary.longitude = results[0].geometry.location.lng();
            this.dispensary.zoom = 15;
          }
          this.map.triggerResize();
        } else {
          console.log("Sorry, this search produced no results.");
        }
      }
    );
  }

  onSubmit() {
    this.locationService.updateDispensary(this.dispensary, false).then(success => {
      this.showForm = false;
      const dispensaryAdditionalUrl = this.locationService.getInvalidateLink(`/dispensaries/${this.dispensary.businessStateCode.toLowerCase()}/${this.dispensary.city.counties[0].countySlug}/${this.dispensary.city.citySlug}/${this.dispensary.businessSlug}/`);
      this.webAppInvalidateUrlSource = `https://prod-mamasganja.herokuapp.com${dispensaryAdditionalUrl}`;
    });
  }
}
