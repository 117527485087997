var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
var AdminDispensariesIndexComponent = /** @class */ (function (_super) {
    __extends(AdminDispensariesIndexComponent, _super);
    function AdminDispensariesIndexComponent(seo) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.index = false;
        _this.follow = false;
        return _this;
    }
    AdminDispensariesIndexComponent.prototype.ngOnInit = function () {
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
    };
    return AdminDispensariesIndexComponent;
}(BaseComponent));
export { AdminDispensariesIndexComponent };
