import { Inject, Injectable, PLATFORM_ID, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

export const DOMAIN = 'https://www.mamasganja.com';

@Injectable()
export class SeoService {

    constructor(
        private renderFactory: RendererFactory2,
        @Inject(DOCUMENT) private document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    public addTags(tags: TagDefinition[]): void {
        tags.forEach(tag => this.addTag(tag));
    }

    public addTag(tag: TagDefinition): void {
        try {
            const renderer = this.renderFactory.createRenderer(
                this.document,
                {
                    id: '-1',
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {}
                }
            );
            const link = renderer.createElement(tag.tagName);
            const head = this.document.head;

            if (head === null) {
                throw new Error('<head> not found within DOCUMENT');
            }

            Object.keys(tag).forEach((prop: string) => {
                if (prop !== 'tagName') {
                    return renderer.setAttribute(link, prop, tag[prop]);
                }
            });
            let prop = '';
            switch (tag.tagName) {
                case 'link':
                    prop = 'rel';
                    break;
                case 'meta':
                    prop = 'name';
                    break;
                default:
                    throw `Unknown tag: ${tag.tagName}`;
            }
            let oldTag = head.querySelector(`${tag.tagName}[${prop}='${tag[prop]}']`);
            if (oldTag) {
                renderer.removeChild(head, oldTag);
            }
            renderer.appendChild(head, link);
        } catch (e) {
            // console.error('Error within seoService#addTag : ', e);
        }
    }

    public removeJsonLd(): void {
        try {
            const renderer = this.renderFactory.createRenderer(
                this.document,
                {
                    id: '-1',
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {}
                }
            );
            const body = this.document.body;

            if (body === null) {
                throw new Error('<body> not found within DOCUMENT');
            }
            let oldTags = body.querySelectorAll(`script[type='application/ld+json']`);
            oldTags.forEach(oldTag => {
                renderer.removeChild(body, oldTag);
            });
        } catch (e) {
            // console.error('Error within seoService#setJsonLd : ', e);
        }
    }

    public setJsonLd(data: any, id: string): void {
        if (isPlatformBrowser(this.platformId)) {
            return;
        }
        try {
            const renderer = this.renderFactory.createRenderer(
                this.document,
                {
                    id: '-1',
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {}
                }
            );

            const footer = this.document.body.querySelector('footer');

            if (footer === null) {
                throw new Error('<footer> not found within DOCUMENT');
            }
            if (!footer.querySelector(`#${id}`)) {
                let scriptBlock = renderer.createElement('script');
                renderer.setAttribute(scriptBlock, 'id', id);
                scriptBlock.type = 'application/ld+json';
                scriptBlock.text = `${JSON.stringify(data)}`;
                renderer.appendChild(footer, scriptBlock);
            }
        } catch (e) {
            // console.error('Error within seoService#setJsonLd : ', e);
        }
    }

}

export declare type TagDefinition = {
    tagName: string;
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};
