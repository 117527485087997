/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./authenticate.component";
import * as i2 from "../services/seo.service";
import * as i3 from "../services/auth.service";
var styles_AuthenticateComponent = [];
var RenderType_AuthenticateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthenticateComponent, data: {} });
export { RenderType_AuthenticateComponent as RenderType_AuthenticateComponent };
export function View_AuthenticateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Login"]))], null, null); }
export function View_AuthenticateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-authenticate", [], null, null, null, View_AuthenticateComponent_0, RenderType_AuthenticateComponent)), i0.ɵdid(1, 114688, null, 0, i1.AuthenticateComponent, [i2.SeoService, i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticateComponentNgFactory = i0.ɵccf("app-authenticate", i1.AuthenticateComponent, View_AuthenticateComponent_Host_0, {}, {}, []);
export { AuthenticateComponentNgFactory as AuthenticateComponentNgFactory };
