import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialMediaSite } from '../../../model/social-media-site';

@Component({
    selector: 'app-admin-dispensaries-edit-social-media-site-add-modal',
    templateUrl: './admin-dispensaries-edit-social-media-site-add-modal.component.html'
})
export class AdminDispensariesEditSocialMediaSiteAddModalComponent implements OnInit {
    @Input() public businessId: number;
    @Input() public socialMediaSites: any[];
    @Output() addSocialMediaSiteEmitter: EventEmitter<any> = new EventEmitter();

    socialMedia: SocialMediaSite;
    socialMediaSite: any[] = [];
    socialMediaSiteSettings: any = {};

    constructor(public addSocialMediaSiteModal: NgbActiveModal) {
        this.socialMedia  = {
          id: -1,
          name: '',
          icon: '',
          baseUrl: '',
          socialMedia: {
            businessId: -1,
            socialMediaSiteId: -1,
            userName: ''
          }
        };

    }

    ngOnInit() {
        this.socialMedia.socialMedia.businessId = this.businessId;
        this.socialMediaSiteSettings = {
            singleSelection: true,
            idField: 'socialMediaSiteId',
            textField: 'socialMediaSiteName',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
    }

    onSocialMediaSiteSelect(socialMediaSite) {
      this.socialMedia.id = socialMediaSite.socialMediaSiteId;
      this.socialMedia.name = socialMediaSite.socialMediaSiteName;
      this.socialMedia.socialMedia.socialMediaSiteId = socialMediaSite.socialMediaSiteId;
    }

    onSocialMediaSiteDeSelect(socialMediaSite) {
      this.socialMedia.id = -1;
      this.socialMedia.name = '';
      this.socialMedia.socialMedia.socialMediaSiteId = -1;
    }

    add(socialMediaSite) {
      this.addSocialMediaSiteEmitter.emit(socialMediaSite);
      this.addSocialMediaSiteModal.close();
    }
}
