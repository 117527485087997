<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Edit Open Time</h4>
  <button type="button" class="close" aria-label="Close" (click)="editOpenTimeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
      <div class="form-group">
          <label for="dayOfWeek">Day of the Week</label>
          <ng-multiselect-dropdown
              name="dayOfWeek"
              [(ngModel)]="dayOfWeek"
              [placeholder]="'Select Day of the Week'"
              [data]="daysOfWeek"
              [settings]="daysOfWeekSettings"
              (onSelect)="onDayOfWeekSelect($event)"
              (onDeSelect)="onDayOfWeekDeSelect($event)"
          >
          </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
          <label for="startTime">Start Time</label>
          <input
              [(ngModel)]="openTime.startTime"
              type="text"
              id="startTime"
              name="startTime"
              class="form-control"
          />
      </div>
      <div class="form-group">
          <label for="endTime">End Time</label>
          <input
              [(ngModel)]="openTime.endTime"
              type="text"
              id="endTime"
              name="endTime"
              class="form-control"
          />
      </div>
      <div class="form-group">
          <label for="order">Order</label>
          <input
              [(ngModel)]="openTime.order"
              type="text"
              id="order"
              name="order"
              class="form-control"
          />
      </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="update(openTime)">Save</button>
</div>