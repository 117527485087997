import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '../services/auth.service';
import { BaseComponent } from './base.component';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-callback',
  template: `
    <p>
      Loading...
    </p>
  `,
  styles: []
})
export class CallbackComponent extends BaseComponent implements OnInit {

  constructor(
    private seo: SeoService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }

  ngOnInit() {
    this.seo.addTags([
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
    ]);

    if (isPlatformBrowser(this.platformId)) {
      this.authService.handleLoginCallback();
    }
  }

}
