var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../../services/location.service';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { AdminAdsPhotoAddModalComponent } from '../admin-ads-photo-add-modal/admin-ads-photo-add-modal.component';
import { environment } from '../../../../environments/environment';
export var TopperMode;
(function (TopperMode) {
    TopperMode["State"] = "State";
    TopperMode["City"] = "City";
    TopperMode["County"] = "County";
})(TopperMode || (TopperMode = {}));
var AdminLocationEditComponent = /** @class */ (function (_super) {
    __extends(AdminLocationEditComponent, _super);
    function AdminLocationEditComponent(seo, locationService, modalService) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.locationService = locationService;
        _this.modalService = modalService;
        _this.cdnRoot = environment.cdnRoot;
        _this.businessCitySettings = {};
        _this.businessCountySettings = {};
        _this.businessSettings = {};
        // Toppers & Ads
        _this.selectedDispensaryOne = [];
        _this.selectedDispensaryTwo = [];
        _this.selectedDispensaryThree = [];
        _this.TopperMode = TopperMode;
        _this.forceInvalidation = false;
        _this.forceInvalidationState = false;
        _this.locationService.getAllDispensaries().subscribe(function (dispensaries) {
            dispensaries.map(function (business) {
                business.dba = business.dba + " - " + business.businessCityName + ", " + business.businessStateCode;
                return business;
            });
            console.log({ dispensaries: dispensaries });
            _this.dispensaries = dispensaries;
        });
        _this.refreshStates();
        _this.index = false;
        _this.follow = false;
        return _this;
    }
    Object.defineProperty(AdminLocationEditComponent.prototype, "canEditToppers", {
        get: function () {
            return this.selectedState && this.selectedState.stateName
                && (this.topperEditMode === TopperMode.City && this.selectedCity ? true : false
                    ||
                        this.topperEditMode === TopperMode.County && this.selectedCounty ? true : false
                    ||
                        this.topperEditMode === TopperMode.State && this.selectedState.stateName ? true : false);
        },
        enumerable: true,
        configurable: true
    });
    AdminLocationEditComponent.prototype.refreshStates = function (selectedState) {
        var _this = this;
        this.locationService.getStateList().subscribe(function (states) {
            _this.statesList = states;
            if (selectedState) {
                _this.selectedState = states.find(function (state) { return state.id === selectedState.id; });
                if (_this.selectedState.adPhotoId) {
                    _this.displayAdImage(_this.selectedState.adPhotoId);
                }
            }
        });
    };
    AdminLocationEditComponent.prototype.refreshCities = function (selectedState) {
        var _this = this;
        this.locationService.getAllCities(selectedState.stateCode).subscribe(function (cityList) {
            // console.log({cityList});
            _this.citySelectItems = cityList;
        });
    };
    AdminLocationEditComponent.prototype.refreshCounties = function (selectedState) {
        var _this = this;
        this.locationService.getAllCounties(selectedState.stateCode).subscribe(function (counties) {
            // console.log({counties})
            _this.counties = counties;
        });
    };
    AdminLocationEditComponent.prototype.getStateInfo = function (selectedState) {
        // console.log({selectedState});
        this.changeEditMode(TopperMode.State, selectedState);
        this.topperLocationName = selectedState.stateName;
        this.refreshCities(selectedState);
        this.refreshCounties(selectedState);
    };
    AdminLocationEditComponent.prototype.changeEditMode = function (type, collection) {
        console.log({ topperEditMode: type });
        if (type === 'State') {
            this.topperLocationName = this.selectedState.stateName;
        }
        this.selectedCity = null;
        this.selectedCounty = null;
        this.selectedDispensaryOne = null;
        this.selectedDispensaryTwo = null;
        this.selectedDispensaryThree = null;
        this.selectedAd = null;
        this.adImage = null;
        this.adImageSrc = null;
        if (collection) {
            this.fillSelections(collection);
        }
        this.topperEditMode = TopperMode[type];
    };
    AdminLocationEditComponent.prototype.fillSelections = function (collection) {
        console.log('\n>> fillSelections >>');
        console.log(collection);
        this.currentCollection = collection;
        this.selectedDispensaryOne = collection.topperOneBusinessId ? [
            this.dispensaries.filter(function (d) { return d.id === collection.topperOneBusinessId; })[0]
        ] : [];
        this.selectedDispensaryTwo = collection.topperTwoBusinessId ? [
            this.dispensaries.filter(function (d) { return d.id === collection.topperTwoBusinessId; })[0]
        ] : [];
        this.selectedDispensaryThree = collection.topperThreeBusinessId ? [
            this.dispensaries.filter(function (d) { return d.id === collection.topperThreeBusinessId; })[0]
        ] : [];
        this.selectedAd = collection.adPhotoId;
        // Show uploaded photo
        if (collection.adPhotoId) {
            this.displayAdImage(collection.adPhotoId);
        }
        else {
            this.adImage = null;
            this.adImageSrc = '';
        }
    };
    AdminLocationEditComponent.prototype.ngOnInit = function () {
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
        this.businessCitySettings = {
            singleSelection: true,
            idField: "cityId",
            textField: "cityName",
            allowSearchFilter: true,
            closeDropDownOnSelection: true,
            defaultOpen: true
        };
        this.businessCountySettings = {
            singleSelection: true,
            idField: "id",
            textField: "countyName",
            allowSearchFilter: true,
            closeDropDownOnSelection: true,
            defaultOpen: true
        };
        this.businessSettings = {
            singleSelection: true,
            idField: "id",
            textField: "dba",
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
    };
    AdminLocationEditComponent.prototype.onDispensarySelect = function (dispensary, topperLevel) {
        console.log({ dispensary: dispensary, topperLevel: topperLevel });
    };
    AdminLocationEditComponent.prototype.onDispensaryDeSelect = function (dispensary, topperLevel) {
        console.log({ dispensary: dispensary, topperLevel: topperLevel });
    };
    AdminLocationEditComponent.prototype.onBusinessCitySelect = function (city) {
        // console.log({city})
        this.topperLocationName = city.cityName;
        var fullCityData = this.citySelectItems.filter(function (c) { return c.cityId === city.cityId; })[0];
        this.fillSelections(fullCityData);
    };
    AdminLocationEditComponent.prototype.onBusinessCityDeSelect = function () {
        this.selectedCity = null;
    };
    AdminLocationEditComponent.prototype.onCountySelect = function (county) {
        // console.log({county});
        this.topperLocationName = county.countyName;
        var fullCountyData = this.counties.filter(function (c) { return c.id === county.id; })[0];
        this.fillSelections(fullCountyData);
    };
    AdminLocationEditComponent.prototype.onCountyDeSelect = function () {
        this.selectedCounty = null;
    };
    AdminLocationEditComponent.prototype.onSubmit = function () {
        var _this = this;
        var getValue = function (item) {
            return item && item.length ? item[0] && item[0].id : null;
        };
        var toppersAndAds = {
            topperOneBusinessId: getValue(this.selectedDispensaryOne),
            topperTwoBusinessId: getValue(this.selectedDispensaryTwo),
            topperThreeBusinessId: getValue(this.selectedDispensaryThree),
            adPhotoId: this.selectedAd,
        };
        var stateCode = this.selectedState.stateCode.toLowerCase();
        this.currentCollection = __assign({}, this.currentCollection, toppersAndAds);
        // console.log(`\n\n EDIT MODE : ${this.topperEditMode}\n\n`);
        // console.log({ currentCollection: this.currentCollection });
        switch (this.topperEditMode) {
            case this.TopperMode.City:
                var city = this.currentCollection;
                this.locationService.updateCity(city, this.forceInvalidation)
                    .subscribe(function (res) {
                    if (res.status === 200) {
                        alert('Saved!');
                        _this.refreshCities(_this.selectedState);
                    }
                });
                return;
            case this.TopperMode.County:
                var county = this.currentCollection;
                this.locationService.updateCounty(county, this.forceInvalidation)
                    .subscribe(function (res) {
                    if (res.status === 200) {
                        alert('Saved!');
                        _this.refreshCounties(_this.selectedState);
                    }
                });
                return;
            case this.TopperMode.State:
                var state = this.currentCollection;
                this.locationService.updateState(state, this.forceInvalidation)
                    .subscribe(function (res) {
                    if (res.status === 200) {
                        alert('Saved!');
                        _this.refreshStates(_this.selectedState);
                    }
                });
                return;
            default:
                return;
        }
    };
    AdminLocationEditComponent.prototype.updateAllStateLocations = function () {
        var _this = this;
        var confirmed = window.confirm("WARNING:\nThis will Save this Ad to ALL Counties & Cities for: " + this.topperLocationName + "\n(that do -NOT- already have an Ad selected)\n\n>> This process is irreversible <<\n------------------------------------\nAre you sure you want to continue?");
        if (confirmed) {
            this.locationService.updateAdToAllLocationsInState(this.currentCollection, this.forceInvalidationState)
                .subscribe(function (res) {
                if (res.status === 20) {
                    console.log(res);
                    _this.refreshStates(_this.selectedState);
                    _this.refreshCities(_this.selectedState);
                    _this.refreshCounties(_this.selectedState);
                    window.location.reload(true);
                }
            });
        }
    };
    AdminLocationEditComponent.prototype.imageModal = function (type, editMode) {
        var _this = this;
        if (editMode === void 0) { editMode = false; }
        var modalRef = this.modalService.open(AdminAdsPhotoAddModalComponent);
        modalRef.componentInstance.editMode = editMode;
        modalRef.componentInstance.photoType = 'ad';
        modalRef.componentInstance.businessId = '';
        modalRef.componentInstance.addPhotoEmitter.subscribe(function (photo) {
            switch (type) {
                case 'ad':
                    _this.displayAdImage(null, photo);
                    _this.selectedAd = photo.id;
                    return;
                default:
                    console.log('Did you forget to pass a photo type?');
                    return;
            }
        });
    };
    AdminLocationEditComponent.prototype.displayAdImage = function (id, photo) {
        var _this = this;
        // We have the Name of the file from processed upload
        if (photo) {
            console.log('we just uploaded a photo!');
            console.log(photo.photoId);
            var url = this.cdnRoot + "/images/ad/" + photo.photoId;
            this.adImage = photo;
            this.adImageSrc = url;
            return;
        }
        // Get image by ID
        this.locationService.getImageById(id)
            .subscribe(function (image) {
            var url = _this.cdnRoot + "/images/ad/" + image.photoId;
            _this.adImage = image;
            _this.adImageSrc = url;
        });
    };
    return AdminLocationEditComponent;
}(BaseComponent));
export { AdminLocationEditComponent };
