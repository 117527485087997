import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../../services/location.service';
import { Business } from '../../../model/business';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';

@Component({
  selector: "app-admin-dispensaries-search",
  templateUrl: "./admin-dispensaries-search.component.html"
})
export class AdminDispensariesSearchComponent extends BaseComponent implements OnInit {
  public searchType = 'name';
  public q: string;
  public matches: Business[];

  constructor(
    private seo: SeoService,
    private locationService: LocationService,
    private router: Router
  ) {
    super();
    this.index = false;
    this.follow = false;
}

  ngOnInit() {
    this.seo.addTags([
        { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
      ]);
  }

  searchDispensaries() {
    this.locationService.searchDispensaries(this.q, this.searchType, true).subscribe(matches => {
      this.matches = matches;
    });
  }

  edit(id) {
    this.router.navigate([`admin/dispensaries/edit/${id}`]);
  }

  delete(id) {
    this.router.navigate([`admin/dispensaries/delete/${id}`]);
  }
}
