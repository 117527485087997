<div class="col-lg-8 offset-lg-2">
  <div id="wrapper">
    <form #editDispensaryForm="ngForm" (submit)="onSubmit()" type="multipart/form-data" *ngIf="showForm">
      <div
        class="form-group"
        [class.is-invalid]="businessName.touched && !businessName.valid"
      >
        <label for="businessName"
          >Business name <span class="require-symbol">*</span></label
        >
        <input
          #businessName="ngModel"
          [(ngModel)]="dispensary.businessName"
          type="text"
          id="businessName"
          name="businessName"
          class="form-control"
          required
        />
        <span
          class="help-block"
          *ngIf="
            businessName.errors &&
            businessName.errors.required &&
            businessName.touched
          "
        >
          Business name is required.
        </span>
      </div>
      <div class="form-group">
        <label for="dba">DBA</label>
        <input
          [(ngModel)]="dispensary.dba"
          type="text"
          id="dba"
          name="dba"
          class="form-control"
        />
      </div>
      <div
        class="form-group"
        [class.is-invalid]="businessAddress.touched && !businessAddress.valid"
      >
        <label for="businessAddress"
          >Business address</label
        >
        <input
          #businessAddress="ngModel"
          [(ngModel)]="dispensary.businessAddress"
          type="text"
          id="businessAddress"
          name="businessAddress"
          class="form-control"
          (change)="onLocationChange()"
        />
      </div>
      <div class="form-group">
        <label for="businessStateCode">Business state code</label>
        <select
          [(ngModel)]="dispensary.businessStateCode"
          id="businessStateCode"
          name="businessStateCode"
          class="form-control"
          (change)="updateCities()"
        >
          <option *ngFor="let code of stateCodeList" [ngValue]="code">
            {{ code }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="businessCityName">City</label>
        <ng-multiselect-dropdown
          name="businessCity"
          [(ngModel)]="businessCity"
          [placeholder]="'Select City'"
          [data]="citySelectItems"
          [settings]="businessCitySettings"
          (onSelect)="onBusinessCitySelect($event)"
          (onDeSelect)="onBusinessCityDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="businessZip">ZIP Code</label>
        <input
          [(ngModel)]="dispensary.businessZip"
          type="text"
          id="businessZip"
          name="businessZip"
          class="form-control"
        />
      </div>
      <div
        class="form-group"
        [class.is-invalid]="businessPhone.touched && !businessPhone.valid"
      >
        <label for="businessPhone"
          >Phone number <span class="require-symbol">*</span></label
        >
        <input
          #businessPhone="ngModel"
          [(ngModel)]="dispensary.businessPhone"
          type="text"
          id="businessPhone"
          name="businessPhone"
          class="form-control"
          required
        />
        <span
          class="help-block"
          *ngIf="
            businessPhone.errors &&
            businessPhone.errors.required &&
            businessPhone.touched
          "
        >
          Phone number is required.
        </span>
        <div class="form-group">
          <label for="businessWebsite"
            >Website</label
          >
          <input
            #businessWebsite="ngModel"
            [(ngModel)]="dispensary.businessWebsite"
            type="text"
            id="businessWebsite"
            name="businessWebsite"
            class="form-control"
          />
        </div>
      </div>
      <div
        class="form-group"
        [class.is-invalid]="businessEmail.touched && !businessEmail.valid"
      >
        <label class="form-label" for="businessEmail"
          >Email</label
        >
        <input
          #businessEmail="ngModel"
          [(ngModel)]="dispensary.businessEmail"
          type="text"
          id="businessEmail"
          name="businessEmail"
          class="form-control"
          email
        />
        <span
          class="help-block"
          *ngIf="
            businessEmail.errors &&
            businessEmail.errors.email &&
            businessEmail.touched
          "
        >
          Email is invalid.
        </span>
      </div>
      <div class="form-group">
        <label for="businessContactName">Contact name</label>
        <input
          [(ngModel)]="dispensary.businessContactName"
          type="text"
          id="businessContactName"
          name="businessContactName"
          class="form-control"
        />
      </div>

      <div *ngIf="dispensary.dba; else noProfileLogoImageUpload" style="border: 3px #fff solid; padding: 1rem;">
        <!-- Profile photo -->
        <div style="width: 45%; display:inline-block; margin-right: 5%">
          <button
            type="button"
            class="btn btn-success"
            (click)="addPhoto('profile')"
          >
            <span class="fa fa-plus"> Upload New Profile Photo</span>
          </button>
          <br>
          <br>

          <div class="form-group">
            <label for="profileImageId">Profile Image ID</label><br>
            <img *ngIf="dispensary.profileImageId" [src]="cdnRoot + '/images/business/sm-' + dispensary.profileImageId" width="50" height="auto"><br>
            <br>
            <input
              [(ngModel)]="dispensary.profileImageId"
              disabled
              type="text"
              id="profileImageId"
              name="profileImageId"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="profileImageExt">Profile Image Extension</label>
            <input
              [(ngModel)]="dispensary.profileImageExt"
              type="text"
              disabled
              id="profileImageExt"
              name="profileImageExt"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="profileImageAlt">Profile Image Alternate Text</label>
            <input
              [(ngModel)]="dispensary.profileImageAlt"
              [disabled]="!dispensary.profileImageId"
              type="text"
              id="profileImageAlt"
              name="profileImageAlt"
              class="form-control"
            />
          </div>
        </div>

        <!-- Logo -->
        <div style="width: 45%; display:inline-block;">
          <button
            type="button"
            class="btn btn-success"
            (click)="addPhoto('logo')"
          >
            <span class="fa fa-plus"> Upload New Logo</span>
          </button>
          <br>
          <br>

          <div class="form-group">
            <label for="logoImageId">Logo Image ID</label>
            <br>
            <img *ngIf="dispensary.logoImageId" [src]="cdnRoot + '/images/business/sm-' + dispensary.logoImageId" width="50" height="auto">
            <br><br>
            <input
              [(ngModel)]="dispensary.logoImageId"
              disabled
              type="text"
              id="logoImageId"
              name="logoImageId"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="logoImageExt">Logo Image Extension</label>
            <input
              [(ngModel)]="dispensary.logoImageExt"
              disabled
              type="text"
              id="logoImageExt"
              name="logoImageExt"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="logoImageAltText">Logo Image Alternate Text</label>
            <input
              [(ngModel)]="dispensary.logoImageAltText"
              [disabled]="!dispensary.logoImageId"
              type="text"
              id="logoImageAltText"
              name="logoImageAltText"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <ng-template #noProfileLogoImageUpload>
        <div class="m-5">
          <h2>Set the DBA to upload Photos</h2>
        </div>
      </ng-template>

      <div class="form-group">
        <label for="priceRangeLow">Price Range Low End</label>
        <input
          [(ngModel)]="dispensary.priceRangeLow"
          type="text"
          id="priceRangeLow"
          name="priceRangeLow"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="priceRangeHigh">Price Range High End</label>
        <input
          [(ngModel)]="dispensary.priceRangeHigh"
          type="text"
          id="priceRangeHigh"
          name="priceRangeHigh"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="shortDesc">Short Description</label>
        <textarea
          [(ngModel)]="dispensary.shortDesc"
          id="shortDesc"
          name="shortDesc"
          class="form-control"
          rows="3"
        >
        </textarea>
      </div>
      <div class="form-group">
        <label for="aboutBio">About Us</label>
        <textarea
          [(ngModel)]="dispensary.aboutBio"
          id="aboutBio"
          name="aboutBio"
          class="form-control"
          rows="3"
        >
        </textarea>
      </div>
      <div class="form-group">
        <label for="announcement">Announcements</label>
        <textarea
          [(ngModel)]="dispensary.announcement"
          id="announcement"
          name="announcement"
          class="form-control"
          rows="3"
        >
        </textarea>
      </div>
      <div class="form-group">
        <label for="webMenu">Web Menu</label>
        <input
          [(ngModel)]="dispensary.webMenu"
          type="text"
          id="webMenu"
          name="webMenu"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="discounts">Discounts</label>
        <input
          [(ngModel)]="dispensary.discounts"
          type="text"
          id="discounts"
          name="discounts"
          class="form-control"
        />
      </div>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.openClosed"
          id="openClosed"
          name="openClosed"
          data-md-icheck
        />
        Open
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.adaAccessible"
          id="adaAccessible"
          name="adaAccessible"
          data-md-icheck
        />
        ADA Accessible
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.veteranDiscount"
          id="veteranDiscount"
          name="veteranDiscount"
          data-md-icheck
        />
        Veteran Discount
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.atmOnsite"
          id="atmOnsite"
          name="atmOnsite"
          data-md-icheck
        />
        ATM Onsite
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.verified"
          id="verified"
          name="verified"
          data-md-icheck
        />
        Verified
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.isPermanentlyClosed"
          id="isPermanentlyClosed"
          name="isPermanentlyClosed"
          data-md-icheck
        />
        Is Permanently Closed
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input
          type="checkbox"
          [(ngModel)]="dispensary.hasStartedEditing"
          id="hasStartedEditing"
          name="hasStartedEditing"
          data-md-icheck
        />
        Has Started Editing
        <span class="checkmark"></span>
      </label>

      <div class="form-group">
        <label for="businessType">Business Type</label>
        <ng-multiselect-dropdown
          name="businessType"
          [(ngModel)]="businessType"
          [placeholder]="'Select Business Type'"
          [data]="businessTypeSelectItems"
          [settings]="businessTypeSettings"
          (onSelect)="onBusinessTypeSelect($event)"
          (onDeSelect)="onBusinessTypeDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="licenseType">License Type</label>
        <ng-multiselect-dropdown
          name="licenseType"
          [(ngModel)]="licenseType"
          [placeholder]="'Select License Type'"
          [data]="licenseTypeSelectItems"
          [settings]="licenseTypeSettings"
          (onSelect)="onLicenseTypeSelect($event)"
          (onDeSelect)="onLicenseTypeDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group">
        <label for="licenseNumber">License Expiry Date</label>
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="licenseExpiryDate"
            ngbDatepicker
            #d="ngbDatepicker"
            (ngModelChange)="dateChanged($event)"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar fa fa-calendar"
              (click)="d.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="licenseNumber">License Number</label>
        <input
          [(ngModel)]="dispensary.licenseNumber"
          type="text"
          id="licenseNumber"
          name="licenseNumber"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="licDesignations">License Designations</label>
        <ng-multiselect-dropdown
          name="licDesignations"
          [(ngModel)]="licDesignations"
          [placeholder]="'Select License Designations'"
          [data]="licDesignationTypeSelectItems"
          [settings]="licDesignationsSettings"
          (onSelect)="onLicDesignationSelect($event)"
          (onDeSelect)="onLicDesignationDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>


      <div class="form-group">
        <label for="licenseSource">License Source</label>
        <input
          [(ngModel)]="dispensary.licenseSource"
          type="text"
          id="licenseSource"
          name="licenseSource"
          class="form-control"
        />
      </div>

      <div class="form-group" *ngIf="hasDeliveryLicense()">
        <label for="deliveryCities">Delivery Cities</label>
        <ng-multiselect-dropdown
          name="deliveryCities"
          [(ngModel)]="deliveryCities"
          [placeholder]="'Select Cities'"
          [data]="citySelectItems"
          [settings]="deliveryCitiesSettings"
          (onSelect)="onDeliveryCitySelect($event)"
          (onDeSelect)="onDeliveryCityDeSelect($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div *ngIf="dispensary.dba; else noGalleryImageUpload" class="m-3 p-3" style="background: #fff;">
        <h3>Image Gallery (3 images max)</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Photo Id</th>
              <th scope="col">Photo Extension</th>
              <th scope="col">Alt Text</th>
              <th class="button-container">
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="addPhoto('gallery')"
                >
                  <span class="fa fa-plus"></span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let photo of dispensary.photos">
              <td>
                {{ photo.photoId }}<br>
                <img [src]="cdnRoot + '/images/business/sm-' + photo.photoId" width="50" height="auto">
              </td>
              <td>{{ photo.photoExt }}</td>
              <td>{{ photo.altText }}</td>
              <td class="button-container">
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="editPhoto(photo)"
                >
                  <span class="fa fa-edit"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deletePhoto(photo)"
                >
                  <span class="fa fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-template #noGalleryImageUpload>
        <div class="m-5">
          <h2>Set the DBA to upload Gallery Photos</h2>
        </div>
      </ng-template>


      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Day of the Week</th>
            <th scope="col">Start Time</th>
            <th scope="col">End Time</th>
            <th scope="col">Order</th>
            <th class="button-container">
              <button type="button" class="btn btn-success" (click)="addTime()">
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let openTime of sortedOpenTimes()">
            <td>{{ showDayName(openTime.dayOfWeek) }}</td>
            <td>{{ openTime.startTime }}</td>
            <td>{{ openTime.endTime }}</td>
            <td>{{ openTime.order }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editTime(openTime)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteTime(openTime)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="form-group">
        <label for="payments">Payment Methods</label>
        <ng-multiselect-dropdown
          name="payments"
          [(ngModel)]="payments"
          [placeholder]="'Select Payment Methods'"
          [data]="paymentMethodItems"
          [settings]="paymentMethodSettings"
          (onSelect)="onPaymentMethodSelect($event)"
          (onDeSelect)="onPaymentMethodDeSelect($event)"
          (onSelectAll)="onPaymentMethodSelectAll($event)"
          (onDeSelectAll)="onPaymentMethodDeSelectAll($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Social Media Site</th>
            <th scope="col">User Name</th>
            <th class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="addSocialMediaSite()"
              >
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of dispensary.socialMediaSites">
            <td>{{ site.name }}</td>
            <td>{{ site.socialMedia.userName }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editSocialMediaSite(site)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteSocialMediaSite(site)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Review Site</th>
            <th scope="col">Slug</th>
            <th scope="col">Review Count</th>
            <th scope="col">Rating</th>
            <th class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="addReviewSite()"
              >
                <span class="fa fa-plus"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let site of dispensary.reviewSites">
            <td>{{ site.name }}</td>
            <td>{{ site.review.slug }}</td>
            <td>{{ site.review.reviewCount }}</td>
            <td>{{ site.review.rating }}</td>
            <td class="button-container">
              <button
                type="button"
                class="btn btn-success"
                (click)="editReviewSite(site)"
              >
                <span class="fa fa-edit"></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="deleteReviewSite(site)"
              >
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <label>
          <input type="checkbox" [(ngModel)]="forceInvalidation" [ngModelOptions]="{standalone: true}" />
          Force an invalidation of the cache
        </label>
      </div>

      <button
        type="submit"
        class="btn btn-primary btn-block"
        id="submit"
        [disabled]="!editDispensaryForm.form.valid"
      >
        Submit
      </button>

      <pre>
            {{ dispensary | json }}
        </pre
      >
    </form>
    <div id="map-wrapper">
      <agm-map
        [latitude]="dispensary.latitude || 0"
        [longitude]="dispensary.longitude || 0"
        [zoom]="dispensary.zoom || 0"
        [disableDefaultUI]="true"
        [zoomControl]="true"
        [style.height.px]="showForm ? '0' : '300'"
        title=""
      >
        <agm-marker
          [latitude]="dispensary.latitude || 0"
          [longitude]="dispensary.longitude || 0"
        >
          <agm-snazzy-info-window
            [maxWidth]="200"
            [closeWhenOthersOpen]="true"
            [isOpen]="!showForm"
          >
            <ng-template>
              {{ dispensary.dba }}
            </ng-template>
          </agm-snazzy-info-window>
        </agm-marker>
      </agm-map>
      <div style="text-align: center;">
        <ng-container *ngIf="webAppInvalidateUrl">
        [
        <a [href]="webAppInvalidateUrl" target="_blank">Dispensary link (7d cache)</a>
        ]
        [
        <a [href]="webAppInvalidateUrlSource" target="_blank">Dispensary link (QA - no cache)</a>
        ]
        </ng-container>
        [
        <a href="/admin/dispensaries/edit/{{ dispensary.id }}"
          >Back to {{ dispensary.businessName }}</a
        >
        ] [ <a href="/admin/dispensaries/search/">Search Again</a> ]
      </div>
    </div>
  </div>
</div>
