var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { LocationService } from '../../../services/location.service';
import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { environment } from '../../../../environments/environment';
import { FeaturedDispensarySiteLocation } from '../../../model/featured-dispensary';
var AdminFeaturedComponent = /** @class */ (function (_super) {
    __extends(AdminFeaturedComponent, _super);
    function AdminFeaturedComponent(seo, locationService) {
        var _this = _super.call(this) || this;
        _this.seo = seo;
        _this.locationService = locationService;
        _this.cdnRoot = environment.cdnRoot;
        _this.siteLocationList = [
            FeaturedDispensarySiteLocation.Homepage,
            FeaturedDispensarySiteLocation.USA
        ];
        _this.businessSettings = {};
        // Toppers & Ads
        _this.selectedDispensaryOne = [];
        _this.selectedDispensaryTwo = [];
        _this.selectedDispensaryThree = [];
        _this.toast = {
            message: '',
            show: false,
        };
        _this.forceInvalidation = false;
        _this.locationService.getAllDispensaries().subscribe(function (dispensaries) {
            dispensaries.map(function (business) {
                business.dba = business.dba + " - " + business.businessCityName + ", " + business.businessStateCode;
                return business;
            });
            console.log({ dispensaries: dispensaries });
            _this.dispensaries = dispensaries;
        });
        _this.index = false;
        _this.follow = false;
        return _this;
    }
    AdminFeaturedComponent.prototype.refreshFeaturedDispensaries = function (selectedSiteLocation) {
        var _this = this;
        this.locationService.getFeaturedDispensaries(selectedSiteLocation).subscribe(function (dispensaries) {
            _this.featuredDispensaries = dispensaries;
            _this.fillSelections(dispensaries);
        });
    };
    AdminFeaturedComponent.prototype.changeLocation = function (selectedSiteLocation) {
        this.featuredDispensaries = [];
        this.refreshFeaturedDispensaries(selectedSiteLocation);
    };
    AdminFeaturedComponent.prototype.fillSelections = function (collection) {
        var _this = this;
        console.log('\n>> fillSelections >>');
        console.log(collection);
        // Reset all before mapping
        this.selectedDispensaryOne = [];
        this.selectedDispensaryTwo = [];
        this.selectedDispensaryThree = [];
        collection.map(function (dispensary) {
            switch (dispensary.rank) {
                case 1:
                    _this.selectedDispensaryOne = [
                        _this.dispensaries.filter(function (d) { return d.id === dispensary.businessId; })[0]
                    ];
                    return;
                case 2:
                    _this.selectedDispensaryTwo = [
                        _this.dispensaries.filter(function (d) { return d.id === dispensary.businessId; })[0]
                    ];
                    return;
                case 3:
                    _this.selectedDispensaryThree = [
                        _this.dispensaries.filter(function (d) { return d.id === dispensary.businessId; })[0]
                    ];
                    return;
                default:
                    return;
            }
        });
    };
    AdminFeaturedComponent.prototype.ngOnInit = function () {
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" }
        ]);
        this.businessSettings = {
            singleSelection: true,
            idField: "id",
            textField: "dba",
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };
    };
    AdminFeaturedComponent.prototype.onDispensarySelect = function (dispensary, rank) {
        this.setFeatured(dispensary, rank);
    };
    AdminFeaturedComponent.prototype.onDispensaryDeSelect = function (dispensary, rank) {
        var featuredId = this.featuredDispensaries.filter(function (d) { return d.businessId === dispensary.id; })[0].id;
        this.removeFeatured(featuredId);
    };
    AdminFeaturedComponent.prototype.setFeatured = function (dispensary, rank) {
        var _this = this;
        this.locationService.setFeaturedDispensary(dispensary.id, this.selectedSiteLocation, rank, this.forceInvalidation).subscribe(function (_) {
            _this.showToast("Dispensary SAVED to Rank #" + rank + " for " + _this.selectedSiteLocation + "!");
        });
    };
    AdminFeaturedComponent.prototype.removeFeatured = function (id) {
        var _this = this;
        this.locationService.removeFeaturedDispensary(id).subscribe(function (_) {
            _this.showToast("Dispensary REMOVED!");
        });
    };
    AdminFeaturedComponent.prototype.showToast = function (msg) {
        var _this = this;
        this.toast = {
            message: msg,
            show: true
        };
        clearTimeout(this.toastTimeout);
        this.toastTimeout = setTimeout(function () {
            _this.toast = {
                message: '',
                show: false
            };
        }, 5000);
    };
    return AdminFeaturedComponent;
}(BaseComponent));
export { AdminFeaturedComponent };
