import { Component, OnInit } from '@angular/core';

import { Business } from '../../../model/business';
import { LocationService } from '../../../services/location.service';

import { BaseComponent } from '../../base.component';
import { SeoService } from '../../../services/seo.service';
import { environment } from '../../../../environments/environment';
import { FeaturedDispensary, FeaturedDispensarySiteLocation } from '../../../model/featured-dispensary';

@Component({
  selector: "app-admin-featured",
  templateUrl: "./admin-featured.component.html",
  styles: [`
  .buttons-wrapper button { margin-right: 1rem; }
  .buttons-wrapper button.selected { background: #5279a4; color: #fff; }
`]
})
export class AdminFeaturedComponent extends BaseComponent implements OnInit {

  cdnRoot = environment.cdnRoot;

  dispensary: Business;

  siteLocationList: any[] = [
    FeaturedDispensarySiteLocation.Homepage,
    FeaturedDispensarySiteLocation.USA
  ];
  selectedSiteLocation: FeaturedDispensarySiteLocation;

  dispensaries: Business[];
  featuredDispensaries: FeaturedDispensary[];

  businessSettings: any = {};

  // Toppers & Ads
  selectedDispensaryOne: any[] = [];
  selectedDispensaryTwo: any[] = [];
  selectedDispensaryThree: any[] = [];

  toast = {
    message: '',
    show: false,
  };

  toastTimeout;

  forceInvalidation: boolean = false;

  constructor(
    private seo: SeoService,
    private locationService: LocationService
  ) {
    super();

    this.locationService.getAllDispensaries().subscribe(dispensaries => {
      dispensaries.map((business: Business) => {
          business.dba = `${business.dba} - ${business.businessCityName}, ${business.businessStateCode}`
          return business;
        });

      console.log({dispensaries});

      this.dispensaries = dispensaries;
    });

    this.index = false;
    this.follow = false;
  }

  refreshFeaturedDispensaries(selectedSiteLocation: FeaturedDispensarySiteLocation) {
    this.locationService.getFeaturedDispensaries(selectedSiteLocation).subscribe(dispensaries => {
      this.featuredDispensaries = dispensaries;
      this.fillSelections(dispensaries);
    })
  }

  changeLocation(selectedSiteLocation: FeaturedDispensarySiteLocation) {
    this.featuredDispensaries = [];
    this.refreshFeaturedDispensaries(selectedSiteLocation);
  }

  fillSelections(collection: FeaturedDispensary[]) {
    console.log('\n>> fillSelections >>');
    console.log(collection);

    // Reset all before mapping
    this.selectedDispensaryOne = [];
    this.selectedDispensaryTwo = [];
    this.selectedDispensaryThree = [];

    collection.map(dispensary => {
      switch (dispensary.rank) {
        case 1:
          this.selectedDispensaryOne =  [
            this.dispensaries.filter(d => d.id === dispensary.businessId)[0]
          ];
          return;
        case 2:
          this.selectedDispensaryTwo =  [
            this.dispensaries.filter(d => d.id === dispensary.businessId)[0]
          ];
          return;
        case 3:
          this.selectedDispensaryThree =  [
            this.dispensaries.filter(d => d.id === dispensary.businessId)[0]
          ];
          return;
        default:
          return;
      }
    });

  }

  ngOnInit() {
    this.seo.addTags([
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
    ]);

    this.businessSettings = {
      singleSelection: true,
      idField: "id",
      textField: "dba",
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onDispensarySelect(dispensary: Business, rank: 1|2|3) {
    this.setFeatured(dispensary, rank);
  }
  onDispensaryDeSelect(dispensary: Business, rank: 1|2|3) {
    const featuredId = this.featuredDispensaries.filter(d => d.businessId === dispensary.id)[0].id;
    this.removeFeatured(featuredId);
  }

  setFeatured(dispensary: Business, rank: 1|2|3) {
    this.locationService.setFeaturedDispensary(
      dispensary.id,
      this.selectedSiteLocation,
      rank,
      this.forceInvalidation
    ).subscribe(_ => {
      this.showToast(`Dispensary SAVED to Rank #${rank} for ${this.selectedSiteLocation}!`);
    });
  }

  removeFeatured(id: number) {
    this.locationService.removeFeaturedDispensary(id).subscribe(_ => {
      this.showToast(`Dispensary REMOVED!`)
    });
  }

  showToast(msg) {
    this.toast = {
      message: msg,
      show: true
    };

    clearTimeout(this.toastTimeout);

    this.toastTimeout = setTimeout(() => {
      this.toast = {
        message: '',
        show: false
      };
    }, 5000);
  }

}
