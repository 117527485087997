export interface FeaturedDispensary {
  id: number;
  scope: 'homepage' | 'usa';
  businessId: number,
  rank: number;
}

export enum FeaturedDispensarySiteLocation {
  Homepage = 'homepage',
  USA = 'usa'
}