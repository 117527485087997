import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocationService } from '../services/location.service';

@Injectable()
export class CountryResolver implements Resolve<any> {

    constructor(private locationService: LocationService) {}

    resolve() {
        return this.locationService.getCountry(1);
    }
}
