import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

//(window as any).global = window;

@Injectable()
export class AuthService {
  auth0 = new auth0.WebAuth({
    clientID: environment.auth.clientID,
    domain: environment.auth.domain,
    responseType: 'token',
    redirectUri: environment.auth.redirect,
    audience: environment.auth.audience,
    scope: environment.auth.scope
  });
  expiresAt: number;
  userProfile: any;
  accessToken: string;
  authenticated: boolean;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  login() {
    if (isPlatformBrowser(this.platformId)) {
      this.auth0.authorize();
    }
  }

  // TODO: Fix this
  handleLoginCallback() {
    if (isPlatformBrowser(this.platformId)) {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken) {
          window.location.hash = '';
          this.getUserInfo(authResult).then(() => {
            this.router.navigate(['/admin/']);
          });
        } else if (err) {
          console.error(`Error: ${err.error}`);
        }
      });
    }
  }

  getAccessToken() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken) {
          this.getUserInfo(authResult).then(x => {
            resolve(x);
          });
        } else {
          resolve(null);
        }
      });
    });
  }

  getUserEmail() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken) {
          this.getUserInfo(authResult).then(x => {
            resolve(x['userProfile'].email);
          });
        } else {
          resolve(null);
        }
      });
    });
  }

  isAdminEmail(email) {
    const adminEmails = [
      'mlybrand+ganjadev@gmail.com',
      'george@newmediaroots.com',
    ];
    //return false;
    return adminEmails.includes(email);
  }

  getUserInfo(authResult) {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
        if (profile) {
          resolve(this._setSession(authResult, profile));
        }
      });
    });
  }

  private _setSession(authResult, profile) {
    let returnObj =  {
      expiresAt: authResult.expiresIn * 1000 + Date.now(),
      accessToken: authResult.accessToken,
      userProfile: profile,
      authenticated: true
    };
    return returnObj;
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      this.auth0.logout({
        returnTo: environment.apiRoot,
        clientID: environment.auth.clientID
      });
    }
  }

  get isLoggedIn() {
    if (isPlatformBrowser(this.platformId)) {
      return this.getAccessToken()
        .then(token => {
          if (!token) {
            return false;
          }
          console.log('email');
          console.log(token['userProfile'].email);
          return Date.now() < token['expiresAt'] && token['authenticated'];
        });
    } else {
      return new Promise<boolean>(resolve => {
        resolve(true);
      });
    }
  }

  get isAdmin() {
    if (isPlatformBrowser(this.platformId)) {
      return this.getUserEmail()
         .then(email => {
            if (!email) {
             return false;
            }
            return this.isAdminEmail(email);
          });
    } else {
      return new Promise<boolean>(resolve => {
        resolve(true);
      });
    }
  }
}
