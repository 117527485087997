import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from '../../services/header-title.service';
import { BaseComponent } from '../base.component';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocationService } from '../../services/location.service';
import { DOMAIN, SeoService } from '../../services/seo.service';
import { ActivatedRoute } from '@angular/router';

export interface AddBusinessModel {
  businessType: string;
  businessName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneNumber?: string;
  email?: string;
  website?: string;
  contactName?: string;
  captcha?: string;
}

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html'
})
export class AddBusinessComponent extends BaseComponent implements OnInit {
  showForm: boolean = true;
  stateCodeList: string[];
  public addBusinessModel: AddBusinessModel = {
    businessType: 'Dispensary'
  };
  private apiRoot: string = environment.apiRoot;
  cdnRoot: string = environment.cdnRoot;
  public recaptchaKey: string = environment.recaptchaKey;

  constructor(
    private headerTitleService: HeaderTitleService,
    private http: HttpClient,
    private locationService: LocationService,
    private seo: SeoService,
    private actr: ActivatedRoute
  ) {
    super();
    this.actr.data.subscribe(data => {
      this.stateCodeList = data.stateCodeList as string[];
    });
  }

  ngOnInit() {
    window && window.scrollTo && window.scrollTo(0, 0);

    this.headerTitleService.setTitle('Add a Legal Business');

    this.seo.addTags([
      { tagName: 'link', rel: 'canonical', href: `${DOMAIN}/add-business/` },
      { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` },
      { tagName: 'meta', name: 'description', content: `Own a legal marijuana business or have a favorite cannabis business? Add your business to Mama's Ganja and grow the customer base today.` },
      { tagName: 'meta', name: 'keywords', content: `add marijuana business, add legal marijuana business, add marijuana dispensary, add legal marijuana dispensary, add business to mamas ganja` },
      { tagName: 'meta', name: 'og:title', content: `Add a Legal Marijuana Business | MAMA'S GANJA` },
      { tagName: 'meta', name: 'og:description', content: `Own a legal marijuana business or have a favorite cannabis business? Add your business to Mama's Ganja and grow the customer base today.` },
      { tagName: 'meta', name: 'og:url', content: `${DOMAIN}/add-business/` },
    ]);

    this.seo.removeJsonLd();

    this.seo.setJsonLd({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": `${DOMAIN}`,
            "name": "Mama's Ganja Legal Marijuana Dispensary Directory and Legal Marijuana Information",
            "image": `${this.cdnRoot}/img/marijuana-business-directory.jpg`
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": `${DOMAIN}/add-business/`,
            "name": "Add a Legal Business",
            "image": `${this.cdnRoot}/img/marijuana-business-directory.jpg`
          }
        }
      ]
    }, 'breadcrumb-schema');
  }

  onSubmit() {
    this.http.post(`${this.apiRoot}/api/add-business`, this.addBusinessModel)
      .subscribe(() => {
        this.addBusinessModel = {
          businessType: 'Dispensary'
        };
        this.showForm = false;
      });
  }

}
