<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete review site?</h4>
    <button type="button" class="close" aria-label="Close" (click)="deleteReviewSiteModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this review site?</p>
  
    <p>
      {{ reviewSite.name }}<br />
      {{ reviewSite.review.slug }}
    </p>
  
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="deleteReviewSiteModal.dismiss()">No</button>
      <button type="button" class="btn btn-outline-dark" (click)="delete(reviewSite)">Yes</button>
  </div>