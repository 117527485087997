var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { HeaderTitleService } from '../../services/header-title.service';
import { BaseComponent } from '../base.component';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocationService } from '../../services/location.service';
import { DOMAIN, SeoService } from '../../services/seo.service';
import { ActivatedRoute } from '@angular/router';
var AddBusinessComponent = /** @class */ (function (_super) {
    __extends(AddBusinessComponent, _super);
    function AddBusinessComponent(headerTitleService, http, locationService, seo, actr) {
        var _this = _super.call(this) || this;
        _this.headerTitleService = headerTitleService;
        _this.http = http;
        _this.locationService = locationService;
        _this.seo = seo;
        _this.actr = actr;
        _this.showForm = true;
        _this.addBusinessModel = {
            businessType: 'Dispensary'
        };
        _this.apiRoot = environment.apiRoot;
        _this.cdnRoot = environment.cdnRoot;
        _this.recaptchaKey = environment.recaptchaKey;
        _this.actr.data.subscribe(function (data) {
            _this.stateCodeList = data.stateCodeList;
        });
        return _this;
    }
    AddBusinessComponent.prototype.ngOnInit = function () {
        window && window.scrollTo && window.scrollTo(0, 0);
        this.headerTitleService.setTitle('Add a Legal Business');
        this.seo.addTags([
            { tagName: 'link', rel: 'canonical', href: DOMAIN + "/add-business/" },
            { tagName: 'meta', name: 'robots', content: (!this.index ? 'no' : '') + "index, " + (!this.follow ? 'no' : '') + "follow" },
            { tagName: 'meta', name: 'description', content: "Own a legal marijuana business or have a favorite cannabis business? Add your business to Mama's Ganja and grow the customer base today." },
            { tagName: 'meta', name: 'keywords', content: "add marijuana business, add legal marijuana business, add marijuana dispensary, add legal marijuana dispensary, add business to mamas ganja" },
            { tagName: 'meta', name: 'og:title', content: "Add a Legal Marijuana Business | MAMA'S GANJA" },
            { tagName: 'meta', name: 'og:description', content: "Own a legal marijuana business or have a favorite cannabis business? Add your business to Mama's Ganja and grow the customer base today." },
            { tagName: 'meta', name: 'og:url', content: DOMAIN + "/add-business/" },
        ]);
        this.seo.removeJsonLd();
        this.seo.setJsonLd({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": "" + DOMAIN,
                        "name": "Mama's Ganja Legal Marijuana Dispensary Directory and Legal Marijuana Information",
                        "image": this.cdnRoot + "/img/marijuana-business-directory.jpg"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": DOMAIN + "/add-business/",
                        "name": "Add a Legal Business",
                        "image": this.cdnRoot + "/img/marijuana-business-directory.jpg"
                    }
                }
            ]
        }, 'breadcrumb-schema');
    };
    AddBusinessComponent.prototype.onSubmit = function () {
        var _this = this;
        this.http.post(this.apiRoot + "/api/add-business", this.addBusinessModel)
            .subscribe(function () {
            _this.addBusinessModel = {
                businessType: 'Dispensary'
            };
            _this.showForm = false;
        });
    };
    return AddBusinessComponent;
}(BaseComponent));
export { AddBusinessComponent };
