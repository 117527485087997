import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'admin-ads-photo-add-modal',
  templateUrl: './admin-ads-photo-add-modal.component.html'
})
export class AdminAdsPhotoAddModalComponent implements OnInit {
  @Input() public editMode: boolean;
  @Output() addPhotoEmitter: EventEmitter<any> = new EventEmitter();

  public altText: string = '';
  public fileName: string;
  public adUrl: string = '';
  public adCode: string = '';

  public photo;

  constructor(
    public addPhotoModal: NgbActiveModal,
    private locationService: LocationService
  ) {
  }

  get formDisabled() {
    return !this.adCode && (!this.photo || !this.altText.length || !this.adUrl.length);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.photo = file;
    }
  }

  ngOnInit() {

  }

  add() {
    this.locationService.uploadAdImage(this.photo, this.altText, this.adUrl, this.adCode).subscribe((res: any) => {
      this.addPhotoEmitter.emit(res);
      this.addPhotoModal.close();
    }, err => {
      console.error(err);
      alert(err.error.message);
    });
  }

}
