/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./admin-dispensaries-edit-open-time-delete-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var styles_AdminDispensariesEditOpenTimeDeleteModalComponent = [];
var RenderType_AdminDispensariesEditOpenTimeDeleteModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminDispensariesEditOpenTimeDeleteModalComponent, data: {} });
export { RenderType_AdminDispensariesEditOpenTimeDeleteModalComponent as RenderType_AdminDispensariesEditOpenTimeDeleteModalComponent };
export function View_AdminDispensariesEditOpenTimeDeleteModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete Open Time?"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteOpenTimeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Are you sure you want to delete this open time?"])), (_l()(), i0.ɵeld(9, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", ""])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, [" ", ""])), (_l()(), i0.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, [" ", " "])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteOpenTimeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["No"])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_co.openTime) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Yes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDayName(_co.openTime.dayOfWeek); _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.openTime.startTime; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.openTime.endTime; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.openTime.order; _ck(_v, 16, 0, currVal_3); }); }
export function View_AdminDispensariesEditOpenTimeDeleteModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin-dispensaries-edit-open-time-delete-modal", [], null, null, null, View_AdminDispensariesEditOpenTimeDeleteModalComponent_0, RenderType_AdminDispensariesEditOpenTimeDeleteModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.AdminDispensariesEditOpenTimeDeleteModalComponent, [i2.NgbActiveModal], null, null)], null, null); }
var AdminDispensariesEditOpenTimeDeleteModalComponentNgFactory = i0.ɵccf("app-admin-dispensaries-edit-open-time-delete-modal", i1.AdminDispensariesEditOpenTimeDeleteModalComponent, View_AdminDispensariesEditOpenTimeDeleteModalComponent_Host_0, { openTime: "openTime", daysOfWeek: "daysOfWeek" }, { deleteOpenTimeEmitter: "deleteOpenTimeEmitter" }, []);
export { AdminDispensariesEditOpenTimeDeleteModalComponentNgFactory as AdminDispensariesEditOpenTimeDeleteModalComponentNgFactory };
