import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenTime } from '../../../model/open-time';

@Component({
    selector: 'app-admin-dispensaries-edit-open-time-delete-modal',
    templateUrl: './admin-dispensaries-edit-open-time-delete-modal.component.html'
})
export class AdminDispensariesEditOpenTimeDeleteModalComponent {
    @Input() openTime: OpenTime;
    @Input() public daysOfWeek: any[];
    @Output() deleteOpenTimeEmitter: EventEmitter<any> = new EventEmitter();

    constructor(public deleteOpenTimeModal: NgbActiveModal) {}

    delete(openTime) {
        this.deleteOpenTimeEmitter.emit(openTime);
        this.deleteOpenTimeModal.close();
    }

    showDayName(dayId) {
        return this
            .daysOfWeek
            .find(x => x.dayOfWeekId === dayId)
            .dayOfWeekName;
    }

}
