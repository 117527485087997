import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { SeoService } from '../../../services/seo.service';
import { AuthService } from '../../../services/auth.service';
import { LocationService } from '../../../services/location.service';
import { Business } from '../../../model/business';
import { StateButton } from '../../../model/state-button';

@Component({
    selector: 'app-admin-index',
    templateUrl: './admin-index.component.html'
})
export class AdminIndexComponent extends BaseComponent implements OnInit {
    public isAdmin: boolean = false;
    public businesses: Business[];
    public stateButtons: StateButton[];
    public temp: Array<any>;
    public math = Math;
    public stateClicked: boolean = false;

    constructor(
        private seo: SeoService,
        private locationService: LocationService,
        private authService: AuthService,
        private router: Router
        ) {
        super();
        this.index = false;
        this.follow = false;
    }

    ngOnInit() {
        this.authService.isAdmin.then(state => {
            this.isAdmin = state;
            if (!this.isAdmin) {
                this.locationService.getPendStates().subscribe(stateButtons => {
                    this.stateButtons  = stateButtons;
                    this.temp = new Array(Math.ceil(stateButtons.length / 4));
                });
            }
        });
        this.seo.addTags([
            { tagName: 'meta', name: 'robots', content: `${!this.index?'no':''}index, ${!this.follow?'no':''}follow` }
          ]);
    }
    edit(id) {
        this.router.navigate([`admin/dispensaries/copyedit/${id}`]);
    }

    getBusinesses(stateId) {
        this.stateClicked = true;
        this.locationService.getPendList(stateId).subscribe(businesses => {
            this.businesses  = businesses;
        });
    }
}
